import { useTranslation } from 'react-i18next';

import { PrimaryButton } from '../PrimaryButton/PrimaryButton';
import { CloseIcon } from '../Icons/CloseIcon';
import { Button } from '../Button/Button';

export const ActionButtons: React.FC<{
  changeRange: () => void;
  clearRange: () => void;
}> = ({ changeRange, clearRange }) => {
  const { t } = useTranslation();
  return (
    <div className="RangeDatePickerNoBorder-picker-footer">
      <Button
        id="cancel-button"
        background="white"
        border="none"
        padding="0"
        action={clearRange}
        className="RangeDatePickerNoBorder-picker-footer__closeButton"
      >
        <CloseIcon />
        {t('global.cancel')}
      </Button>
      <PrimaryButton
        id="submit-button"
        size="small"
        variant="green"
        onClick={changeRange}
      >
        {t('global.done')}
      </PrimaryButton>
    </div>
  );
};
