import { colors } from '@constants/colors';

export const CreatingTestIcon = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="540 500 1300 950"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{ fillRule: 'evenodd', clipRule: 'evenodd' }}
    >
      <g>
        <g
          style={{ fill: '#e6e6e6', fillRule: 'nonzero' }}
          transform="translate(1500,1000)"
        >
          <path d="M130.3,9.69c-14.479,40.937 -34.654,79.637 -59.929,114.95c-0.584,0.833 -1.175,1.625 -1.771,2.441l-76.667,-24.833c0.346,-0.792 0.725,-1.667 1.129,-2.646c24.167,-57.129 169.496,-405.354 154.063,-477.954c1.454,5.958 49.612,203.188 -16.825,388.042Z">
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="rotate"
              values="0;5;0"
              dur="2"
              repeatCount="indefinite"
            />
          </path>
          <path d="M85.18,129.43c-0.937,0.704 -1.9,1.404 -2.875,2.083l-57.542,-18.645c0.688,-0.638 1.488,-1.388 2.409,-2.234c15.058,-14.075 59.8,-56.25 103.129,-100.958c46.567,-48.046 91.517,-99.008 96.296,-121.175c-0.9,5.071 -29.271,158.925 -141.417,240.929Z">
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="rotate"
              values="0;5;0"
              dur="2"
              begin="-0.5s"
              repeatCount="indefinite"
            />
          </path>
        </g>
        <path
          d="M1671.83,1365.86l-986.188,0c-8.77,0 -17.179,-3.487 -23.383,-9.687c-6.2,-6.2 -9.683,-14.613 -9.683,-23.379c357.5,-41.55 708.275,-41.55 1052.32,-0c-0,8.766 -3.484,17.179 -9.684,23.379c-6.204,6.2 -14.612,9.687 -23.383,9.687Z"
          style={{ fill: colors.oxfordBlue1, fillRule: 'nonzero' }}
        />
        <path
          d="M1704.89,1334.73l-1052.32,-1.945l121.958,-205.213l0.583,-0.971l0,-452.262c0,-5.492 1.08,-10.93 3.184,-16c2.1,-5.071 5.179,-9.68 9.062,-13.559c3.88,-3.883 8.488,-6.962 13.563,-9.062c5.071,-2.1 10.504,-3.184 15.996,-3.179l717.791,-0c5.488,-0 10.925,1.079 15.996,3.179c5.071,2.104 9.679,5.183 13.563,9.062c3.883,3.884 6.962,8.492 9.062,13.563c2.1,5.071 3.179,10.508 3.179,16l0,455.375l128.384,205.012Z"
          style={{ fill: colors.oxfordBlue, fillRule: 'nonzero' }}
        />
        <path
          d="M815.963,661.717c-3.609,0.004 -7.071,1.437 -9.625,3.991c-2.55,2.555 -3.988,6.013 -3.992,9.625l-0,412.359c0.004,3.608 1.442,7.071 3.991,9.621c2.555,2.554 6.017,3.987 9.625,3.995l725.538,0c3.608,-0.008 7.071,-1.441 9.625,-3.995c2.55,-2.551 3.987,-6.013 3.992,-9.621l-0,-412.359c-0.005,-3.612 -1.442,-7.071 -3.992,-9.625c-2.554,-2.554 -6.017,-3.987 -9.625,-3.991l-725.538,-0Z"
          style={{ fill: colors.white, fillRule: 'nonzero' }}
        />
        <path
          d="M820.733,1155.78c-1.116,0 -2.208,0.317 -3.145,0.921c-0.942,0.6 -1.688,1.458 -2.155,2.475l-37.579,81.696c-0.408,0.888 -0.587,1.867 -0.521,2.842c0.067,0.975 0.379,1.92 0.904,2.745c0.53,0.826 1.259,1.501 2.117,1.971c0.858,0.471 1.821,0.717 2.8,0.717l790.279,0c0.996,0 1.971,-0.254 2.842,-0.742c0.871,-0.483 1.6,-1.179 2.121,-2.029c0.525,-0.846 0.821,-1.812 0.867,-2.804c0.041,-0.996 -0.167,-1.983 -0.613,-2.871l-40.833,-81.696c-0.484,-0.971 -1.225,-1.787 -2.146,-2.358c-0.925,-0.571 -1.988,-0.871 -3.071,-0.867l-711.867,0Z"
          style={{ fill: colors.oxfordBlue3, fillRule: 'nonzero' }}
        />
        <path
          d="M1175.82,654.913c4.833,-0 8.754,-3.921 8.754,-8.755c-0,-4.837 -3.921,-8.754 -8.754,-8.754c-4.834,0 -8.754,3.917 -8.754,8.754c-0,4.834 3.92,8.755 8.754,8.755Z"
          style={{ fill: colors.white, fillRule: 'nonzero' }}
        />
        <path
          d="M1098.13,1264.71c-1.283,-0.005 -2.529,0.42 -3.55,1.2c-1.016,0.783 -1.75,1.875 -2.083,3.116l-9.425,35c-0.233,0.863 -0.263,1.771 -0.092,2.65c0.175,0.879 0.55,1.704 1.092,2.417c0.546,0.712 1.246,1.287 2.05,1.683c0.804,0.396 1.688,0.604 2.583,0.604l179.271,0c0.929,0 1.85,-0.225 2.675,-0.65c0.825,-0.425 1.542,-1.045 2.079,-1.804c0.542,-0.758 0.892,-1.633 1.021,-2.554c0.134,-0.921 0.042,-1.862 -0.262,-2.742l-12.121,-34.995c-0.396,-1.15 -1.142,-2.142 -2.129,-2.846c-0.988,-0.704 -2.171,-1.079 -3.384,-1.079l-157.725,-0Z"
          style={{ fill: colors.oxfordBlue3, fillRule: 'nonzero' }}
        />
        <path
          d="M1576.51,1119.79l-0,7.779l-801.984,-0l0.604,-0.971l0,-6.808l801.38,-0Z"
          style={{ fill: colors.oxfordBlue1, fillRule: 'nonzero' }}
        />
        <path
          d="M1327.18,734.575l-189.221,0l0,3.454l189.221,0c9.317,0 18.25,3.7 24.838,10.288c6.587,6.587 10.287,15.521 10.287,24.837c0,9.313 -3.7,18.25 -10.287,24.838c-6.588,6.583 -15.521,10.287 -24.838,10.287l-286.325,0c-9.316,0 -18.25,-3.704 -24.837,-10.287c-6.588,-6.588 -10.288,-15.525 -10.288,-24.838c0,-9.316 3.7,-18.25 10.288,-24.837c6.587,-6.588 15.521,-10.288 24.837,-10.288l10.7,0l0,-3.454l-10.7,0c-10.162,0.104 -19.875,4.217 -27.025,11.438c-7.146,7.224 -11.158,16.979 -11.158,27.141c-0,10.163 4.012,19.917 11.158,27.142c7.15,7.225 16.863,11.333 27.025,11.442l286.321,-0c5.1,0.05 10.158,-0.909 14.888,-2.821c4.729,-1.917 9.029,-4.75 12.654,-8.338c3.625,-3.587 6.504,-7.858 8.466,-12.567c1.963,-4.708 2.975,-9.758 2.975,-14.858c0,-5.1 -1.012,-10.15 -2.975,-14.858c-1.962,-4.704 -4.841,-8.979 -8.466,-12.567c-3.625,-3.587 -7.925,-6.421 -12.654,-8.333c-4.73,-1.917 -9.788,-2.875 -14.888,-2.821l0.004,-0Z"
          style={{ fill: colors.oxfordBlue8, fillRule: 'nonzero' }}
        />
        <path
          d="M1327.18,853.813l-189.221,-0l0,3.454l189.221,-0c9.317,-0 18.25,3.7 24.838,10.287c6.587,6.588 10.287,15.521 10.287,24.838c0,9.312 -3.7,18.25 -10.287,24.833c-6.588,6.588 -15.521,10.292 -24.838,10.292l-286.325,-0c-9.316,-0 -18.25,-3.704 -24.837,-10.292c-6.588,-6.583 -10.288,-15.521 -10.288,-24.833c0,-9.317 3.7,-18.25 10.288,-24.838c6.587,-6.587 15.521,-10.287 24.837,-10.287l10.7,-0l0,-3.454l-10.7,-0c-10.162,0.104 -19.875,4.216 -27.025,11.437c-7.146,7.225 -11.158,16.979 -11.158,27.142c-0,10.162 4.012,19.916 11.158,27.141c7.15,7.225 16.863,11.334 27.025,11.442l286.321,0c5.1,0.05 10.158,-0.908 14.888,-2.821c4.729,-1.916 9.029,-4.75 12.654,-8.337c3.625,-3.588 6.504,-7.859 8.466,-12.567c1.963,-4.708 2.975,-9.758 2.975,-14.858c0,-5.1 -1.012,-10.15 -2.975,-14.859c-1.962,-4.708 -4.841,-8.979 -8.466,-12.566c-3.625,-3.588 -7.925,-6.421 -12.654,-8.334c-4.73,-1.916 -9.788,-2.875 -14.888,-2.821l0.004,0Z"
          style={{ fill: colors.oxfordBlue8, fillRule: 'nonzero' }}
        />
        <path
          d="M1327.18,973.042l-189.221,-0l0,3.454l189.221,-0c9.317,-0 18.25,3.7 24.838,10.287c6.587,6.588 10.287,15.521 10.287,24.838c0,9.312 -3.7,18.25 -10.287,24.833c-6.588,6.588 -15.521,10.292 -24.838,10.292l-286.325,-0c-9.316,-0 -18.25,-3.704 -24.837,-10.292c-6.588,-6.583 -10.288,-15.521 -10.288,-24.833c0,-9.317 3.7,-18.25 10.288,-24.838c6.587,-6.587 15.521,-10.287 24.837,-10.287l10.7,-0l0,-3.454l-10.7,-0c-10.162,0.104 -19.875,4.216 -27.025,11.437c-7.146,7.225 -11.158,16.979 -11.158,27.142c-0,10.162 4.012,19.917 11.158,27.141c7.15,7.221 16.863,11.334 27.025,11.442l286.321,0c5.1,0.05 10.158,-0.908 14.888,-2.821c4.729,-1.916 9.029,-4.75 12.654,-8.337c3.625,-3.588 6.504,-7.859 8.466,-12.567c1.963,-4.708 2.975,-9.758 2.975,-14.858c0,-5.1 -1.012,-10.15 -2.975,-14.859c-1.962,-4.708 -4.841,-8.979 -8.466,-12.566c-3.625,-3.588 -7.925,-6.421 -12.654,-8.334c-4.73,-1.916 -9.788,-2.875 -14.888,-2.82l0.004,-0Z"
          style={{ fill: colors.oxfordBlue8, fillRule: 'nonzero' }}
        />
        <path
          d="M1072.91,725.446c-1.35,-0 -2.688,0.262 -3.934,0.779c-1.246,0.517 -2.379,1.275 -3.333,2.229c-0.954,0.954 -1.708,2.088 -2.225,3.333c-0.517,1.246 -0.784,2.584 -0.784,3.934c0.001,1.35 0.267,2.683 0.784,3.929c0.516,1.25 1.271,2.379 2.225,3.333c0.954,0.955 2.087,1.713 3.333,2.23c1.246,0.516 2.584,0.783 3.934,0.783l45.075,-0c2.725,0.042 5.358,-0.996 7.316,-2.892c1.958,-1.896 3.083,-4.491 3.125,-7.216c0.046,-2.725 -0.992,-5.355 -2.887,-7.313c-1.896,-1.958 -4.492,-3.087 -7.217,-3.129l-45.417,-0l0.005,-0Z"
          style={{ fill: colors.oxfordBlue1, fillRule: 'nonzero' }}
        />
        <path
          d="M1072.91,846.408c-2.725,0 -5.338,1.08 -7.267,3.009c-1.925,1.925 -3.008,4.541 -3.008,7.266c-0,2.725 1.083,5.338 3.008,7.263c1.929,1.929 4.542,3.012 7.267,3.012l45.075,0c2.725,0.042 5.358,-0.995 7.316,-2.891c1.958,-1.896 3.083,-4.492 3.125,-7.217c0.046,-2.725 -0.992,-5.354 -2.887,-7.312c-1.896,-1.959 -4.492,-3.088 -7.217,-3.13l-45.417,0l0.005,0Z"
          style={{ fill: colors.oxfordBlue1, fillRule: 'nonzero' }}
        />
        <path
          d="M1072.91,963.913c-2.725,-0 -5.338,1.079 -7.267,3.008c-1.925,1.925 -3.008,4.542 -3.008,7.267c-0,2.725 1.083,5.337 3.008,7.262c1.929,1.929 4.542,3.012 7.267,3.012l45.075,0c1.35,0.021 2.691,-0.224 3.945,-0.72c1.255,-0.496 2.4,-1.234 3.371,-2.171c0.967,-0.938 1.742,-2.058 2.279,-3.296c0.538,-1.237 0.825,-2.571 0.846,-3.921c0.025,-1.35 -0.221,-2.687 -0.716,-3.946c-0.496,-1.254 -1.234,-2.4 -2.171,-3.366c-0.938,-0.971 -2.059,-1.746 -3.296,-2.284c-1.242,-0.537 -2.571,-0.825 -3.921,-0.845l-45.417,-0l0.005,-0Z"
          style={{ fill: colors.oxfordBlue1, fillRule: 'nonzero' }}
        />
        <g fill={colors.oxfordBlue8} fillRule="nonzero">
          <rect x="1045" y="763" width="280" height="20" rx="10" />
          <rect x="1045" y="883" width="280" height="20" rx="10" />
          <rect x="1045" y="1003" width="280" height="20" rx="10" />
        </g>
        <g fill={colors.emerald} fillRule="nonzero">
          <rect x="1045" y="763" width="280" height="20" rx="10">
            <animate
              attributeName="width"
              values="0;280;280;280;280"
              dur="4s"
              repeatCount="indefinite"
            />
          </rect>
          <rect x="1045" y="883" width="280" height="20" rx="10">
            <animate
              attributeName="width"
              values="0;0;280;280;280"
              dur="4s"
              repeatCount="indefinite"
            />
          </rect>
          <rect x="1045" y="1003" width="280" height="20" rx="10">
            <animate
              attributeName="width"
              values="0;0;0;280;280"
              dur="4s"
              repeatCount="indefinite"
            />
          </rect>
        </g>
        <g fill={colors.oxfordBlue8} fillRule="nonzero">
          <rect x="936" y="749" width="46" height="46" rx="23" />
          <rect x="936" y="869" width="46" height="46" rx="23" />
          <rect x="936" y="989" width="46" height="46" rx="23" />
        </g>
        <g fill={colors.emerald} fillRule="nonzero">
          <rect x="936" y="749" width="46" height="46" rx="23">
            <animate
              attributeName="opacity"
              values="0;0;1;1;1;1;1;1"
              calcMode="discrete"
              dur="4s"
              repeatCount="indefinite"
            />
          </rect>
          <rect x="936" y="869" width="46" height="46" rx="23">
            <animate
              attributeName="opacity"
              values="0;0;0;0;1;1;1;1"
              calcMode="discrete"
              dur="4s"
              repeatCount="indefinite"
            />
          </rect>
          <rect x="936" y="989" width="46" height="46" rx="23">
            <animate
              attributeName="opacity"
              values="0;0;0;0;0;0;1;1"
              calcMode="discrete"
              dur="4s"
              repeatCount="indefinite"
            />
          </rect>
        </g>
        <path
          d="M957.433,780.8l-5.995,-7.708l3.487,-2.713l2.838,3.65l9.583,-10.121l3.208,3.038l-13.121,13.854Z"
          style={{ fill: colors.white, fillRule: 'nonzero' }}
        />
        <path
          d="M957.433,900.038l-5.995,-7.709l3.487,-2.712l2.838,3.65l9.583,-10.121l3.208,3.037l-13.121,13.854Z"
          style={{ fill: colors.white, fillRule: 'nonzero' }}
        />
        <path
          d="M957.433,1019.27l-5.995,-7.708l3.487,-2.713l2.838,3.65l9.583,-10.121l3.208,3.038l-13.121,13.854Z"
          style={{ fill: colors.white, fillRule: 'nonzero' }}
        />
        <path
          d="M1410.37,792.921c11.362,-0 20.575,-9.213 20.575,-20.575c-0,-11.367 -9.213,-20.575 -20.575,-20.575c-11.363,-0 -20.575,9.208 -20.575,20.575c-0,11.362 9.212,20.575 20.575,20.575Z"
          style={{ fill: colors.oxfordBlue8, fillRule: 'nonzero' }}
        />
        <path
          d="M1410.37,912.154c11.362,0 20.575,-9.212 20.575,-20.575c-0,-11.366 -9.213,-20.575 -20.575,-20.575c-11.363,0 -20.575,9.209 -20.575,20.575c-0,11.363 9.212,20.575 20.575,20.575Z"
          style={{ fill: colors.oxfordBlue8, fillRule: 'nonzero' }}
        />
        <path
          d="M1410.37,1031.39c11.362,-0 20.575,-9.209 20.575,-20.575c-0,-11.363 -9.213,-20.575 -20.575,-20.575c-11.363,-0 -20.575,9.212 -20.575,20.575c-0,11.366 9.212,20.575 20.575,20.575Z"
          style={{ fill: colors.oxfordBlue8, fillRule: 'nonzero' }}
        />
        <path
          d="M1405.17,765.733l-1.712,1.713l11.504,11.504l1.712,-1.712l-11.504,-11.505Z"
          style={{ fill: colors.white, fillRule: 'nonzero' }}
        />
        <path
          d="M1415.57,765.733l-11.504,11.509l1.712,1.708l11.504,-11.504l-1.712,-1.713Z"
          style={{ fill: colors.white, fillRule: 'nonzero' }}
        />
        <path
          d="M1405.17,884.971l-1.712,1.708l11.504,11.509l1.712,-1.713l-11.504,-11.504Z"
          style={{ fill: colors.white, fillRule: 'nonzero' }}
        />
        <path
          d="M1415.58,884.967l-11.504,11.508l1.712,1.708l11.505,-11.504l-1.713,-1.712Z"
          style={{ fill: colors.white, fillRule: 'nonzero' }}
        />
        <path
          d="M1405.17,1004.21l-1.712,1.713l11.504,11.504l1.712,-1.712l-11.504,-11.505Z"
          style={{ fill: colors.white, fillRule: 'nonzero' }}
        />
        <path
          d="M1415.58,1004.2l-11.504,11.508l1.712,1.709l11.505,-11.504l-1.713,-1.713Z"
          style={{ fill: colors.white, fillRule: 'nonzero' }}
        />
        <path
          d="M963.308,1002.57c1.792,-1.183 3.305,-2.746 4.438,-4.571c1.129,-1.829 1.85,-3.879 2.112,-6.012c0.263,-2.134 0.059,-4.296 -0.6,-6.342c-0.654,-2.046 -1.745,-3.929 -3.2,-5.513l-19.754,-21.533l-18.975,9.608l14.525,28.684c0.946,1.871 2.279,3.521 3.909,4.837c1.629,1.321 3.516,2.279 5.545,2.817c2.025,0.533 4.142,0.637 6.209,0.296c2.071,-0.338 4.041,-1.113 5.791,-2.271Z"
          style={{ fill: colors.lightSalmon5, fillRule: 'nonzero' }}
        />
        <path
          d="M707.958,1066.53c2.134,-0.25 4.188,-0.958 6.025,-2.079c1.834,-1.117 3.4,-2.621 4.6,-4.409c1.196,-1.783 1.992,-3.808 2.33,-5.929c0.337,-2.12 0.212,-4.291 -0.367,-6.358l-7.917,-28.133l-21.25,-0l0,32.146c0,2.1 0.442,4.17 1.3,6.083c0.859,1.912 2.113,3.621 3.679,5.016c1.563,1.392 3.405,2.438 5.405,3.071c2,0.63 4.108,0.834 6.191,0.592l0.004,0Z"
          style={{ fill: colors.lightSalmon5, fillRule: 'nonzero' }}
        />
        <path
          d="M830.796,1278.75l40.171,-6.3l-16.542,-17.329l-23.629,-0l-0,23.629Z"
          style={{ fill: colors.lightSalmon5, fillRule: 'nonzero' }}
        />
        <path
          d="M701.621,1280.33l41.746,11.03c-7.688,-15.95 -12.425,-30.063 -9.45,-39.384l-19.692,-2.362l-12.604,30.716Z"
          style={{ fill: colors.lightSalmon5, fillRule: 'nonzero' }}
        />
        <path
          d="M710.283,1253.55l24.417,4.725l66.95,-215.837l28.354,215.033l25.992,-0l-2.363,-304.042l-100.833,0l-42.517,300.121Z"
          style={{ fill: colors.emerald, fillRule: 'nonzero' }}
        />
        <path
          d="M838.508,1288.32l39.738,-13.071c1.054,-0.346 2.021,-0.917 2.833,-1.671c0.817,-0.754 1.458,-1.671 1.884,-2.696c0.424,-1.025 0.629,-2.129 0.591,-3.237c-0.042,-1.109 -0.317,-2.196 -0.812,-3.192c-0.684,-1.362 -1.75,-2.492 -3.075,-3.25c-1.321,-0.754 -2.838,-1.104 -4.359,-1.004l-9.012,0.608l-3.534,-1.987c-0.37,-0.213 -0.799,-0.3 -1.229,-0.258c-0.425,0.041 -0.825,0.22 -1.15,0.504c-0.321,0.283 -0.546,0.658 -0.641,1.075c-0.096,0.421 -0.059,0.854 0.104,1.25l3.258,7.92l-33.083,-2.366l-1.634,13.062c-0.162,1.296 0.005,2.613 0.488,3.83c0.479,1.216 1.262,2.291 2.271,3.12c1.012,0.834 2.216,1.396 3.5,1.638c1.287,0.242 2.612,0.154 3.854,-0.254l0.008,-0.021Z"
          style={{ fill: colors.oxfordBlue3, fillRule: 'nonzero' }}
        />
        <path
          d="M704.188,1294.33l43.2,2.804c1.145,0.071 2.295,-0.1 3.366,-0.508c1.075,-0.412 2.046,-1.046 2.85,-1.867c0.808,-0.816 1.425,-1.8 1.817,-2.879c0.392,-1.079 0.546,-2.233 0.454,-3.375c-0.129,-1.575 -0.717,-3.071 -1.696,-4.308c-0.975,-1.238 -2.296,-2.159 -3.796,-2.646l-8.887,-2.917l-2.625,-3.271c-0.275,-0.345 -0.654,-0.595 -1.079,-0.716c-0.425,-0.121 -0.875,-0.109 -1.292,0.041c-0.417,0.15 -0.775,0.421 -1.029,0.784c-0.254,0.362 -0.388,0.796 -0.383,1.237l0.07,8.854l-30.833,-15.045l-6.612,11.904c-0.659,1.179 -1.005,2.508 -1.009,3.862c-0.008,1.354 0.329,2.684 0.979,3.871c0.646,1.188 1.584,2.192 2.725,2.921c1.142,0.725 2.446,1.154 3.796,1.242l-0.017,0.012Z"
          style={{ fill: colors.oxfordBlue3, fillRule: 'nonzero' }}
        />
        <path
          d="M796.138,783.308c16.095,0 29.141,-13.05 29.141,-29.141c0,-16.096 -13.046,-29.142 -29.141,-29.142c-16.096,0 -29.142,13.046 -29.142,29.142c-0,16.091 13.046,29.141 29.142,29.141Z"
          style={{ fill: colors.lightSalmon5, fillRule: 'nonzero' }}
        />
        <path
          d="M777.233,807.729l40.171,0l0,-38.596l-40.171,0c3.109,12.675 3.109,25.917 0,38.596Z"
          style={{ fill: colors.lightSalmon5, fillRule: 'nonzero' }}
        />
        <path
          d="M747.304,957.388l110.275,1.575l11.029,-116.5c0,-7.605 -2.379,-15.013 -6.808,-21.192c-4.425,-6.179 -10.679,-10.817 -17.875,-13.259l-17.083,-5.795l-9.45,-11.03c-12.784,-2.387 -25.921,-2.116 -38.596,0.788l-10.242,14.967l-11.025,3.52c-7.496,2.388 -14.008,7.15 -18.562,13.567c-4.55,6.417 -6.892,14.138 -6.667,22.004c8.225,42.384 14.604,81.771 15.004,111.354Z"
          style={{ fill: colors.oxfordBlue, fillRule: 'nonzero' }}
        />
        <path
          d="M687.442,1019.6l27.566,3.15l31.509,-126.817l3.15,-81.916l-0.929,0.45c-7.292,3.525 -13.663,8.704 -18.609,15.125c-4.942,6.416 -8.325,13.895 -9.875,21.85l-32.812,168.158Z"
          style={{ fill: colors.oxfordBlue, fillRule: 'nonzero' }}
        />
        <path
          d="M928.467,972.35l21.25,-10.242l-62.379,-116.395c-9.059,-16.905 -23.784,-30.076 -41.592,-37.201l11.817,74.042l70.904,89.796Z"
          style={{ fill: colors.oxfordBlue, fillRule: 'nonzero' }}
        />
        <path
          d="M778.129,780.404c0,0 28.579,-1.696 29.196,-6.637c0.617,-4.942 4.792,-16.221 9.733,-15.6c4.942,0.621 18.642,-15.859 15.709,-22.5c-2.934,-6.642 -16.784,-31.1 -45.05,-19.83c-8.975,3.296 -16.309,9.971 -20.434,18.601c-4.129,8.625 -4.721,18.525 -1.65,27.583c3.579,6.504 7.763,12.658 12.496,18.383Z"
          style={{ fill: colors.oxfordBlue1, fillRule: 'nonzero' }}
        />
        <path
          d="M1477.53,1137.62c-0.313,-0 -0.617,0.104 -0.862,0.292c-0.246,0.191 -0.425,0.454 -0.509,0.758l-2.296,8.525c-0.058,0.208 -0.066,0.429 -0.02,0.641c0.041,0.217 0.129,0.417 0.262,0.592c0.133,0.171 0.304,0.313 0.5,0.408c0.196,0.096 0.413,0.15 0.629,0.15l43.654,0c0.225,0 0.45,-0.054 0.65,-0.158c0.205,-0.104 0.375,-0.258 0.509,-0.442c0.129,-0.183 0.216,-0.395 0.246,-0.62c0.033,-0.225 0.012,-0.455 -0.063,-0.667l-2.95,-8.525c-0.096,-0.279 -0.279,-0.521 -0.521,-0.692c-0.237,-0.171 -0.525,-0.262 -0.821,-0.262l-38.408,-0Z"
          style={{ fill: colors.emerald, fillRule: 'nonzero' }}
        />
        <path
          d="M1413.78,1137.62c-0.312,-0 -0.616,0.1 -0.862,0.292c-0.25,0.187 -0.429,0.454 -0.508,0.758l-2.296,8.525c-0.059,0.208 -0.067,0.429 -0.025,0.641c0.046,0.217 0.133,0.417 0.266,0.592c0.134,0.171 0.305,0.313 0.5,0.408c0.196,0.096 0.409,0.15 0.63,0.15l43.654,0c0.225,0 0.45,-0.054 0.65,-0.158c0.2,-0.104 0.375,-0.258 0.508,-0.442c0.129,-0.183 0.217,-0.395 0.246,-0.62c0.033,-0.225 0.012,-0.455 -0.063,-0.667l-2.95,-8.525c-0.096,-0.279 -0.279,-0.521 -0.521,-0.692c-0.237,-0.171 -0.525,-0.262 -0.82,-0.262l-38.409,-0Z"
          style={{ fill: colors.emerald, fillRule: 'nonzero' }}
        />
      </g>
    </svg>
  );
};
