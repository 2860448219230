import './Checkbox.scss';

/** Component to display checkbox with label, takes all default checkbox props */
export const Checkbox: React.FC<
  React.InputHTMLAttributes<HTMLInputElement> & {
    label?: string | JSX.Element;
    field?: any;
    color?: 'primary' | 'light';
    checked?: boolean;
    partiallyChecked?: boolean;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    showReverse?: boolean;
  }
> = ({
  className,
  disabled,
  readOnly,
  id,
  value,
  color = 'primary',
  field,
  label,
  checked,
  partiallyChecked = false,
  showReverse = false,
  onChange,
}) => (
  <div
    className={`Checkbox ${className || ''} ${showReverse ? 'reverse' : ''}`}
  >
    <input
      {...field}
      type="checkbox"
      className={`Checkbox__input Checkbox__input--${color} ${
        partiallyChecked ? 'partiallyChecked' : ''
      } ${disabled ? 'disabled' : ''}`}
      id={id}
      readOnly={readOnly}
      disabled={disabled}
      value={value}
      checked={checked}
      onChange={onChange}
    />
    {label && (
      <label htmlFor={id} className="Checkbox__label">
        {label}
      </label>
    )}
  </div>
);
