import i18n from 'i18next';
import { saveAs } from 'file-saver';
import { uuid4 } from '@sentry/utils';

import store from '@redux/store';
import { ENDPOINTS } from '@constants/endpoints';
import DigitalAsset, {
  DigitalAssetBrief,
  UpdateDigitalAssetBody,
} from '@models/digital-asset';

import { clientWithToken, PAGE_SIZE } from '.';

import { toast } from '@components/ToastNotification/ToastManager';
import { RAsset } from '@redux/slices/asset';
import {
  ListConfiguration,
  ListablePayload,
  ListableState,
} from '@models/filter';
import { ALL_EXTENSIONS } from '@utils/files';
import { AxiosRequestConfig, CancelToken } from 'axios';

export namespace DamApi {
  export namespace List {
    export type State = ListableState<DigitalAsset, typeof damListConfig>;
    export type Query = ListablePayload<typeof damListConfig>;
  }
}

export const damListConfig = {
  sort: [
    { sortBy: 'updatedAt', sortOrder: 'desc' },
    { sortBy: 'updatedAt', sortOrder: 'asc' },
    { sortBy: 'lastUsedAt', sortOrder: 'desc' },
    { sortBy: 'lastUsedAt', sortOrder: 'asc' },
    { sortBy: 'createdAt', sortOrder: 'desc' },
    { sortBy: 'createdAt', sortOrder: 'asc' },
    { sortBy: 'title', sortOrder: 'desc' },
    { sortBy: 'title', sortOrder: 'asc' },
  ],
  filters: [
    {
      name: 'fileType',
      multiple: true,
      hidden: false,
      literal: true,
      options: ALL_EXTENSIONS,
    },
  ],
} as const satisfies ListConfiguration;

export const deleteDigitalAssets = async (assetsId: string[]) => {
  const url = `${ENDPOINTS.dam.assets}/${assetsId.join(',')}`;
  try {
    await clientWithToken.delete<unknown>(url, {});
    toast.show({
      message: `${i18n.t(
        `digitalAssets.asset${assetsId.length > 1 ? 's' : ''}`,
      )} ${i18n.t('global.deleted_successfully')}`,
      error: false,
    });
    return {
      message: '',
      isSuccess: true,
    };
  } catch (errorObj: any) {
    const { errors, body } = errorObj;
    toast.show({
      message: errors?.[0]?.message,
      error: true,
    });
    return {
      message: errors?.[0]?.message,
      body,
      isSuccess: false,
    };
  }
};

export const getAsset = async (assetId: string): Promise<DigitalAssetBrief> => {
  const url = `${ENDPOINTS.dam.assets}/${assetId}`;
  return clientWithToken
    .get<{ body: DigitalAssetBrief }>(url)
    .then((r) => r.data.body);
};

export const downloadDigitalAssets = async (assetsId: string[]) => {
  try {
    const response = await clientWithToken.post<BlobPart>(
      ENDPOINTS.dam.download,
      assetsId,
      {
        responseType: 'arraybuffer',
      },
    );
    const blob = new Blob([response.data], {
      type: 'application/octet-stream',
    });
    const filename = 'assets.zip';
    saveAs(blob, filename);
    toast.show({
      message: i18n.t('global.downloaded_successfully'),
      error: false,
    });
  } catch (errorObj: any) {
    const { errors } = errorObj;
    toast.show({
      message: errors?.[0]?.message,
      error: true,
    });
  }
};

export const uploadDigitalAsset = async (
  formData: FormData,
  {
    onUploadProgress,
    cancelToken,
  }: {
    onUploadProgress?: AxiosRequestConfig['onUploadProgress'];
    cancelToken?: CancelToken;
  },
) => {
  const res = await clientWithToken.post<unknown>(
    ENDPOINTS.dam.assets,
    formData,
    {
      onUploadProgress,
      cancelToken,
    },
  );
  return res.data;
};

export const updateDigitalAsset = async (
  assetId: string,
  data: UpdateDigitalAssetBody,
) => {
  try {
    await clientWithToken.patch<unknown>(
      `${ENDPOINTS.dam.assets}/${assetId}`,
      data,
    );
    store.dispatch(RAsset.resetResults());
    toast.show({
      message: i18n.t('digitalAssets.updated_successfully'),
      error: false,
    });
    return {
      success: true,
    };
  } catch (errorObj: any) {
    const { errors } = errorObj;
    toast.show({
      message: errors?.[0]?.message,
      error: true,
    });
  }
};

export const getAssetTagsByQuery = async (query: string) => {
  try {
    const response = await clientWithToken.get<{ body: { tags: unknown } }>(
      `${ENDPOINTS.dam.assetTags}?${ENDPOINTS.dam.queryParams.query}=${query}`,
    );
    return {
      data: response?.data?.body?.tags,
      isSuccess: true,
    };
  } catch (errorObj: any) {
    const { errors } = errorObj;
    toast.show({
      message: errors?.[0]?.message,
      error: true,
    });
  }
};

export const replaceDigitalAsset = async (
  assetId: string,
  data: UpdateDigitalAssetBody,
  isEdit = false,
) => {
  try {
    const response = await clientWithToken.patch<{ body: unknown }>(
      `${ENDPOINTS.dam.replaceAsset}/${assetId}`,
      data,
    );
    store.dispatch(RAsset.getDigitalAssetByIdSaga(assetId));
    toast.show({
      message: i18n.t(
        `digitalAssets.${isEdit ? 'edited' : 'replaced'}_successfully`,
      ),
      error: false,
    });
    return {
      data: response?.data?.body,
      isSuccess: true,
    };
  } catch (errorObj: any) {
    const { errors } = errorObj;
    toast.show({
      message: errors?.[0]?.message,
      error: true,
    });
  }
};

export const removeDigitalAssetUsage = async (
  assetId: string,
  consumerId: string,
  assetConsumer: string,
): Promise<
  | { isSuccess: true; data: { usage: { [key: string]: string[] } } }
  | { isSuccess: false; message?: string; body: unknown }
> => {
  const { removeUsage, queryParams } = ENDPOINTS.dam;
  const url = `${removeUsage}/${assetId}?${queryParams.consumerId}=${consumerId}&${queryParams.assetConsumer}=${assetConsumer}`;
  try {
    const response = await clientWithToken.patch<{
      body: { usage: { [key: string]: string[] } };
    }>(url, {});
    toast.show({
      message: i18n.t('digitalAssets.usage_removed_successfully'),
      error: false,
    });
    return {
      isSuccess: true,
      data: response.data.body,
    };
  } catch (errorObj: any) {
    const { errors, body } = errorObj;
    toast.show({
      message: errors?.[0]?.message,
      error: true,
    });
    return {
      isSuccess: false,
      message: errors?.[0]?.message,
      body,
    };
  }
};

export const uploadDigitalAssetUrl = async (
  url: string,
): Promise<
  | {
      isSuccess: true;
      data: { fileName: string };
    }
  | { isSuccess: false; message?: string; body: unknown }
> => {
  const { uploadUrl } = ENDPOINTS.dam;
  const tenantId = store.getState().keyCloak.keyCloak.clientId;

  try {
    const response = await clientWithToken.post<{ body: { fileName: string } }>(
      uploadUrl,
      {
        url: url,
        tenantId: tenantId,
        publicId: uuid4(),
        referenceID: uuid4(),
        altText: '',
        tags: [],
        description: '',
      },
    );

    toast.show({
      message: i18n.t('global.file_uploaded'),
      error: false,
    });
    return {
      data: response?.data.body,
      isSuccess: true,
    };
  } catch (errorObj: any) {
    const { errors, body } = errorObj;
    toast.show({
      message: errors?.[0]?.message,
      error: true,
    });
    return {
      message: errors?.[0]?.message,
      body,
      isSuccess: false,
    };
  }
};

export const listAssets = async ({
  page,
  searchText,
  sortOrder,
  sortBy,
  filters,
}: DamApi.List.Query): Promise<DigitalAssetBrief[]> => {
  const url = ENDPOINTS.dam.v3.list;
  const query = new URLSearchParams({
    page: page + '',
    size: PAGE_SIZE + '',
  });
  if (searchText) query.append('searchText', searchText);
  query.append('sortOrder', sortOrder);
  query.append('sortBy', sortBy);
  for (const filter in filters) {
    const value = filters[filter as keyof typeof filters];
    if (value) {
      if (typeof value === 'string') query.append(filter, value);
      else for (const val of value) query.append(filter, val);
    }
  }

  const result = await clientWithToken.get<DigitalAssetBrief[]>(
    `${url}?${query}`,
  );
  return result.data;
};

export const countAssets = async ({
  searchText,
  filters,
}: Pick<DamApi.List.Query, 'searchText' | 'filters'>): Promise<number> => {
  const url = ENDPOINTS.dam.v3.count;
  const query = new URLSearchParams({
    page: '0',
    size: PAGE_SIZE + '',
  });
  if (searchText) query.append('searchText', searchText);
  for (const filter in filters) {
    const value = filters[filter as keyof typeof filters];
    if (value) {
      if (typeof value === 'string') query.append(filter, value);
      else for (const val of value) query.append(filter, val);
    }
  }

  const result = await clientWithToken.get<{ count: number }>(
    `${url}?${query}`,
  );
  return result.data.count;
};
