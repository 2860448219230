import { registerSideEffects } from '@redux/sideEffects';
import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '@redux/store';
import { DateRange } from '@models/rangeDatePicker';
import { Connector, GalleryStatus } from '@apis/connector';
import { countProducts } from '@apis/products';
import { toast } from '@components/ToastNotification/ToastManager';
import { fetchTenantLogo } from '@apis/tenant';
import { graphClient } from '@apis/graphql';
import { TENANT_ANALYTICS_METADATA } from '@apis/graphql/dashboard';
import { isError } from '@utils/ImproperError';

type TenantState = {
  logo: string | null;
  currency: string | null;
  onboardingDate: string | null;
  storeUrl: string;
  defaultRange: string | null;
  selectedDate: DateRange | null;
  customDate: boolean | false;
  analyticsPeriod: AnalyticsPeriod | null;
  galleryStatus: GalleryStatus;
  onboarded: boolean | 'unknown';
};

type AnalyticsPeriod = {
  start: string | null;
  end: string | null;
  publishDate?: number;
};

const initialState: TenantState = {
  logo: null,
  currency: null,
  onboardingDate: null,
  storeUrl: '',
  defaultRange: null,
  selectedDate: null,
  customDate: false,
  analyticsPeriod: {
    start: null,
    end: null,
  },
  galleryStatus: 'enabled',
  onboarded: localStorage.getItem('onboardingComplete') === 'true' || 'unknown',
};

const { actions, reducer } = createSlice({
  name: 'tenant',
  initialState,
  reducers: {
    FETCH_TENANT_CURRENCY_ONBOARDING_DATE_DONE: (
      state,
      {
        payload,
      }: {
        payload: {
          currency: string;
          onboardedOn: string;
          storeUrl: string;
          defaultRange: string;
        };
      },
    ) => {
      state.currency = payload.currency;
      state.onboardingDate = payload.onboardedOn;
      state.storeUrl = payload.storeUrl;
      state.defaultRange = payload.defaultRange;
    },
    FETCH_TENANT_CURRENCY_ONBOARDING_DATE_ERROR: (state) => {
      state.currency = null;
      state.onboardingDate = null;
    },
    FETCH_TENANT_LOGO_DONE: (state, { payload }: { payload: string }) => {
      state.logo = payload;
    },
    FETCH_TENANT_LOGO_ERROR: (state) => {
      state.logo = null;
    },
    SET_SELECTED_DATE: (
      state,
      { payload }: { payload: TenantState['selectedDate'] },
    ) => {
      state.selectedDate = payload;
      state.customDate = true;
    },
    SET_ANALYTICS_PERIOD: (
      state,
      { payload }: { payload: AnalyticsPeriod },
    ) => {
      state.analyticsPeriod = payload;
    },
    setGalleryStatus: (state, { payload }: { payload: GalleryStatus }) => {
      state.galleryStatus = payload;
    },
    setOnboarded: (state, { payload }: { payload: boolean }) => {
      localStorage.setItem('onboardingComplete', payload + '');
      state.onboarded = payload;
    },
  },
});

const thunks = {
  fetchTenantLogoSaga: (): AppThunk => async (dispatch, getState) => {
    try {
      const logoUrl = await fetchTenantLogo(
        getState().keyCloak.keyCloak.realm!,
      );
      dispatch(RTenant.FETCH_TENANT_LOGO_DONE(logoUrl));
    } catch (e) {
      dispatch(RTenant.FETCH_TENANT_LOGO_ERROR());
    }
  },
  fetchTenantCurrencyAndOnboardDateSaga: (): AppThunk => async (dispatch) => {
    try {
      const {
        data: { metadata },
      } = await graphClient.query({
        query: TENANT_ANALYTICS_METADATA,
      });
      dispatch(RTenant.FETCH_TENANT_CURRENCY_ONBOARDING_DATE_DONE(metadata));
    } catch (e) {
      dispatch(RTenant.FETCH_TENANT_CURRENCY_ONBOARDING_DATE_ERROR());
    }
  },
  fetchGalleryStatus: (): AppThunk => async (dispatch) => {
    // TODO this should be on tenant service
    const { data } = await Connector.getGalleryStatus();
    dispatch(RTenant.setGalleryStatus(data.galleryEnabled));
  },
  checkOnboardingComplete: (): AppThunk => async (dispatch) => {
    const result = await countProducts({
      searchText: '',
      filters: {
        status: 'onboarded',
        optimizationStatus: 'all',
      },
    });
    if (isError(result)) toast.show(result);
    else dispatch(RTenant.setOnboarded(result !== 0));
  },
} satisfies { [key: string]: (...args: any[]) => AppThunk };

registerSideEffects();

export const RTenant = Object.assign(actions, thunks);

export default reducer;
