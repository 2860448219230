import { Loading } from '@components/Loading/Loading';
import { ROUTES as ROUTES_LEGACY } from '@constants/routes';
import { Suspense } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import ApplicationLayout from '../layouts/ApplicationLayout';
import { ROUTES } from './routes';
import { PageComponent as TestFlow } from './test-flow/+page';

export const ApplicationRouter: React.FC<{ showOnboardingFlow: boolean }> = ({
  showOnboardingFlow,
}) => (
  <BrowserRouter>
    <Route
      render={(props) => (
        <ApplicationLayout
          {...props}
          showSidebar={
            props.location.pathname !== '/404' && !showOnboardingFlow
          }
        >
          <Switch>
            {showOnboardingFlow ? (
              <TestFlow />
            ) : (
              ROUTES.map(({ path, Component }) => (
                <Route key={path} path={path} exact>
                  <Suspense fallback={<Loading />}>
                    <Component />
                  </Suspense>
                </Route>
              ))
            )}
            <Route path="/" exact>
              <Redirect to={ROUTES_LEGACY.dashboard.base} />
            </Route>
            <Route path="*" exact>
              <Redirect to="/404" />
            </Route>
          </Switch>
        </ApplicationLayout>
      )}
    />
  </BrowserRouter>
);
