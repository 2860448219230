import { cloneElement } from 'react';
import ReactTooltip, { Place, TooltipProps } from 'react-tooltip';

import './Tooltip.scss';

export const CustomToolTip: React.FC<{
  id: string;
  tipText: string | JSX.Element | JSX.Element[];
  children: any;
  hideToolTip?: boolean;
  placement?: Place;
  customPosition?: {
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
  };
}> = ({
  id,
  tipText,
  children,
  hideToolTip = false,
  placement = 'bottom',
  customPosition = {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
}) => {
  /** This function overrides tooltip width to prevent it from overflowing the window */
  const maxScreenPosition: TooltipProps['overridePosition'] = (
    { left, top },
    _e,
    _t,
    node,
  ) => ({
    top,
    left: typeof node === 'string' ? left : Math.max(left, 0),
  });

  return (
    <>
      <ReactTooltip
        id={id}
        effect="solid"
        offset={customPosition}
        place={placement}
        disable={hideToolTip}
        overridePosition={maxScreenPosition}
      >
        <span className="text-break">{tipText}</span>
      </ReactTooltip>
      {cloneElement(children, { 'data-tip': true, 'data-for': id })}
    </>
  );
};
