import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  TypedDocumentNode,
} from '@apollo/client';
import store from '@redux/store';
import { BASE } from '..';

export const graphClient = new ApolloClient({
  link: ApolloLink.from([
    new ApolloLink((operation, forward) => {
      const kcInstance = store.getState().keyCloak.keyCloak;

      operation.setContext(({ headers = {}, uri = '' }) => ({
        headers: {
          ...headers,
          'x-tenant-id': kcInstance.clientId,
          authorization: `Bearer ${kcInstance.token}`,
        },
        uri: BASE + '/analytics/graphql?operation=' + operation.operationName,
      }));

      return forward(operation);
    }),
    new HttpLink({ uri: BASE + '/analytics/graphql' }),
  ]),
  cache: new InMemoryCache(),
});

/** Data broken down by base, contextualized, and test traffic */
export type RateData = {
  /** The performance of the control/base product/page */
  base: number;
  /** The combined performance of all versions for the product/page */
  version: number;
  /** The combined performance of all test versions for the product/page */
  test: number;
  /** The combined performance of all contextualized versions for the product/page */
  contextualized: number;
};

/** Data compared from base to heuristic versions */
export type TimeSeriesData =
  | {
      /** The date of the entry */
      date: string;
      /** The performance of the control/base product/page for that date */
      base: number;
      /** The combined performance of all versions for the product/page for that date */
      version: number;
      /** The combined performance of all test versions for the product/page for that date */
      test: number;
      /** The combined performance of all contextualized versions for the product/page for that date */
      contextualized: number;
    }[]
  | null;

export type FilterTab = {
  label: string;
  id: string;
  query: TypedDocumentNode<{
    product: { [key: string]: { timeseries: TimeSeriesData } };
  }>;
};
/** // TODO unify with {@see FilterTab }  */
export type FilterTabTitle<T extends 'tenant' | 'product' = 'product'> = {
  title: string;
  id: string;
  query: TypedDocumentNode<
    { product: { [key: string]: { timeseries: TimeSeriesData } } },
    StartEndDates &
      (T extends 'tenant'
        ? { product_id?: string; version_id?: string }
        : { product_id: string; version_id?: string })
  >;
};
/** // TODO unify with {@see FilterTab }  */
export type FilterTabPage = {
  label: string;
  id: string;
  query: TypedDocumentNode<{
    page: { [key: string]: { timeseries: TimeSeriesData } };
  }>;
};

/** GraphQL variable for a time period specifying the first and last date of the query */
export type StartEndDates = { start: string; end: string };

/** GraphQL variable if no variables are required */
export type NoVariables = Record<string, never>;
