import { LegacyVersion } from '@models/productVersion';

export enum CREATE_VERSION_STEP {
  overview = 'OVERVIEW',
  publishing = 'PUBLISHING',
  content = 'CONTENT',
  saveAndExit = 'SAVE_AND_EXIT',
}

export type AssetType = 'image' | 'video' | 'doc';

export enum DUE_TYPE {
  asap = 'ASAP',
  toBeConfirmed = 'TBC',
  onAdate = 'ON_A_DATE',
}

export const DUE_TYPE_NAME = {
  [DUE_TYPE.asap]: 'As soon as possbile',
  [DUE_TYPE.toBeConfirmed]: 'To be confirmed',
  [DUE_TYPE.onAdate]: 'On a set date',
};

export enum VersionTargetingOption {
  anonymous = 'ANONYMOUS_TRAFFIC',
  context = 'SAVED_AUDIENCE',
}

export enum FieldName {
  title = 'title',
  description = 'description',
  publishDate = 'publishDate',
  expiryDate = 'expiryDate',
  dueType = 'dueType',
  audienceType = 'audienceType',
  controlGroupValue = 'controlGroupValue',
  contextIds = 'contextIds',
  published = 'published',
  contentList = 'contentList',
  taskList = 'taskList',
  contentReference = 'contentReference',
  isTestVersion = 'isTestVersion',
  isActive = 'isActive',
  completedSteps = 'completedSteps',
  thumbnail = 'thumbnail',
  experimentIds = 'experimentIds',
  workflowEnabled = 'workflowEnabled',
  highPriority = 'highPriority',
}

export const PRODUCT_VERSION_INITIAL_STATE: LegacyVersion.UpdateBody = {
  [FieldName.title]: '',
  [FieldName.description]: '',
  [FieldName.publishDate]: undefined,
  [FieldName.expiryDate]: undefined,
  [FieldName.dueType]: DUE_TYPE.asap,
  [FieldName.audienceType]: VersionTargetingOption.anonymous,
  [FieldName.controlGroupValue]: 0,
  [FieldName.contextIds]: [],
  [FieldName.published]: false,
  [FieldName.contentList]: [],
  [FieldName.taskList]: [],
  [FieldName.contentReference]: '',
  [FieldName.isTestVersion]: true,
  [FieldName.isActive]: true,
  [FieldName.experimentIds]: [],
  [FieldName.completedSteps]: '',
  [FieldName.workflowEnabled]: false,
  [FieldName.highPriority]: false,
};

export enum CREATION_TYPE {
  saveAndExit = 'SAVE_N_EXIT',
  next = 'NEXT',
}
