import { colors } from '@constants/colors';

export const CrossIcon = ({
  height = '16px',
  width = '16px',
  fill = colors.manatte,
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.8535 12.1465C12.9 12.193 12.9368 12.2481 12.962 12.3088C12.9871 12.3695 13.0001 12.4346 13.0001 12.5003C13.0001 12.566 12.9871 12.631 12.962 12.6917C12.9368 12.7524 12.9 12.8076 12.8535 12.854C12.8071 12.9005 12.7519 12.9373 12.6912 12.9625C12.6305 12.9876 12.5655 13.0006 12.4998 13.0006C12.4341 13.0006 12.369 12.9876 12.3083 12.9625C12.2476 12.9373 12.1925 12.9005 12.146 12.854L7.99979 8.70715L3.85354 12.854C3.75972 12.9478 3.63247 13.0006 3.49979 13.0006C3.36711 13.0006 3.23986 12.9478 3.14604 12.854C3.05222 12.7602 2.99951 12.633 2.99951 12.5003C2.99951 12.3676 3.05222 12.2403 3.14604 12.1465L7.29291 8.00028L3.14604 3.85403C3.05222 3.76021 2.99951 3.63296 2.99951 3.50028C2.99951 3.3676 3.05222 3.24035 3.14604 3.14653C3.23986 3.05271 3.36711 3 3.49979 3C3.63247 3 3.75972 3.05271 3.85354 3.14653L7.99979 7.2934L12.146 3.14653C12.2399 3.05271 12.3671 3 12.4998 3C12.6325 3 12.7597 3.05271 12.8535 3.14653C12.9474 3.24035 13.0001 3.3676 13.0001 3.50028C13.0001 3.63296 12.9474 3.76021 12.8535 3.85403L8.70666 8.00028L12.8535 12.1465Z"
      fill={fill}
    />
  </svg>
);
