import {
  ALL_EXTENSIONS,
  ALL_IMAGES,
  ALL_VIDEOS,
  ASSET_TYPES,
} from '@utils/files';

type FilterAssetTypes<Filter extends (typeof ASSET_TYPES)[number]['type']> =
  Extract<(typeof ASSET_TYPES)[number], { type: Filter }>;

export type Image = 'jpg' | 'png' | 'jpeg';
export type ImageType = 'image/jpeg' | 'image/jpg' | 'image/png';

export type SVG = 'svg';
export type SVGType = 'image/svg+xml';

export type GIF = 'gif';
export type GIFType = 'image/gif';

export type AllImage = FilterAssetTypes<'image'>['extension'];
export type AllImageType = (typeof ALL_IMAGES)[number];

export type Video = FilterAssetTypes<'video'>['extension'];
export type VideoType = (typeof ALL_VIDEOS)[number];

export type Document = FilterAssetTypes<'document'>['extension'];
export type DocumentType = FilterAssetTypes<'document'>['mime'];

export type Transcript = FilterAssetTypes<'transcript'>['extension'];
export type TranscriptType = FilterAssetTypes<'transcript'>['mime'];

export type AllFiles = (typeof ALL_EXTENSIONS)[number];
export type AllFilesType = (typeof ASSET_TYPES)[number]['mime'];

export const allowedFileSizes: { [key in AllFiles]: number } = {
  mp4: 1073741824,
  mov: 1073741824,
  mvp: 1073741824,
  jpg: 52428800,
  png: 52428800,
  jpeg: 52428800,
  svg: 52428800,
  gif: 52428800,
  pdf: 104857600,
  vtt: 52428800,
} as const;
