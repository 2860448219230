import { registerSideEffects } from '@redux/sideEffects';
import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '@redux/store';
import { IGuide, IGuideStatus } from '@models/quickStartGuide';
import { IBackendErrorMessageFormat } from '@models/errorMessage';
import { fetchGuide, fetchGuideStatus } from '@apis/quickStartGuide';

type QuickStartGuideState = {
  guide: IGuide | null;
  guideStatus: IGuideStatus | null;
  errors: null | IBackendErrorMessageFormat[];
};

const initialState: QuickStartGuideState = {
  guide: null,
  guideStatus: null,
  errors: null,
};

const { actions, reducer } = createSlice({
  name: 'quickStartGuide',
  initialState,
  reducers: {
    FETCH_GUIDE_DONE: (state, { payload }: { payload: IGuide }) => {
      state.guide = payload;
    },
    FETCH_GUIDE_ERROR: (
      state,
      { payload }: { payload: IBackendErrorMessageFormat[] },
    ) => {
      state.errors = payload;
    },
    FETCH_GUIDE_STATUS_DONE: (
      state,
      { payload }: { payload: IGuideStatus },
    ) => {
      state.guideStatus = payload;
    },
  },
});

const thunks = {
  fetchGuideSaga: (): AppThunk => async (dispatch) => {
    try {
      const guide = await fetchGuide();
      dispatch(RQuickStartGuide.FETCH_GUIDE_DONE(guide));
    } catch (errorObj: any) {
      const { errors } = errorObj;
      dispatch(RQuickStartGuide.FETCH_GUIDE_ERROR(errors));
    }
  },
  fetchGuideStatusSaga: (): AppThunk => async (dispatch) => {
    try {
      const status = await fetchGuideStatus();
      dispatch(RQuickStartGuide.FETCH_GUIDE_STATUS_DONE(status));
    } catch (errorObj: any) {
      const { errors } = errorObj;
      dispatch(RQuickStartGuide.FETCH_GUIDE_ERROR(errors));
    }
  },
} satisfies { [key: string]: (...args: any[]) => AppThunk };

registerSideEffects();

export const RQuickStartGuide = Object.assign(actions, thunks);

export default reducer;
