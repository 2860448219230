import { Card } from '../Card/Card';
import { SkeletonText } from '../SkeletonText/SkeletonText';

import './LoadingCard.scss';

export const LoadingCard: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  className = '',
  ...props
}) => (
  <Card className={`LoadingCard ${className}`} {...props}>
    <SkeletonText className="LoadingCard__image" />
    <SkeletonText className="LoadingCard__title" />
    <SkeletonText className="LoadingCard__subTitle" />
    <SkeletonText className="LoadingCard__description" />
  </Card>
);
