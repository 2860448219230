import { useEffect, useRef, useState } from 'react';
import Cropper from 'react-easy-crop';

import { ModalProps } from '@models/modal';
import { DigitalAssetBrief } from '@models/digital-asset';
import { downloadAsset } from '@utils/digitalAssets';

import { HeaderFooterModalLayout } from '../Modals/HeaderFooterModalLayout/HeaderFooterModalLayout';
import { PrimaryButton } from '../PrimaryButton/PrimaryButton';
import { colors } from '@constants/colors';
import { ReactComponent as LeftChevronIcon } from '@assets/icons/left-chevron-big.svg';
import { ReactComponent as RightChevronIcon } from '@assets/icons/right-chevron-big.svg';
import { ReactComponent as ZoomInIcon } from '@assets/icons/zoom-in.svg';
import { ReactComponent as ZoomOutIcon } from '@assets/icons/zoom-out.svg';
import { ReactComponent as DownloadIcon } from '@assets/icons/download.svg';
import { ReactComponent as EditIcon } from '@assets/icons/edit-pen-white.svg';

import './AssetPreviewModal.scss';

/** Displays media in HTML based on media type. */
const AssetPreview: React.FC<{
  asset: DeepReadonly<
    Pick<DigitalAssetBrief, 'format' | 'url' | 'title' | 'fileName'>
  >;
  zoomInAndOut?: boolean;
  zoom: number;
  setZoom: (zoom: number) => void;
}> = ({ asset, zoomInAndOut, zoom, setZoom }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });

  switch (asset.format) {
    case 'mp4':
    case 'mov':
    case 'mvp':
      return <video src={asset.url} className="assetPreview" controls></video>;

    case 'pdf':
      return (
        <object
          data={asset.url}
          type="application/pdf"
          className="assetPreview"
          aria-label="PDF Preview"
        />
      );

    default:
      return zoomInAndOut ? (
        <Cropper
          crop={crop}
          onCropChange={setCrop}
          image={asset.url}
          zoom={zoom}
          onZoomChange={setZoom}
          showGrid={false}
          aspect={1 / 1}
        />
      ) : (
        <img src={asset.url} alt={asset.title} className="assetPreview" />
      );
  }
};

/** Renders HeaderFooterModalLayout with slider */
export const AssetPreviewModal: React.FC<
  ModalProps & {
    /** List of all digital assets to display */
    assets: DeepReadonly<
      Pick<DigitalAssetBrief, 'id' | 'title' | 'format' | 'url' | 'fileName'>[]
    >;
    /** object containing title and onClick action of CTA button */
    callToAction?: {
      text: string;
      callback: React.MouseEventHandler<HTMLButtonElement>;
    };
    zoomInAndOut?: boolean;
    download?: boolean;
    showAssetIndex?: boolean;
    hasUpdatePermission?: boolean;
    edit?: () => void;
  }
> = ({
  assets,
  showModal,
  setShowModal,
  onClose,
  callToAction,
  zoomInAndOut,
  download,
  showAssetIndex,
  hasUpdatePermission = true,
  edit,
}) => {
  const [assetIndex, setAssetIndex] = useState<number>(0);
  const previewDiv = useRef<HTMLDivElement>(null);
  const [translateX, setTranslateX] = useState<number>(0);
  const [zoom, setZoom] = useState<number>(1);

  const zoomIn = () => {
    if (zoom < 8) {
      setZoom((z) => z * 2);
    }
  };

  const zoomOut = () => {
    if (zoom > 1) {
      setZoom((z) => z / 2);
    }
  };

  const onModalClose = () => {
    setAssetIndex(0);
    setTranslateX(0);
    onClose();
  };

  const handleDownloadIconClick = () => {
    const currentAsset = assets[assetIndex];
    downloadAsset(currentAsset.url, currentAsset.fileName, currentAsset.format);
  };

  useEffect(() => {
    if (previewDiv && previewDiv.current) {
      setTranslateX(assetIndex * previewDiv.current?.offsetWidth);
    }
  }, [previewDiv, assetIndex]);

  return (
    <HeaderFooterModalLayout
      showModal={showModal}
      setShowModal={setShowModal}
      onClose={onModalClose}
      isFullScreen
      header={
        <div className="assetPreviewModal__header">
          {assets[assetIndex]?.title}
        </div>
      }
      body={
        <div className="assetPreviewModal">
          {assets.length > 1 && (
            <>
              {assetIndex > 0 && (
                <button
                  className="assetPreviewModal__button assetPreviewModal__button--prev"
                  onClick={() =>
                    setAssetIndex((prev) => (prev === 0 ? prev : prev - 1))
                  }
                >
                  <LeftChevronIcon fill={colors.manatte5} />
                </button>
              )}
              {assetIndex < assets.length - 1 && (
                <button
                  className="assetPreviewModal__button assetPreviewModal__button--next"
                  onClick={() =>
                    setAssetIndex((prev) =>
                      prev === assets.length - 1 ? prev : prev + 1,
                    )
                  }
                >
                  <RightChevronIcon fill={colors.manatte5} />
                </button>
              )}
            </>
          )}

          <div
            className="assetPreviewModal__preview"
            ref={previewDiv}
            style={{
              transform: `translateX(-${translateX}px)`,
            }}
          >
            {assets.map((asset) => (
              <div
                className="assetPreviewModal__preview--container"
                key={asset.id}
              >
                <AssetPreview
                  asset={asset}
                  zoomInAndOut={zoomInAndOut}
                  zoom={zoom}
                  setZoom={setZoom}
                />
              </div>
            ))}
          </div>
        </div>
      }
      footer={
        <div className="assetPreviewModal__footer">
          <div className="assetPreviewModal__footer--cta">
            {hasUpdatePermission && callToAction && (
              <PrimaryButton onClick={callToAction.callback}>
                {callToAction.text}
              </PrimaryButton>
            )}
          </div>
          <div className="assetPreviewModal__footer--index-count">
            {showAssetIndex && (
              <>
                Asset {assetIndex + 1} of {assets.length}
              </>
            )}
          </div>
          <div className="assetPreviewModal__footer--actions">
            {zoomInAndOut && (
              <>
                <button
                  className="assetPreviewModal__footer--action-button"
                  onClick={zoomIn}
                >
                  <ZoomInIcon />
                </button>
                <button
                  className="assetPreviewModal__footer--action-button"
                  onClick={zoomOut}
                >
                  <ZoomOutIcon />
                </button>
              </>
            )}
            {hasUpdatePermission && edit && (
              <button
                className="assetPreviewModal__footer--action-button"
                onClick={edit}
              >
                <EditIcon />
              </button>
            )}
            {download && (
              <button
                className="assetPreviewModal__footer--action-button"
                onClick={handleDownloadIconClick}
              >
                <DownloadIcon />
              </button>
            )}
          </div>
        </div>
      }
    />
  );
};
