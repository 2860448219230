import i18next from 'i18next';

interface IStatus {
  [key: string]: {
    label: string;
    backgroundColor: 'green' | 'yellow' | 'orange' | 'red' | 'gray' | 'blue';
  };
}

export const progressMap: IStatus = {
  NOT_STARTED: {
    backgroundColor: 'red',
    label: i18next.t('status.not_started'),
  },
  TO_DO: {
    backgroundColor: 'red',
    label: i18next.t('status.to_do'),
  },
  IN_PROGRESS: {
    backgroundColor: 'yellow',
    label: i18next.t('status.in_progress'),
  },
  DRAFT: {
    label: i18next.t('status.draft'),
    backgroundColor: 'gray',
  },
  PAUSED: {
    label: i18next.t('status.paused'),
    backgroundColor: 'gray',
  },
  LIVE: {
    label: i18next.t('status.live'),
    backgroundColor: 'green',
  },
  EXPIRED: {
    label: i18next.t('status.expired'),
    backgroundColor: 'red',
  },
  AWAITING_PUBLICATION: {
    label: i18next.t('status.awaiting_publication'),
    backgroundColor: 'orange',
  },
  ON_HOLD: {
    label: i18next.t('status.on_hold'),
    backgroundColor: 'gray',
  },
  READY_FOR_REVIEW: {
    label: i18next.t('status.ready_for_review'),
    backgroundColor: 'red',
  },
  COMPLETED: {
    label: i18next.t('status.completed'),
    backgroundColor: 'green',
  },
  SUBMITTED: {
    label: i18next.t('status.submitted'),
    backgroundColor: 'green',
  },
  IN_TEST: {
    label: i18next.t('status.in_test'),
    backgroundColor: 'green',
  },
  ACTIVE: {
    label: i18next.t('settings.active_status'),
    backgroundColor: 'green',
  },
  PENDING: {
    label: i18next.t('settings.pending_status'),
    backgroundColor: 'orange',
  },
  INACTIVE: {
    label: i18next.t('settings.archived_status'),
    backgroundColor: 'red',
  },
  onboarded: {
    label: i18next.t('status.active'),
    backgroundColor: 'green',
  },
  'non-onboarded': {
    label: i18next.t('status.active'),
    backgroundColor: 'green',
  },
  draft: {
    label: i18next.t('status.draft'),
    backgroundColor: 'blue',
  },
  archived: {
    label: i18next.t('status.archived'),
    backgroundColor: 'gray',
  },
  deleted: {
    label: i18next.t('status.deleted'),
    backgroundColor: 'red',
  },
  NEW: {
    label: i18next.t('global.new'),
    backgroundColor: 'blue',
  },
  UPDATED: {
    label: i18next.t('global.updated'),
    backgroundColor: 'orange',
  },
};
