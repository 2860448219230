import i18next from 'i18next';
import moment from 'moment';

import { toast } from '@components/ToastNotification/ToastManager';
import { GLOBAL_TEMPLATE_CONFIGURATIONS_KEYS } from '@constants/productTemplate';
import { HTML_TAG_MATCHER, SPACE_MATCHER } from '@constants/regex';
import { AnyAttributeValue, AttributeValueMap, Variant } from '@models/product';
import {
  AttributeTypes,
  LegacyTemplate,
  Template,
  TemplateAttribute,
} from '@models/productTemplate';
import { LegacyVersion, ProductVersion, Version } from '@models/productVersion';
import { capitalizeEveryFirstLetter } from './textTransform';
import { migrateVersion } from './migrate';
import DOMPurify from 'dompurify';

export const getHTMLLength = (str: string): number => {
  const sanitized = DOMPurify.sanitize(str);
  const div = document.createElement('div');
  div.innerHTML = sanitized;
  return div.innerText.length;
};

// returns product template validator by id if exists
export const getProductTemplateValidator = (
  validators: LegacyTemplate.IAttributeValidator[],
  id: string,
): LegacyTemplate.IAttributeValidator | null => {
  const validatorObj = validators.find(
    (validator: LegacyTemplate.IAttributeValidator) => validator.name === id,
  );

  return validatorObj ? validatorObj : null;
};

export const getProductKeysFromGlobalTemplateConfigurations = (
  globalTemplateConfigurations: LegacyTemplate.GlobalTemplateConfigurationsObj[],
) => ({
  [GLOBAL_TEMPLATE_CONFIGURATIONS_KEYS.PRODUCT_NAME]:
    getProductDataKeyFromConfigKey(
      GLOBAL_TEMPLATE_CONFIGURATIONS_KEYS.PRODUCT_NAME,
      globalTemplateConfigurations,
    ),
  [GLOBAL_TEMPLATE_CONFIGURATIONS_KEYS.PRODUCT_PRICE]:
    getProductDataKeyFromConfigKey(
      GLOBAL_TEMPLATE_CONFIGURATIONS_KEYS.PRODUCT_PRICE,
      globalTemplateConfigurations,
    ),
  [GLOBAL_TEMPLATE_CONFIGURATIONS_KEYS.PRODUCT_DESCRIPTION]:
    getProductDataKeyFromConfigKey(
      GLOBAL_TEMPLATE_CONFIGURATIONS_KEYS.PRODUCT_DESCRIPTION,
      globalTemplateConfigurations,
    ),
  [GLOBAL_TEMPLATE_CONFIGURATIONS_KEYS.PV_THUMBNAIL]:
    getProductDataKeyFromConfigKey(
      GLOBAL_TEMPLATE_CONFIGURATIONS_KEYS.PV_THUMBNAIL,
      globalTemplateConfigurations,
    ),
});

// returns key name which will be mapped against product data received
const getProductDataKeyFromConfigKey = (
  key: string,
  globalTemplateConfigurations: LegacyTemplate.GlobalTemplateConfigurationsObj[],
) => {
  const productDataKeyNameObj = globalTemplateConfigurations.find(
    (configuration) => configuration.key === key,
  );
  return productDataKeyNameObj?.value[0];
};

export const checkIsAttributeInputValid = (
  attributeValue: string | any[],
  templateAttribute: TemplateAttribute<AttributeTypes>,
  showError = true,
): boolean => {
  if (!templateAttribute.validator) return true;
  const { validator } = templateAttribute;

  const length =
    typeof attributeValue === 'string'
      ? attributeValue.replace(SPACE_MATCHER, ' ').replace(HTML_TAG_MATCHER, '')
          .length
      : attributeValue.length;

  const validMin = validator.min === undefined ? true : length >= validator.min;
  const validMax = validator.max === undefined ? true : length <= validator.max;

  if (validMin && validMax) return true;

  if (showError) {
    const issue = !validMax
      ? 'less than ' + (validator.max! + 1)
      : 'more than ' + (validator.min! - 1);
    const type =
      templateAttribute.type === 'assets'
        ? 'assets'
        : templateAttribute.type === 'richContent'
        ? 'rich-contents'
        : 'characters';
    const message = `Invalid attribute "${templateAttribute.displayName}" — must have ${issue} ${type}`;
    toast.show({ error: true, message });
  }
  return false;
};

// TODO this is very basic and probably won't work with more extensive products
export const createBaseVersion = (variant: Variant): ProductVersion => {
  // Convert in to Prescriptive product data model with base product details
  const contextualProductWithBaseProductDetails: ProductVersion = {
    id: variant.productId,
    title: capitalizeEveryFirstLetter(i18next.t('global.base_version')),
    productId: variant.productId,
    contentReference: variant.productId,
    experimentIds: [],
    publishDate: moment().startOf('day').valueOf(),
    type: 'test',
    trafficPercentage: 20,
    thumbnail: '',
    attributes: variant.attributes,
    tenantId: '',
    status: 'published',
    variantOverrides: {},
    updatedAt: Date.now(),
  };
  // Set base version
  return contextualProductWithBaseProductDetails;
};

export const validateAttribute = (
  attribute: TemplateAttribute<AttributeTypes>,
  attributeData: LegacyVersion.PostPersonalizedContent,
  showError = true,
): boolean =>
  checkIsAttributeInputValid(
    attributeData?.standard_text,
    attribute,
    showError,
  );

export const toContentList = async (
  attributes: AttributeValueMap,
  template?: Template,
  variant?: Variant,
): Promise<LegacyVersion.PostPersonalizedSection[]> => {
  const version: Version = {
    attributes: { ...attributes },
    // DUMMY VALUES BELOW AS NOT USED
    id: '',
    title: '',
    experimentIds: [],
    productId: '',
    tenantId: '',
    type: 'test',
    contentReference: '',
    thumbnail: '',
    publishDate: 0,
    status: 'draft',
    variantOverrides: {},
    updatedAt: 0,
  };
  if (variant) {
    template?.attributes.forEach(({ name, customizable }) => {
      // If customizable and not yet in version, add from product
      if (customizable && !version.attributes[name])
        version.attributes[name] = variant.attributes[name];
    });
  }
  const legacyVersion = await migrateVersion.toLegacy(version);
  const contentList = legacyVersion.contentList;
  return contentList.map(
    ({
      sectionName,
      personalisedContent,
    }): LegacyVersion.PostPersonalizedSection => ({
      section_name: sectionName,
      personalised_content: personalisedContent.map(
        (content): LegacyVersion.PostPersonalizedContent => ({
          property_name: content.propertyName,
          standard_text: content.standardText,
          personalised_text: content.personalisedText,
          asset_id: content.assetId,
          alt_text: content.altText,
          asset_title: content.assetTitle,
          type: content.type,
          position: content.position,
        }),
      ),
    }),
  );
};

/** @returns any invalid attributes found */
export const validateContent = (
  content: AttributeValueMap,
  template: Template,
  validateAll = false,
): string[] => {
  const invalid: string[] = [];
  const attributes = { ...content };

  if (validateAll) {
    for (const { name, type } of template.attributes) {
      const fallback = ['html', 'text'].includes(type) ? '' : [];
      attributes[name] = attributes[name] || fallback;
    }
  }

  for (const attribute in attributes) {
    const value = attributes[attribute];
    const constraints = template.attributes.find(
      ({ name }) => name === attribute,
    );
    if (!constraints) invalid.push(attribute);
    else {
      const { validator, type, displayName } = constraints;
      const expected: { [type in AttributeTypes]: string } = {
        text: 'string',
        html: 'string',
        assets: 'object',
        richContent: 'object',
      };
      if (typeof value !== expected[type]) invalid.push(displayName);
      else if (validator) {
        const len =
          type !== 'html' ? value.length : getHTMLLength(value as string);
        if (validator.min && len < validator.min) invalid.push(displayName);
        else if (validator.max && len > validator.max)
          invalid.push(displayName);
      }
    }
  }
  return invalid;
};

/**
 * Checks if the value can be used for that attribute
 * @returns Assertion that value is `string & string[]`, however only applicable for the attribute provided
 */
export const isValidAttributeValue = <T extends AttributeTypes>(
  attribute: TemplateAttribute<T>,
  value: AnyAttributeValue,
): value is string & string[] => {
  /** If we should expect a string */
  const expectedString = ['text', 'html'].includes(attribute.type);
  const isString = typeof value === 'string';
  return expectedString === isString;
};

export class AttributeValueError extends Error {
  constructor(
    public attribute: TemplateAttribute<AttributeTypes>,
    public value: AnyAttributeValue,
  ) {
    super(
      `Value of type ${
        typeof value === 'string' ? 'string' : 'string[]'
      } cannot be assigned to attribute ${attribute.name} which is of type ${
        attribute.type
      }`,
    );
  }
}
