import { ENDPOINTS } from '@constants/endpoints';
import { clientWithToken } from '.';
import {
  IUpdateWorkflowSettingPayload,
  IWorkflow_Temp,
} from '@models/workflow';

export const fetchWorkflowSettings = async () => {
  const url = ENDPOINTS.workflow.base;
  const res = await clientWithToken.get<{
    body: {
      workflowEnabled: boolean;
      activeWorkflow: string;
      workflows: IWorkflow_Temp[];
    };
  }>(url);
  return res.data.body;
};

export const updateWorkflowSettings = async (
  update: IUpdateWorkflowSettingPayload,
) => {
  const url = ENDPOINTS.workflow.base;
  const res = await clientWithToken.put<{
    body: {
      workflowEnabled: boolean;
      activeWorkflow: string;
      workflows: IWorkflow_Temp[];
    };
  }>(url, update);
  return res.data.body;
};
