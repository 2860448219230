import { colors } from '@constants/colors';
import styled from 'styled-components';

export const MainContent = styled.div<{
  sidebarMargin: boolean;
  active: boolean;
}>`
  position: relative;
  background: ${colors.white};
  margin-left: ${(props) =>
    props.sidebarMargin ? (props.active ? '15rem' : '3.7rem') : '0'};

  transition: 0.2s;
  height: 100%;
  display: grid;
  grid-template-rows: max-content;
`;

export const SideBarParent = styled.div<{ active: boolean }>`
  display: grid;
  grid-template-rows: max-content 1fr;
  position: fixed;
  top: 0;
  bottom: 0;
  background-color: ${colors.oxfordBlue};
  width: ${(props) => (props.active ? '15rem' : '3.7rem')};
  transition: 0.2s;
  z-index: 999;
  box-shadow: 0 0 12px ${colors.oxfordBlue};
  height: 100vh;
  grid-row-gap: 8px;
  overflow: hidden;
`;

export const SidebarItem = styled.div<{
  active: boolean;
  show: boolean;
}>`
  display: grid;
  grid-template-columns: 3.123rem 1fr;
  position: relative;
  padding: ${(props) => (props.show ? '12px 8px' : '12px 4px')};
  align-items: center;
  transition: all 0.25s ease-in-out;
  user-select: none;
  &:hover {
    div:nth-child(2) {
      color: ${(props) => (props.active ? colors.emerald1 : colors.white)};
    }
    div:nth-child(1) {
      svg * {
        fill: ${(props) => (props.active ? colors.emerald1 : colors.white)};
      }
      border-color: ${(props) =>
        props.active ? colors.emerald1 : colors.white};
      color: ${(props) => (props.active ? colors.emerald1 : colors.white)};
    }
  }
  div:nth-child(1) {
    text-align: center;
    color: ${(props) => (props.active ? colors.emerald1 : colors.oxfordBlue3)};
    border-color: ${(props) =>
      props.active ? colors.emerald1 : colors.oxfordBlue3};
  }
  img,
  svg {
    width: 1.625rem;
    height: 1.625rem;
  }
  svg * {
    fill: ${(props) => (props.active ? colors.emerald1 : colors.oxfordBlue3)};
  }
  div:nth-child(2) {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    color: ${(props) => (props.active ? colors.emerald1 : colors.oxfordBlue3)};
    display: ${(props) => (props.show ? 'inline' : 'none')};
    font-size: 0.875rem;
  }
`;

export const Toggler = styled.div<{ active: boolean }>`
  display: grid;
  align-items: center;
  height: 1.5rem;
  padding: 0 0.3rem;
  justify-content: center;
  background-repeat: no-repeat;
  img {
    width: 1.25rem;
  }
`;
