import { MutableRefObject, useMemo } from 'react';

/**
 * @description Using this hook you can observe if an element becomes visible
 * @param onVisible Run whenever the component becomes visible
 */

export const useObserver = <T extends Element = Element>(
  onVisible: () => any,
) => {
  let current: T | null = null;

  const observer = new IntersectionObserver((entries) => {
    // Only call if some entries are visible
    if (entries.some((entry) => entry.isIntersecting)) onVisible();
  });

  const get = () => current;
  const set = (value: T | null) => {
    if (value === current) return;
    current = value;
    observer.disconnect();
    if (value) observer.observe(value);
  };

  const createRef = () =>
    Object.defineProperty({}, 'current', {
      get,
      set,
    }) as MutableRefObject<T | null>;

  return useMemo<MutableRefObject<T | null>>(createRef, []);
};
