import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ChainedBackend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import XHRBackEnd from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEn from './translations/translation.json';

import { ENDPOINTS } from '@constants/endpoints';
import { BASE } from '../apis';

// THIS WILL ONLY BE USED FOR LOCAL DEVELOPMENT AND IT'LL BE THE MOST UPDATED FILE
export const resources = {
  en_US: {
    translation: translationEn,
  },
} as const;

i18n
  .use(LanguageDetector)
  .use(ChainedBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en_US',
    debug: false, // TODO there were still some warnings with debug true but no time to fix them
    load: 'languageOnly',
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    resources,
    backend: {
      backends: [LocalStorageBackend, XHRBackEnd],
      backendOptions: [
        {},
        {
          loadPath: `${BASE}${ENDPOINTS.languages.translation}/{{lng}}`,
          crossDomain: true, // required to add it to make it work locally
        },
      ],
    },
    react: {
      wait: true,
      useSuspense: false,
    },
  });

export default i18n;
