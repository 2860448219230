import { PAGE_KEY } from '@models/quickStartGuide';

export const PAGE_KEY_VISIT_MAPPING = {
  [PAGE_KEY.PRODUCT_DASHBOARD]: 'productDashboardVisited',
  [PAGE_KEY.PRODUCT]: 'productVisited',
  [PAGE_KEY.CONTEXT]: 'contextVisited',
  [PAGE_KEY.DIGITAL_ASSETS]: 'digitalAssetsVisited',
  [PAGE_KEY.PERMISSIONS]: 'permissionsVisited',
} as const satisfies { [key in PAGE_KEY]: string };

export const ON_BOARD_STATUS_KEY_MAPPING = {
  ...PAGE_KEY_VISIT_MAPPING,
  newUser: 'newUser',
  guideClosed: 'guideClosed',
} as const;
