/** Takes Cloudinary image url and returns thumbnail url based on height and width. */
export const getCloudinaryUrlWithOurVersion = (url: string): string => {
  if (!url.includes('res.cloudinary.com')) return url;

  const temp = url.split('upload/');
  const assetName = url.substring(url.lastIndexOf('/') + 1);
  return `${temp[0]}upload/${assetName}`;
};

export const getThumbnail = (url: string, width: number): string => {
  if (!url) return url;

  const urlWithOutVersion = getCloudinaryUrlWithOurVersion(url);
  const temp = urlWithOutVersion.split('upload/');
  return temp.length === 2
    ? `${temp[0]}upload/w_${width},c_scale,f_auto,q_auto/${temp[1]}`
    : urlWithOutVersion;
};
