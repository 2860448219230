import { clientWithToken, PAGE_SIZE } from '.';
import { ENDPOINTS } from '@constants/endpoints';
import { IUtm } from '@models/utm';

const UTM_SORT_ORDER = 'DESC';
const UTM_SORT_BY = 'updatedAt';

export const listUtms = async (page: number, searchText = '') => {
  const url = ENDPOINTS.context.utm.utmList;
  const res = await clientWithToken.post<{
    body: { totalElements: number; content: IUtm[]; number: number };
  }>(url, {
    sortBy: UTM_SORT_BY,
    sortOrder: UTM_SORT_ORDER,
    pageNumber: page,
    pageSize: PAGE_SIZE,
    utmName: searchText,
  });
  return res.data.body;
};
