import './RadioButton.scss';

export const RadioButton: React.FC<
  React.InputHTMLAttributes<HTMLInputElement>
> = ({ className, checked, ...props }) => (
  <input
    type="radio"
    className={`RadioButton ${checked ? 'RadioButton__is-checked' : ''} ${
      className || ''
    }`}
    checked={checked}
    {...props}
  />
);
