import { DirectoryProduct } from '@models/product';
import { useObserver } from '../../../../../hooks/useObserver';
import { useEffect, useMemo } from 'react';
import fallbackImage from '@assets/illustrations/57_NoImage.svg';
import { SearchBar } from '@components/SearchBar/SearchBar';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RProduct } from '@redux/slices/product';

import './style.scss';

const AllProductsView = ({
  selectedProduct,
  setSelectedProduct,
}: {
  selectedProduct: DirectoryProduct | undefined;
  setSelectedProduct: (product: DirectoryProduct) => any;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    items: products,
    totalItems,
    searchText,
  } = useSelector((state) => state.products);

  const loadingObject = useMemo(() => ({ loading: true }), []);

  const loadMoreRow = useObserver<HTMLTableRowElement>(() => {
    if (loadingObject.loading) return;
    loadingObject.loading = true;
    dispatch(RProduct.incrementPage());
  });
  if (products?.length === totalItems) loadMoreRow.current = null;
  useEffect(() => {
    loadingObject.loading = products === null;
  }, [products]);

  const pairs = products?.reduce((list, product, index) => {
    if (index % 2 === 0) list.push([product]);
    else list[list.length - 1].push(product);
    return list;
  }, [] as DirectoryProduct[][]);

  return (
    <>
      <SearchBar
        id="search"
        value={searchText}
        placeholder={t('testFlow.step1.search')}
        updateSearch={(search) => dispatch(RProduct.setSearchText(search))}
        enterkeyHandler={() => {
          // Do nothing
        }}
        onClearHandler={() => dispatch(RProduct.setSearchText(''))}
        count={totalItems}
        debounce={true}
      />
      <table className="product-list">
        <tbody>
          {pairs ? (
            <>
              {pairs.map((pair, i) => {
                const isLastLocally = i === pairs.length - 1;
                const isLast = i === totalItems - 1;

                const ref = isLastLocally && !isLast ? loadMoreRow : undefined;

                const pairElements = pair.map((product, j) => (
                  <td className="product-card" key={i * 3 + j + 1}>
                    <label htmlFor={'product-' + (i * 2 + j)}>
                      <img
                        src={product.thumbnail || fallbackImage}
                        alt={product.title + ' Thumbnail'}
                      />
                      {product.title}
                      <input
                        type="radio"
                        name="selected-product"
                        id={'product-' + (i * 2 + j)}
                        checked={
                          selectedProduct?.productId === product.productId
                        }
                        onChange={() => setSelectedProduct(product)}
                      />
                    </label>
                  </td>
                ));

                return (
                  <tr key={i * 3} ref={ref}>
                    {pairElements}
                  </tr>
                );
              })}
            </>
          ) : (
            Array(5)
              .fill(0)
              .map((_, i) => (
                <tr key={'loading' + i * 3}>
                  {[1, 2].map((j) => (
                    <td className="product-card" key={'loading' + (i * 3 + j)}>
                      <div className="loading"></div>
                    </td>
                  ))}
                </tr>
              ))
          )}
        </tbody>
      </table>
    </>
  );
};

export default AllProductsView;
