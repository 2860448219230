import { listExperiences } from '@apis/experiences';
import { MultiToggle } from '@components/MultiToggle/MultiToggle';
import { Experience } from '@models/experience';
import {
  capitalizeEveryFirstLetter,
  capitalizeFirstLetter,
} from '@utils/textTransform';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ExperienceCard from './ExperienceCard';
import ExperienceCardLoading from './ExperienceCard/loading';

import './style.scss';
import { PrimaryButton } from '@components/PrimaryButton/PrimaryButton';
import { Link } from 'react-router-dom';
import { colorsByExperienceLevel } from '@components/ExperienceTargets/ExperienceTargets';
import { useDispatch, useSelector } from 'react-redux';
import { RTenant } from '@redux/slices/tenant';

const levels = ['basic', 'advanced', 'expert'] as const;

const SelectExperienceView = ({
  setExperience,
}: {
  setExperience: (experience: Experience) => any;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { onboarded } = useSelector((state) => state.tenant);

  const levelTextsMap = Object.fromEntries(
    levels.map((level) => [
      capitalizeEveryFirstLetter(t('testFlow.step0.levels.' + level)),
      level,
    ]),
  );
  const levelTexts = Object.keys(levelTextsMap);

  const [allExperiences, setAllExperiences] =
    useState<{ [key in (typeof levels)[number]]: Experience[] }>();
  const [selectedExperience, setSelectedExperience] = useState<Experience>();
  const [selectedLevelText, setSelectedLevelText] = useState<
    (typeof levelTexts)[number]
  >(levelTexts[0]);
  const selectedLevel = levelTextsMap[selectedLevelText];
  const experiences = allExperiences ? allExperiences[selectedLevel] : null;

  useEffect(() => {
    listExperiences().then((experiences) => {
      const experiencesByLevel = Object.fromEntries(
        levels.map((level): [(typeof levels)[number], Experience[]] => {
          const experienceWithLevel = experiences.filter(
            (experience) => experience.level === level,
          );
          return [level, experienceWithLevel];
        }),
      ) as { [key in (typeof levels)[number]]: Experience[] };
      setAllExperiences(experiencesByLevel);
    });
  }, []);

  return (
    <div className="select-experience-view">
      <MultiToggle
        options={levelTexts}
        selected={selectedLevelText}
        selectedColor={colorsByExperienceLevel[selectedLevel].primary}
        setSelected={(selected) => {
          setSelectedLevelText(selected);
          if (selected !== selectedLevelText) setSelectedExperience(void 0);
        }}
      />
      <div className="experience-list">
        {experiences
          ? experiences.map((experience) => (
              <ExperienceCard
                experience={experience}
                experienceLevel={selectedLevel}
                key={experience.id}
                isSelected={experience.id === selectedExperience?.id}
                setSelected={setSelectedExperience}
              />
            ))
          : Array(5)
              .fill(0)
              .map((_, i) => <ExperienceCardLoading key={i} />)}
      </div>

      <div>
        <PrimaryButton
          className="next-button"
          disabled={!selectedExperience}
          onClick={() =>
            selectedExperience ? setExperience(selectedExperience) : 0
          }
        >
          {capitalizeFirstLetter(t('global.next'))}
        </PrimaryButton>
      </div>
      {onboarded === false && (
        <Link
          className="skip"
          to={'/'}
          onClick={() => dispatch(RTenant.setOnboarded(true))}
        >
          {capitalizeFirstLetter(t('strategyBoard.skip_and_go_to_dashboard'))}
        </Link>
      )}
    </div>
  );
};

export default SelectExperienceView;
