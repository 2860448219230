import { ENDPOINTS } from '@constants/endpoints';
import { clientWithToken } from '.';
import { IMetadataHighlight, IMetadataSegment } from '@models/metadata';

const SIZE = 50;

export const getMetadataHighlights = async () => {
  const { highlights, queryParams } = ENDPOINTS.metadata;
  const url = `${highlights}?${queryParams.page}=0&${queryParams.size}=${SIZE}`;
  const response = await clientWithToken.get<{
    body?: { content: IMetadataHighlight[] | null };
  }>(url);
  return response.data.body?.content || null;
};

export const getMetadataSegments = async () => {
  const { segments, queryParams } = ENDPOINTS.metadata;
  const url = `${segments}?${queryParams.page}=0&${queryParams.size}=${SIZE}`;
  const response = await clientWithToken.get<{
    body?: { content: IMetadataSegment[] | null };
  }>(url);
  return response.data.body?.content || null;
};
