import { Fragment } from 'react';

/** Component to render a component n times */
export const LoadingGroup: React.FC<{
  /** number of times the given component should be rendered */
  numberOfCards?: number;
  /** component to be rendered */
  component: React.ReactNode;
}> = ({ numberOfCards = 12, component }) => (
  <>
    {Array.from({ length: numberOfCards }).map((_el, index: number) => (
      <Fragment key={index}>{component}</Fragment>
    ))}
  </>
);
