import ReactDOM from 'react-dom';

import { CloseIcon } from '../Icons/CloseIcon';

import './ModalLayout.scss';

export const ModalLayout: React.FC<{
  id?: string;
  showModal: boolean;
  setShowModal: any;
  children?: React.ReactNode;
  defaultClose?: boolean;
  style?: any;
  className?: string;
  onClose?: Function;
}> = ({
  id,
  showModal,
  setShowModal,
  children,
  defaultClose,
  style,
  className,
  onClose,
}) => {
  if (!showModal) return null;

  const divPortal = document.getElementById('full-screen-model');

  return (
    divPortal &&
    ReactDOM.createPortal(
      <div className="modal__backdrop" id={id}>
        <div className={`modal__body ${className || ''}`} style={style}>
          {defaultClose && (
            <button
              className="modal__close-button"
              onClick={() => (onClose ? onClose() : setShowModal(!showModal))}
              id={`${id}-close-button`}
            >
              <CloseIcon />
            </button>
          )}
          {children}
        </div>
      </div>,
      divPortal,
    )
  );
};
