import { useEffect, useState, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ALL_IMAGES } from '@utils/files';
import { characterLimits } from '../../../../heuristic.config';
import { capitalizeFirstLetter } from '@utils/textTransform';
import { DigitalAssetBrief } from '@models/digital-asset';
import { Attribute, IRichContentSection } from '@models/rich-content';

import { DigitalAssetsModal } from '@components/DigitalAssetsModal/DigitalAssetsModal';
import useLegacyModal from '@components/ModalLayout/useModal';
import { PrimaryButton } from '@components/PrimaryButton/PrimaryButton';

import SectionHeader from '../RichContentSectionHeader';
import { SectionFieldsFactory } from '../../[id]/template/SectionFieldsFactory';

import VisualAssetsIcon from '@assets/icons/pictogram-visual-assets.svg';

import './style.scss';

const RichContentImageGrid: React.FC<IRichContentSection> = ({
  moveSection,
  index,
  length,
  handleChange,
  attributes,
  permissions,
}) => {
  const { t } = useTranslation();
  const damModalId = 'crop-modal';
  const { openModal, closeModal, showModalId } = useLegacyModal();
  const imageGridPostions = [1, 2, 3, 4, 5, 6];
  const { authorAttributionLimit } = characterLimits.richContent;

  const openDamAndSetPosition = (position: number) => {
    setSelectedPostion(position);
    openModal(damModalId);
  };
  const onImageSelected = (files: DeepReadonly<DigitalAssetBrief[]>) => {
    handleChange(files[0].url, `img${selectedPostion}`, index, true, [
      files[0].id,
    ]);
  };

  const [data, setData] = useState(
    imageGridPostions.map(() => ({
      image: '',
    })),
  );

  const sectionFieldsFactory = (
    id: string,
    key: string,
    attribute: Attribute,
    characterLimits = 0,
  ) => (
    <SectionFieldsFactory
      id={id}
      key={key}
      attributeKey={key}
      sectionOrder={index}
      attribute={attribute}
      permissions={permissions}
      handleChange={handleChange}
      characterLimits={characterLimits}
    />
  );

  const [selectedPostion, setSelectedPostion] = useState(0);

  useEffect(() => {
    const data = imageGridPostions.map((position) => ({
      image: attributes[`img${position}`]?.standardText,
    }));
    setData([...data]);
  }, [attributes, attributes[`img${selectedPostion}`]?.standardText]);

  return (
    <>
      <div className="RichContentImageGrid">
        <SectionHeader
          title={capitalizeFirstLetter(t('rich_content.reviews'))}
          moveSection={moveSection}
          showDropdown={permissions.AUTHOR_CONTENT}
          index={index}
          length={length}
        />
        <div className="RichContentImageGrid">
          <div className="RichContentImageGrid-heading">
            {sectionFieldsFactory(
              `imageGridHeading${index}`,
              'heading',
              attributes['heading'],
              authorAttributionLimit,
            )}
          </div>
          <div className="RichContentImageGrid-grid">
            {data?.map(({ image }, index) => (
              <div className="image-container" key={index}>
                <img
                  className={`${
                    !permissions.VISUAL_ASSETS ? 'template-read-only-mode' : ''
                  } ${image ? 'stretch' : 'small'}`}
                  src={image ? image : VisualAssetsIcon}
                  alt="Add another"
                />
                {permissions?.VISUAL_ASSETS && (
                  <PrimaryButton
                    className={image ? 'changeImage' : ''}
                    variant={image ? 'gray' : 'green'}
                    size="small"
                    onClick={() => openDamAndSetPosition(index + 1)}
                  >
                    {capitalizeFirstLetter(
                      t(image ? 'taskMaster.change_asset' : 'global.add_image'),
                    )}
                  </PrimaryButton>
                )}
              </div>
            ))}
          </div>
        </div>
        {damModalId === showModalId && (
          <DigitalAssetsModal
            showModal={damModalId === showModalId}
            setShowModal={closeModal}
            onClose={closeModal}
            allowedFiles={ALL_IMAGES}
            setFinalFiles={(files) => onImageSelected(files)}
            maxFiles={1}
          />
        )}
      </div>
    </>
  );
};
export default memo(RichContentImageGrid);
