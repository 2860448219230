import { Experience } from '@models/experience';
import {
  ExperienceTargets,
  colorsByExperienceLevel,
} from '@components/ExperienceTargets/ExperienceTargets';

import './style.scss';

const ExperienceCard = ({
  experience,
  isSelected,
  experienceLevel,
  setSelected,
}: {
  experience: Experience;
  isSelected: boolean;
  experienceLevel: Experience['level'];
  setSelected: (experience: Experience) => any;
}) => {
  const colorSet = colorsByExperienceLevel[experienceLevel];

  const cssDefinitions = {
    '--experience-card-primary-color': colorSet.primary,
    '--experience-card-secondary-color': colorSet.secondary,
    '--experience-card-background-color': colorSet.background,
  } as React.CSSProperties;

  return (
    <label
      className={'experience-card' + (isSelected ? ' active' : '')}
      htmlFor={'experience-card-' + experience.id}
      style={cssDefinitions}
    >
      <div className="experience-card-inner">
        <ExperienceTargets experience={experience} />
        <h4>{experience.title}</h4>

        <div className="experience-card-hover">{experience.description}</div>
        <input
          id={'experience-card-' + experience.id}
          className="select"
          name="experience-card"
          type="radio"
          checked={isSelected}
          onChange={() => setSelected(experience)}
        />
      </div>
    </label>
  );
};

export default ExperienceCard;
