export const ROLES_PERMISSIONS = {
  READ_TENANT_SETTINGS: 'READ_TENANT_SETTINGS',
  CREATE_TENANT_SETTINGS: 'CREATE_TENANT_SETTINGS',
  UPDATE_TENANT_SETTINGS: 'UPDATE_TENANT_SETTINGS',
  DELETE_TENANT_SETTINGS: 'DELETE_TENANT_SETTINGS',
  READ_CONTEXT: 'READ_CONTEXT',
  CREATE_CONTEXT: 'CREATE_CONTEXT',
  UPDATE_CONTEXT: 'UPDATE_CONTEXT',
  DELETE_CONTEXT: 'DELETE_CONTEXT',
  READ_DIGITAL_ASSETS: 'READ_DIGITAL_ASSETS',
  CREATE_DIGITAL_ASSETS: 'CREATE_DIGITAL_ASSETS',
  UPDATE_DIGITAL_ASSETS: 'UPDATE_DIGITAL_ASSETS',
  DELETE_DIGITAL_ASSETS: 'DELETE_DIGITAL_ASSETS',
  READ_BUNDLES: 'READ_BUNDLES',
  CREATE_BUNDLES: 'CREATE_BUNDLES',
  UPDATE_BUNDLES: 'UPDATE_BUNDLES',
  DELETE_BUNDLES: 'DELETE_BUNDLES',
  READ_RICH_CONTENT: 'READ_RICH_CONTENT',
  CREATE_RICH_CONTENT: 'CREATE_RICH_CONTENT',
  UPDATE_RICH_CONTENT: 'UPDATE_RICH_CONTENT',
  DELETE_RICH_CONTENT: 'DELETE_RICH_CONTENT',
  READ_PRODUCTS: 'READ_PRODUCTS',
  CREATE_PRODUCTS: 'CREATE_PRODUCTS',
  UPDATE_PRODUCTS: 'UPDATE_PRODUCTS',
  DELETE_PRODUCTS: 'DELETE_PRODUCTS',
  READ_PERSONALIZED_PRODUCTS: 'READ_PERSONALIZED_PRODUCTS',
  CREATE_PERSONALIZED_PRODUCTS: 'CREATE_PERSONALIZED_PRODUCTS',
  DELETE_PERSONALIZED_PRODUCTS: 'DELETE_PERSONALIZED_PRODUCTS',
  UPDATE_PERSONALIZED_PRODUCTS: 'UPDATE_PERSONALIZED_PRODUCTS',
  UPDATE_PRODUCT_ASSETS: 'UPDATE_PRODUCT_ASSETS',
  UPDATE_PRODUCT_INFORMATION: 'UPDATE_PRODUCT_INFORMATION',
  UPDATE_CREATIVE_BRIEF: 'UPDATE_CREATIVE_BRIEF',
} as const;
