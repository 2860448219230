import { HeaderFooterModalLayout } from '@components/Modals/HeaderFooterModalLayout/HeaderFooterModalLayout';
import { SearchBar } from '@components/SearchBar/SearchBar';
import {
  capitalizeEveryFirstLetter,
  capitalizeFirstLetter,
} from '@utils/textTransform';
import { useTranslation } from 'react-i18next';
import { ReactComponent as NoRichContentIcon } from '@assets/icons/NoRichContent.svg';
import { IRichContent } from '@models/rich-content';
import { ListingCard } from '@components/ListingCard/ListingCard';
import { useEffect, useState } from 'react';
import { UpdatedInfiniteScrolling } from '@components/InfiniteScrolling/UpdatedInfiniteScrolling';
import { getRichContentType } from '@utils/richContent';
import defaultImage from '@assets/illustrations/DefaultRichContent.svg';
import { PrimaryButton } from '@components/PrimaryButton/PrimaryButton';
import { fetchRichContent } from '@apis/richContent';
import { toast } from '@components/ToastNotification/ToastManager';

import './RichContentSelectModal.scss';

export const RichContentSelectModal: React.FC<{
  isOpen: boolean;
  closeModal: () => any;
  productId: string;
  onSelectionEnd: (selected: string[]) => any;
}> = ({ isOpen, closeModal, productId, onSelectionEnd }) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState('');
  const [selected, setSelected] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [richContentList, setRichContents] = useState<IRichContent[]>([]);

  useEffect(() => {
    setRichContents([]);
    setPage(1);
  }, [query]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const result = await fetchRichContent({
        pageNumber: page,
        pageSize: 20,
        searchText: query,
        sortBy: 'UPDATED_AT',
        sortOrder: 'DESC',
        workFlowStatus: ['LIVE'],
        productId,
      });
      if (result instanceof Error) {
        setRichContents([]);
        setTotalCount(0);
        toast.show({ message: result.message, error: true });
      } else {
        setRichContents(result.items);
        setTotalCount(result.totalItems);
      }
      setLoading(false);
    })();
  }, [page]);

  const onClose = () => {
    setSelected([]);
    closeModal();
  };

  return (
    <HeaderFooterModalLayout
      onClose={onClose}
      showModal={isOpen}
      setShowModal={onClose}
      body={
        <>
          <div className="CVRichContent__modal--main searchBar">
            <SearchBar
              id="rich-content-pv-search-bar"
              value={query}
              placeholder={`${capitalizeFirstLetter(
                t('rich_content.search_placeholder'),
              )}...`}
              enterkeyHandler={() => {
                // Do nothing
              }}
              updateSearch={setQuery}
              onClearHandler={() => setQuery('')}
              count={
                richContentList.length /** // TODO should be total but not available w/ current API */
              }
              title={capitalizeFirstLetter(t('global.results'))}
            />
          </div>
          <div className="CVRichContent__modal--main" id="richContentList">
            {richContentList.length === 0 && !loading && (
              <div className="CVRichContent__modal--emptyList">
                <NoRichContentIcon />
                <h3>
                  {capitalizeEveryFirstLetter(
                    t('taskMaster.no_more_rich_content'),
                  )}
                </h3>
              </div>
            )}
            <UpdatedInfiniteScrolling
              dataLength={richContentList.length ?? 0}
              hasMore={richContentList.length < totalCount}
              isLoading={loading}
              loader={<div className="rich-content-items-loading"></div>}
              scrollableTarget={'richContentList'}
              className="CVRichContent__modal--cardList"
              next={() => setPage((val) => val + 1)}
              endMessage={t('taskMaster.no_more_rich_content')}
            >
              {richContentList.map((richContent: IRichContent) => (
                <ListingCard
                  module="richContent"
                  id={`modal-rich-content-card-${richContent.id}`}
                  key={richContent.id}
                  status={richContent.workFlowStatus}
                  title={richContent.title}
                  assetUrl={
                    richContent.contentList[0]?.attributes?.image
                      ?.standardText ?? defaultImage
                  }
                  type={getRichContentType(richContent.usage)}
                  onTitleClick={() => {
                    //TODO openViewModal(richContent);
                  }}
                  isSelectable
                  onChange={() => {
                    setSelected((selected) => {
                      const index = selected.indexOf(richContent.id);
                      if (index === -1) selected.push(richContent.id);
                      else selected.splice(index, 1);
                      return [...selected];
                    });
                  }}
                  isSelected={selected.includes(richContent.id)}
                />
              ))}
            </UpdatedInfiniteScrolling>
          </div>
        </>
      }
      header={
        <header className="CVRichContent__modal--header">
          <h2 className="CVRichContent__modal--header-title">
            {capitalizeFirstLetter(t('rich_content.title'))}
          </h2>
        </header>
      }
      footer={
        <footer className="CVRichContent__modal--footer">
          <PrimaryButton
            disabled={!selected.length}
            variant="green"
            size="small"
            onClick={() => {
              onSelectionEnd(selected);
              onClose();
            }}
          >
            {capitalizeFirstLetter(t('global.done'))}
          </PrimaryButton>
        </footer>
      }
    />
  );
};
