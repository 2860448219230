import { useState, useEffect, memo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  capitalizeEveryFirstLetter,
  capitalizeFirstLetter,
} from '@utils/textTransform';
import { Attribute, IRichContentSection } from '@models/rich-content';
import { characterLimits } from '../../../../heuristic.config';

import { ModalLayout } from '@components/ModalLayout/ModalLayout';
import { PrimaryButton } from '@components/PrimaryButton/PrimaryButton';
import useLegacyModal from '@components/ModalLayout/useModal';
import { TextareaWithoutBorder } from '@components/TextareaWithoutBorder/TextareaWithoutBorder';

import SectionHeader from '../RichContentSectionHeader';
import { SectionFieldsFactory } from '../../[id]/template/SectionFieldsFactory';

import { ReactComponent as EditIcon } from '@assets/icons/edit-pen-white.svg';
import { ReactComponent as DeleteIcon } from '@assets/icons/delete.svg';

import './style.scss';
import { Card } from '@components/Card/Card';

interface IRichContentImageText extends IRichContentSection {
  alignment?: string;
}

const RichContentImageText: React.FC<IRichContentImageText> = ({
  alignment,
  index,
  length,
  moveSection,
  permissions,
  attributes,
  handleChange,
}) => {
  const { t } = useTranslation();
  const showModal = 'add-call-to-action';
  const { openModal, closeModal, showModalId } = useLegacyModal();
  const { headingLimit, descriptionLimit, ctaTextLimit } =
    characterLimits.richContent;

  const { standardText: callActionBtnText } = attributes[
    'callActionBtnText'
  ] || { standardText: '' };
  const { standardText: callActionBtnURL } = attributes['callActionBtnURL'] || {
    standardText: '',
  };
  const [buttonData, setButtonData] = useState({
    text: '',
    url: '',
  });

  const onCloseModal = () => {
    setCallToActionData();
    closeModal();
  };

  const onChange = (key: string, value: string) => {
    setButtonData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const updateButtonData = () => {
    handleChange(buttonData.text, 'callActionBtnText', index);
    handleChange(buttonData.url, 'callActionBtnURL', index);
    closeModal();
  };

  const handleEdit = () => {
    setCallToActionData();
    openModal(showModal);
  };

  const handleDelete = () => {
    setButtonData({
      text: '',
      url: '',
    });
    handleChange('', 'callActionBtnText', index);
    handleChange('', 'callActionBtnURL', index);
  };

  const setCallToActionData = () => {
    setButtonData({
      ...buttonData,
      text: callActionBtnText,
      url: callActionBtnURL,
    });
  };

  useEffect(() => {
    callActionBtnText && callActionBtnURL && setCallToActionData();
  }, [callActionBtnText, callActionBtnURL]);

  const sectionFieldsFactory = (
    id: string,
    key: string,
    attribute: Attribute,
    characterLimits = 0,
  ) => (
    <SectionFieldsFactory
      id={id}
      key={key}
      attributeKey={key}
      sectionOrder={index}
      attribute={attribute}
      permissions={permissions}
      handleChange={handleChange}
      characterLimits={characterLimits}
    />
  );

  return (
    <Card className="px-4 py-2 mt-1_5">
      <div
        className={`RichContentImageUploader  ${
          !permissions.AUTHOR_CONTENT && !permissions.VISUAL_ASSETS
            ? 'RichContentTemplate__readOnly'
            : ''
        }`}
      >
        <SectionHeader
          title={capitalizeEveryFirstLetter(
            alignment
              ? t('rich_content.text_and_image')
              : t('rich_content.image_and_text'),
          )}
          moveSection={moveSection}
          index={index}
          length={length}
          showDropdown={permissions.AUTHOR_CONTENT}
        />
        <div
          className={`RichContentImageUploader__textDetails ${
            alignment ? '' : 'reverse'
          }`}
        >
          <div className="image-container">
            {attributes['image'].standardText ? (
              <div className="mainImage">
                <img
                  src={attributes['image'].standardText}
                  alt="Section Main"
                />
                {sectionFieldsFactory(
                  `imageTextImage${index}`,
                  'image',
                  attributes['image'],
                )}
              </div>
            ) : (
              sectionFieldsFactory(
                `imageTextPlaceholder${index}`,
                'image',
                attributes['image'],
              )
            )}
          </div>
          <div className="RichContentImageUploader__textDetails--container">
            <div className="heading">
              {sectionFieldsFactory(
                `imageTextHeading${index}`,
                'heading',
                attributes['heading'],
                headingLimit,
              )}
            </div>
            <div className="description">
              {sectionFieldsFactory(
                `imageTextDescription${index}`,
                'description',
                attributes['description'],
                descriptionLimit,
              )}
            </div>
            {permissions?.AUTHOR_CONTENT &&
            !buttonData.text &&
            !buttonData.url ? (
              <p onClick={() => openModal(showModal)}>
                {capitalizeFirstLetter(t('rich_content.cta_button'))}
              </p>
            ) : (
              <div className="RichContentImageUploader__ctaUploadContainer">
                <div className="ctaContainer">
                  <p className="ctaHeading">
                    {capitalizeFirstLetter(
                      t('rich_content.call_action_button'),
                    )}
                  </p>
                  <div className="actionIcon">
                    {permissions?.AUTHOR_CONTENT && (
                      <EditIcon onClick={handleEdit} />
                    )}
                    {permissions?.AUTHOR_CONTENT && (
                      <DeleteIcon onClick={handleDelete} />
                    )}
                  </div>
                </div>
                <div className="buttonDetails">
                  <div className="ctaContainer">
                    <span className="value">
                      {`${capitalizeFirstLetter(
                        t('rich_content.button_text'),
                      )}:`}
                    </span>
                    <p className="label">{buttonData.text}</p>
                  </div>
                  <div className="ctaContainer">
                    <span className="value">
                      {`${capitalizeFirstLetter(t('global.url'))}:`}
                    </span>
                    <a
                      target="_blank"
                      rel="noreferrer noopener"
                      className="label"
                      href={buttonData.url}
                    >
                      {buttonData.url}
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <ModalLayout
        showModal={showModal === showModalId}
        setShowModal={onCloseModal}
        defaultClose
        className="RichContentImageUploader__modal"
      >
        <>
          <h5 className="heading">
            {capitalizeFirstLetter(t('rich_content.call_to_action_heading'))}
          </h5>
          <TextareaWithoutBorder
            className="ctaInput"
            label={capitalizeFirstLetter(t('rich_content.button_text'))}
            variant="secondary"
            value={buttonData.text}
            limit={ctaTextLimit}
            onChange={(value: string) => {
              if (permissions.AUTHOR_CONTENT) onChange('text', value);
            }}
          />
          <TextareaWithoutBorder
            className="ctaInput"
            label={t('global.url')}
            value={buttonData.url}
            variant="secondary"
            onChange={(value: string) => {
              if (permissions.AUTHOR_CONTENT) onChange('url', value);
            }}
          />
          <div className="addButton">
            <PrimaryButton size="small" onClick={updateButtonData}>
              {capitalizeFirstLetter(t('global.add'))}
            </PrimaryButton>
          </div>
        </>
      </ModalLayout>
    </Card>
  );
};
export default memo(RichContentImageText);
