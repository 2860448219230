import { clientWithToken, PAGE_SIZE } from '.';
import { ENDPOINTS } from '@constants/endpoints';
import { ensureError, isError } from '@utils/ImproperError';
import { DirectoryProduct, LegacyProduct } from '@models/product';
import {
  ListConfiguration,
  ListablePayload,
  ListableState,
} from '@models/filter';

export namespace ProductApi {
  export namespace List {
    export type State = ListableState<
      DirectoryProduct,
      typeof productListConfig
    >;
    export type Query = ListablePayload<typeof productListConfig>;
  }
}

export const productListConfig = {
  sort: [
    { sortBy: 'relevanceScore', sortOrder: 'desc' },
    { sortBy: 'title', sortOrder: 'asc' },
    { sortBy: 'title', sortOrder: 'desc' },
    { sortBy: 'price', sortOrder: 'asc' },
    { sortBy: 'price', sortOrder: 'desc' },
    { sortBy: 'updatedAt', sortOrder: 'desc' },
    { sortBy: 'updatedAt', sortOrder: 'asc' },
  ],
  filters: [
    {
      name: 'optimizationStatus',
      multiple: false,
      hidden: false,
      options: ['all', 'live', 'in_progress', 'not_started'],
    },
    {
      name: 'status',
      multiple: false,
      hidden: true,
      options: [
        'all',
        'active',
        'exists',
        'onboarded',
        'non-onboarded',
        'draft',
        'archived',
        'deleted',
      ],
    },
  ],
} as const satisfies ListConfiguration;

export const getProduct = async (
  productId: string,
): Promise<DirectoryProduct | Error> => {
  const response = await clientWithToken
    .get<DirectoryProduct>(`${ENDPOINTS.products.directory}/${productId}`)
    .catch(ensureError);
  return isError(response) ? response : response.data;
};

export const getSelectedProducts = async (
  productIds: Readonly<string[]>,
): Promise<LegacyProduct[] | Error> => {
  const { product, queryParams } = ENDPOINTS.products;
  const response = await clientWithToken
    .get<{
      body: { items: { product: LegacyProduct }[] };
    }>(`${product}?${queryParams.productIds}=${productIds.join(',')}`)
    .catch(ensureError);

  return isError(response)
    ? response
    : response.data.body.items.map((item) => item.product);
};

export const listProducts = async ({
  page,
  searchText,
  sortOrder,
  sortBy,
  filters,
}: ProductApi.List.Query): Promise<DirectoryProduct[] | Error> => {
  const url = ENDPOINTS.products.directory;
  const query = new URLSearchParams({
    page: page + '',
    size: PAGE_SIZE + '',
  });
  if (searchText) query.append('searchText', searchText);
  query.append('sortOrder', sortOrder);
  query.append('sortBy', sortBy);
  for (const filter in filters) {
    const value = filters[filter as keyof typeof filters];
    if (value) query.append(filter, value);
  }

  const response = await clientWithToken
    .get<DirectoryProduct[]>(`${url}?${query}`)
    .catch(ensureError);
  return isError(response) ? response : response.data;
};

export const countProducts = async ({
  searchText,
  filters,
}: Pick<ProductApi.List.Query, 'searchText' | 'filters'>): Promise<
  number | Error
> => {
  const url = ENDPOINTS.products.directoryCount;
  const query = new URLSearchParams({
    page: '0',
    size: PAGE_SIZE + '',
  });
  if (searchText) query.append('searchText', searchText);
  for (const filter in filters) {
    const value = filters[filter as keyof typeof filters];
    if (value) query.append(filter, value);
  }

  const result = await clientWithToken
    .get<{ count: number }>(`${url}?${query}`)
    .catch(ensureError);
  return isError(result) ? result : result.data.count;
};

export const getFullProduct = async (
  productId: string,
): Promise<LegacyProduct | Error> => {
  const response = await clientWithToken
    .get<{ body: { product: LegacyProduct } }>(
      `${ENDPOINTS.products.product}/${productId}`,
    )
    .catch(ensureError);
  return isError(response) ? response : response.data.body.product;
};
