import './VideoPlayer.scss';

export const VideoPlayer: React.FC<{
  className?: string;
  transcriptUrl?: string;
  url: string;
}> = ({ className, transcriptUrl, url }) => (
  <div className={`VideoPlayer ${className || ''}`}>
    {/* Have to add anonymous in crossOrigin to bypass CORS */}
    <video
      className="VideoPlayer-video"
      controls
      crossOrigin="anonymous"
      src={url}
    >
      {!!transcriptUrl && (
        <track
          src={transcriptUrl}
          kind="subtitles"
          srcLang="en"
          label="English"
          default
        />
      )}
    </video>
  </div>
);
