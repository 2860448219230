import { Loading } from '@components/Loading/Loading';
import { RCurrentUser } from '@redux/slices/currentUser';
import { RKeyCloak } from '@redux/slices/keyCloak';
import { RQuickStartGuide } from '@redux/slices/quickStartGuide';
import { RTenant } from '@redux/slices/tenant';
import * as Sentry from '@sentry/react';
import { capitalizeEveryFirstLetter } from '@utils/textTransform';
import Keycloak from 'keycloak-js';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setUpForbiddenInterceptor } from './apis';
import { ApplicationRouter } from './pages/ApplicationRouter';

import './App.scss';

const App: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const { keyCloak } = useSelector((s) => s.keyCloak);
  const { onboarded } = useSelector((state) => state.tenant);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Set up the forbidden interceptor using the `history` object
  useEffect(() => setUpForbiddenInterceptor(history), [history]);

  // Log into Keycloak based on the realm from the URL
  useLayoutEffect(() => {
    const host = window.location.hostname;
    const realm =
      host === 'localhost'
        ? process.env.REACT_APP_KC_REALM
        : `${host.split('.')[0]}-tenant`;

    if (!realm) throw new Error('No realm to authenticate with found!');

    const keycloak = Keycloak({
      clientId: 'prodport',
      realm,
      url: process.env.REACT_APP_KC_SERVER_URL,
    });

    keycloak
      .init({ onLoad: 'login-required', checkLoginIframe: false })
      .then((authenticated) => {
        dispatch(RKeyCloak.set(keycloak));
        if (authenticated) {
          dispatch(RKeyCloak.setToken(keycloak.token!));
          dispatch(RCurrentUser.fetchUserOnBoardStatusSaga());
          dispatch(RQuickStartGuide.fetchGuideStatusSaga());
          dispatch(RCurrentUser.getUserSaga());
          dispatch(RTenant.fetchTenantLogoSaga());
          dispatch(RTenant.fetchTenantCurrencyAndOnboardDateSaga());
          dispatch(RTenant.fetchGalleryStatus());
          dispatch(RTenant.checkOnboardingComplete());
          setIsAuthenticated(authenticated);
        }
      });

    keycloak.onTokenExpired = () => {
      keycloak
        .updateToken(50)
        .then((refreshed) =>
          console.log((refreshed ? '' : 'not ') + 'refreshed ' + new Date()),
        )
        .catch(() => console.error('Failed to refresh token ' + new Date()));
    };
  }, [dispatch]);

  if (!keyCloak) return <Loading />;
  else if (!isAuthenticated)
    return (
      <h1>{capitalizeEveryFirstLetter(t('global.unable_to_authenticate'))}</h1>
    );
  else return <ApplicationRouter showOnboardingFlow={onboarded === false} />;
};

export default Sentry.withProfiler(App);
