/**
 * @author Ritam Bandyopadhyay <ritam.bandyopadhyay@corevaluetech.com>
 *
 * @param file image file to be compressed
 * @param height height of image - default to 300
 * @param width width of image - default to 300
 * @returns compressed image file
 */
export const compressFile = (
  file: File,
  height = 300,
  width = 300,
): Promise<File> => {
  const name = file.name;
  const reader = new FileReader();
  const image = new Image();
  const canvas = document.createElement('canvas');
  const dataURItoBlob = (dataURI: string) => {
    const bytes =
      dataURI.split(',')[0].indexOf('base64') >= 0
        ? atob(dataURI.split(',')[1])
        : unescape(dataURI.split(',')[1]);
    const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const max = bytes.length;
    const ia = new Uint8Array(max);
    for (let i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
    return new Blob([ia], { type: mime });
  };
  const resize = () => {
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas?.getContext('2d');
    ctx?.drawImage(image, 0, 0, width, height);
    const dataUrl = canvas.toDataURL('image/png');

    return new File([dataURItoBlob(dataUrl)], name);
  };

  return new Promise((resolve, reject) => {
    if (!file.type.match(/image.*/)) {
      reject(new Error('Not an image'));
      return;
    }

    reader.onload = (readerEvent: any) => {
      image.onload = () => resolve(resize());
      image.src = readerEvent.target.result;
    };
    reader.readAsDataURL(file);
  });
};
