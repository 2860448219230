import { colors } from '@constants/colors';
import IIcon from '@models/icon';

import './Icon.scss';

export const UrlIcon = ({
  fill = colors.oxfordBlue1,
  height = '24px',
  width = '24px',
  ...props
}: IIcon) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.8 4.13333H3.2C2.59249 4.13333 2.1 4.64074 2.1 5.26667V17.7333C2.1 18.3593 2.59249 18.8667 3.2 18.8667H20.8C21.4075 18.8667 21.9 18.3593 21.9 17.7333V5.26667C21.9 4.64074 21.4075 4.13333 20.8 4.13333ZM3.2 3C1.98497 3 1 4.01482 1 5.26667V17.7333C1 18.9852 1.98497 20 3.2 20H20.8C22.015 20 23 18.9852 23 17.7333V5.26667C23 4.01482 22.015 3 20.8 3H3.2Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 8H2V7H22V8Z"
      fill={fill}
    />
    <path
      d="M4.58554 12.8294C4.58554 12.5029 4.80875 12.3147 5.12255 12.3147C5.42988 12.3147 5.61104 12.4926 5.61104 12.7971V14.1529H6.19658V12.7147C6.19658 12.1691 5.8569 11.8647 5.34092 11.8647C4.95918 11.8647 4.71656 12.0221 4.60171 12.2779H4.5726V11.1412H4V14.1529H4.58554V12.8294Z"
      fill={fill}
    />
    <path
      d="M8.03407 11.8941H7.54396V11.3529H6.95843V11.8941H6.60581V12.3059H6.95843V13.5618C6.95519 13.9868 7.29487 14.1956 7.73483 14.1838C7.90143 14.1794 8.01628 14.15 8.07936 14.1309L7.98069 13.7147C7.94834 13.7221 7.88202 13.7353 7.80924 13.7353C7.66204 13.7353 7.54396 13.6882 7.54396 13.4735V12.3059H8.03407V11.8941Z"
      fill={fill}
    />
    <path
      d="M9.7644 11.8941H9.27429V11.3529H8.68876V11.8941H8.33614V12.3059H8.68876V13.5618C8.68552 13.9868 9.0252 14.1956 9.46516 14.1838C9.63176 14.1794 9.74661 14.15 9.80969 14.1309L9.71102 13.7147C9.67867 13.7221 9.61235 13.7353 9.53957 13.7353C9.39237 13.7353 9.27429 13.6882 9.27429 13.4735V12.3059H9.7644V11.8941Z"
      fill={fill}
    />
    <path
      d="M10.2541 15H10.8396V13.7971H10.8639C10.9561 13.9618 11.1486 14.1926 11.5756 14.1926C12.1611 14.1926 12.5995 13.7706 12.5995 13.0265C12.5995 12.2735 12.1482 11.8647 11.574 11.8647C11.1356 11.8647 10.9529 12.1044 10.8639 12.2676H10.8299V11.8941H10.2541V15ZM10.8283 13.0235C10.8283 12.5853 11.0354 12.3015 11.4122 12.3015C11.8021 12.3015 12.0026 12.6029 12.0026 13.0235C12.0026 13.4471 11.7988 13.7559 11.4122 13.7559C11.0386 13.7559 10.8283 13.4618 10.8283 13.0235Z"
      fill={fill}
    />
    <path
      d="M15.0646 12.4912C14.9837 12.1088 14.6473 11.8647 14.065 11.8647C13.4665 11.8647 13.0589 12.1324 13.0605 12.55C13.0589 12.8794 13.2821 13.0971 13.7592 13.1868L14.183 13.2676C14.4111 13.3132 14.5179 13.3971 14.5179 13.525C14.5179 13.6794 14.3335 13.7956 14.0552 13.7956C13.7867 13.7956 13.6121 13.6897 13.5619 13.4868L12.9909 13.5368C13.0637 13.9515 13.4471 14.1971 14.0569 14.1971C14.678 14.1971 15.1163 13.9044 15.118 13.4765C15.1163 13.1544 14.8883 12.9574 14.4192 12.8647L13.9954 12.7824C13.7431 12.7309 13.6428 12.6515 13.6444 12.5206C13.6428 12.3676 13.8288 12.2618 14.073 12.2618C14.3432 12.2618 14.4855 12.3956 14.5308 12.5441L15.0646 12.4912Z"
      fill={fill}
    />
    <path
      d="M15.9615 14.1853C16.1523 14.1853 16.3157 14.0412 16.3173 13.8618C16.3157 13.6853 16.1523 13.5412 15.9615 13.5412C15.7641 13.5412 15.604 13.6853 15.6056 13.8618C15.604 14.0412 15.7641 14.1853 15.9615 14.1853ZM15.9615 12.5721C16.1523 12.5721 16.3157 12.4279 16.3173 12.2485C16.3157 12.0721 16.1523 11.9279 15.9615 11.9279C15.7641 11.9279 15.604 12.0721 15.6056 12.2485C15.604 12.4279 15.7641 12.5721 15.9615 12.5721Z"
      fill={fill}
    />
    <path
      d="M18.2741 11H17.7614L16.6938 14.6059H17.2066L18.2741 11Z"
      fill={fill}
    />
    <path d="M20 11H19.4872L18.4197 14.6059H18.9324L20 11Z" fill={fill} />
  </svg>
);
