import { useTranslation } from 'react-i18next';

import { PrimaryButton } from '../PrimaryButton/PrimaryButton';
import useLegacyModal from '../ModalLayout/useModal';
import { DigitalAssetsModal } from '../DigitalAssetsModal/DigitalAssetsModal';

import { ALL_IMAGES, ALL_VIDEOS } from '@utils/files';
import { capitalizeFirstLetter } from '@utils/textTransform';
import { DigitalAssetBrief } from '@models/digital-asset';
import { AssetPlaceholder } from '@components/Icons/AssetPlaceholder';
import { VideoPlaceholder } from '@components/Icons/VideoPlaceholder';

export const AssetSelector: React.FC<{
  enabled: boolean;
  assetSrc: string;
  maxImages?: number;
  handleChange: (value: string, id: string) => void;
  type: 'image' | 'video';
}> = ({ enabled, assetSrc, maxImages = 1, handleChange, type }) => {
  const { t } = useTranslation();

  const damModalId = 'dam-modal';
  const { openModal, closeModal, showModalId } = useLegacyModal();

  const openDam = () => openModal(damModalId);

  const onImageSelected = (files: DeepReadonly<DigitalAssetBrief[]>) =>
    handleChange(files[0].url, files[0].id);

  return (
    <>
      {!assetSrc &&
        (type === 'image' ? (
          <AssetPlaceholder onClick={openDam} />
        ) : (
          <VideoPlaceholder onClick={openDam} />
        ))}

      {enabled && (
        <PrimaryButton
          variant={assetSrc ? 'gray' : 'green'}
          onClick={openDam}
          size="small"
        >
          {capitalizeFirstLetter(
            t(
              assetSrc
                ? 'taskMaster.change_asset'
                : `global.${type === 'video' ? 'add_video' : 'add_image'}`,
            ),
          )}
        </PrimaryButton>
      )}

      {damModalId === showModalId && (
        <DigitalAssetsModal
          showModal={damModalId === showModalId}
          setShowModal={closeModal}
          onClose={closeModal}
          allowedFiles={type === 'image' ? ALL_IMAGES : ALL_VIDEOS}
          setFinalFiles={onImageSelected}
          maxFiles={maxImages}
        />
      )}
    </>
  );
};
