import { Page } from '@models/pages';
import { clientWithToken, PAGE_SIZE } from '.';
import {
  ListablePayload,
  ListableState,
  ListConfiguration,
} from '@models/filter';

export namespace PagesApi {
  export namespace List {
    export type State = ListableState<Page, typeof pagesListConfig>;
    export type Query = ListablePayload<typeof pagesListConfig>;
  }
}

// TODO `/directory/pages`
const BASE_URL = '/products/directory/pages';

export const pagesListConfig = {
  sort: [
    { sortBy: 'relevanceScore', sortOrder: 'desc' },
    { sortBy: 'title', sortOrder: 'asc' },
    { sortBy: 'title', sortOrder: 'desc' },
    { sortBy: 'updatedAt', sortOrder: 'desc' },
    { sortBy: 'updatedAt', sortOrder: 'asc' },
  ],
  filters: [
    {
      name: 'optimizationStatus',
      multiple: false,
      hidden: false,
      options: ['all', 'live', 'in_progress', 'not_started'],
    },
    {
      name: 'status',
      multiple: false,
      hidden: true,
      options: [
        'all',
        'active',
        'exists',
        'onboarded',
        'non-onboarded',
        'draft',
        'archived',
        'deleted',
      ],
    },
  ],
} as const satisfies ListConfiguration;

export const listPages = async ({
  page,
  searchText,
  sortOrder,
  sortBy,
  filters,
}: PagesApi.List.Query) => {
  const query = new URLSearchParams({
    page: page + '',
    size: PAGE_SIZE + '',
  });
  if (searchText) query.append('searchText', searchText);
  query.append('sortOrder', sortOrder);
  query.append('sortBy', sortBy);
  for (const filter in filters) {
    const value = filters[filter as keyof typeof filters];
    if (value) query.append(filter, value);
  }

  const { data } = await clientWithToken.get<Page[]>(BASE_URL + '?' + query);
  return data;
};

export const countPages = async ({
  searchText,
  filters,
}: Pick<PagesApi.List.Query, 'searchText' | 'filters'>) => {
  const query = new URLSearchParams();
  if (searchText) query.append('searchText', searchText);
  for (const filter in filters) {
    const value = filters[filter as keyof typeof filters];
    if (value) query.append(filter, value);
  }

  const { data } = await clientWithToken.get<{ count: number }>(
    BASE_URL + '/count?' + query,
  );
  return data.count;
};

export const getPage = async (id: string): Promise<Page> => {
  const attributes = clientWithToken.get<{ body: { page: Page } }>(
    'products/pages/ids/' + id,
  );
  const { data: page } = await clientWithToken.get<Page>(BASE_URL + '/' + id);
  page.attributes = (await attributes).data.body.page.attributes;
  // TODO mapper for assets since not correctly implemented yet
  for (const attribute in page.attributes) {
    const value = page.attributes[attribute];
    if (!(value instanceof Array)) continue;
    for (let i = 0; i < value.length; i++) {
      if ('assetId' in (value[i] as any)) value[i] = (value[i] as any).assetId;
    }
  }
  if (!page.pathname)
    page.pathname = (await attributes).data.body.page.pathname;
  return page;
};
