import { registerSideEffects } from '@redux/sideEffects';
import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '@redux/store';
import { IRichContent } from '@models/rich-content';

type RichContentState = {
  data: IRichContent[] | null;
  error: string | null;
  totalCount: number;
  selectedRichContent: IRichContent | null;
  feedbackContent: string;
};

const initialState: DeepReadonly<RichContentState> = {
  data: null,
  totalCount: 0,
  error: null,
  selectedRichContent: null,
  feedbackContent: '',
};

const { actions, reducer } = createSlice({
  name: 'richContent',
  initialState,
  reducers: {
    SET_RICH_CONTENT: (state, { payload }: { payload: IRichContent[] }) => {
      state.data = payload;
    },
    SET_SELECTED: (state, { payload }: { payload: null | IRichContent }) => {
      state.selectedRichContent = payload;
    },
    SET_SELECTED_CONTENT_LIST: (
      state,
      { payload }: { payload: IRichContent['contentList'] },
    ) => {
      if (state.selectedRichContent)
        state.selectedRichContent.contentList = payload;
    },
    UPDATE_FEEDBACK_CONTENT: (state, { payload }: { payload: string }) => {
      state.feedbackContent = payload;
    },
  },
});

const thunks = {} satisfies { [key: string]: (...args: any[]) => AppThunk };

registerSideEffects();

export const RRichContent = Object.assign(actions, thunks);

export default reducer;
