import { ENDPOINTS } from '@constants/endpoints';
import { clientWithToken, PAGE_SIZE } from '.';
import { ensureError, isError } from '@utils/ImproperError';
import { LegacyProductVersion, LegacyVersion } from '@models/productVersion';
import { CREATION_TYPE } from '@constants/productVersion';
import { updateVersionExperimentTags } from './experiments';

export const listVersionsById = async (
  ids: string[],
): Promise<unknown[] | Error> => {
  const result = await clientWithToken
    .get<{
      body: {
        list: unknown[];
      };
    }>(`${ENDPOINTS.contextualizedProducts.contextualisedVersions}/${ids}`)
    .catch(ensureError);
  return isError(result) ? result : result.data.body.list;
};

export const checkProductVersionExists = async (
  productId: string,
  data: string[],
): Promise<boolean | Error> => {
  const response = await clientWithToken
    .post<{ body: { prescriptiveContents: unknown[] } }>(
      `${ENDPOINTS.contextualizedProducts.products}/${productId}/${ENDPOINTS.contextualizedProducts.contexts}`,
      data,
    )
    .catch(ensureError);

  return isError(response)
    ? response
    : response.data.body.prescriptiveContents?.length > 0;
};

export const getContextualizedVersionsCount = async (
  productId: string,
): Promise<
  | {
      totalCount: number;
      contextualizeVersions: number;
      testVersion: number;
    }
  | Error
> => {
  const response = await clientWithToken
    .get<{
      body: {
        totalCount: number;
        contextualizeVersions: number;
        testVersion: number;
      };
    }>(
      `${ENDPOINTS.contextualizedProducts.products}/${productId}/${ENDPOINTS.contextualizedProducts.count}`,
    )
    .catch(ensureError);

  return isError(response) ? response : response.data.body;
};

export const patchProductVersion = async (
  productId: string,
  versionId: string,
  creationType: CREATION_TYPE,
  data: LegacyVersion.PostVersion,
): Promise<LegacyProductVersion | Error> => {
  const prevExperimentIds = await getProductVersion(productId, versionId).then(
    (r) => r.experimentIds,
  );

  const { products, prescriptiveContents, queryParams, contextualizedVersion } =
    ENDPOINTS.contextualizedProducts;
  const url = `${products}/${productId}/${prescriptiveContents}/${versionId}/${contextualizedVersion}?${queryParams.creationType}=${creationType}`;
  const response = await clientWithToken
    .patch<{
      body: LegacyProductVersion;
    }>(url, data)
    .catch(ensureError);

  if (!isError(response))
    updateVersionExperimentTags({
      type: 'product',
      parentId: productId,
      id: versionId,
      experimentIds: response.data.body.experimentIds,
      prevExperimentIds,
    }).catch(console.error);

  return isError(response) ? response : response.data.body;
};

export const createNewProductVersion = async (
  productId: string,
  creationType: CREATION_TYPE,
  body: LegacyVersion.PostVersion,
): Promise<LegacyProductVersion | Error> => {
  const { products, prescriptiveContents, queryParams } =
    ENDPOINTS.contextualizedProducts;
  const url = `${products}/${productId}/${prescriptiveContents}?${queryParams.creationType}=${creationType}`;
  const response = await clientWithToken
    .post<{ body: LegacyProductVersion }>(url, body)
    .catch(ensureError);

  if (!isError(response)) {
    const { experimentIds, id: versionId } = response.data.body;
    updateVersionExperimentTags({
      type: 'product',
      parentId: productId,
      id: versionId,
      experimentIds,
      prevExperimentIds: [],
    }).catch(console.error);
  }

  return isError(response) ? response : response.data.body;
};

export const createUpdateProductVersion = async (
  productId: string,
  versionId: string,
  creationType: string,
  body: LegacyVersion.PostVersion,
): Promise<LegacyProductVersion | Error> => {
  const { products, prescriptiveContents, queryParams } =
    ENDPOINTS.contextualizedProducts;
  const response = await clientWithToken
    .put<{ body: LegacyProductVersion }>(
      `${products}/${productId}/${prescriptiveContents}/${versionId}?${queryParams.creationType}=${creationType}`,
      body,
    )
    .catch(ensureError);
  return isError(response) ? response : response.data.body;
};

export const createPatchProductVersion = async (
  productId: string,
  versionId: string,
  creationType: string,
  body: LegacyVersion.PostVersion,
): Promise<LegacyProductVersion | Error> => {
  const prevExperimentIds = await getProductVersion(productId, versionId).then(
    (r) => r.experimentIds,
  );
  const { products, prescriptiveContents, queryParams } =
    ENDPOINTS.contextualizedProducts;
  const response = await clientWithToken
    .patch<{ body: LegacyProductVersion }>(
      `${products}/${productId}/${prescriptiveContents}/${versionId}/${ENDPOINTS.contextualizedProducts.contextualizedVersion}?${queryParams.creationType}=${creationType}`,
      body,
    )
    .catch(ensureError);

  if (!isError(response))
    updateVersionExperimentTags({
      type: 'product',
      parentId: productId,
      id: versionId,
      experimentIds: response.data.body.experimentIds,
      prevExperimentIds,
    }).catch(console.error);

  return isError(response) ? response : response.data.body;
};

export const publishProductVersion = async (payload: {
  productId: string;
  newVersionId: string;
  eventType: 'NEW_MAKEUP' | 'UPDATE_PRODUCT';
  contentReference: unknown;
}): Promise<unknown | Error> => {
  const url = `${ENDPOINTS.contextualizedProducts.products}/${payload.productId}/${ENDPOINTS.contextualizedProducts.publish}/${payload.newVersionId}?${ENDPOINTS.contextualizedProducts.queryParams.eventType}=${payload.eventType}&${ENDPOINTS.contextualizedProducts.queryParams.contentReference}=${payload.contentReference}`;
  const response = await clientWithToken
    .patch<{ body: unknown }>(url, {})
    .catch(ensureError);
  return isError(response) ? response : response.data.body;
};

export const deleteProductVersion = async ({
  productId,
  newVersionId,
}: {
  productId: string;
  newVersionId: string;
}) => {
  const url = `${ENDPOINTS.contextualizedProducts.products}/${productId}/${ENDPOINTS.contextualizedProducts.prescriptiveContents}/${newVersionId}`;
  try {
    const prevExperimentIds = await getProductVersion(
      productId,
      newVersionId,
    ).then((r) => r.experimentIds);

    const response = await clientWithToken.delete<{
      body: {
        errors: [];
        body: 'The prescriptive content has been deleted successfully';
      };
    }>(url, {});

    updateVersionExperimentTags({
      type: 'product',
      parentId: productId,
      id: newVersionId,
      experimentIds: [],
      prevExperimentIds: prevExperimentIds,
    }).catch(console.error);

    return {
      message: response.data.body,
      isSuccess: true,
    };
  } catch (errorObj: any) {
    const { errors } = errorObj;
    return {
      message: errors?.[0]?.message,
      isSuccess: false,
    };
  }
};

export const patchUpdateProductVersionPublishDate = async (payload: any) => {
  const { prescriptiveContents, updatePublishDate, publishDate, expiryDate } =
    ENDPOINTS.contextualizedProducts;
  const url = `${ENDPOINTS.contextualizedProducts.products}/${payload.productId}/${prescriptiveContents}/${payload.newVersionId}/${updatePublishDate}?${publishDate}=${payload.publishDate}&${expiryDate}=${payload.expiryDate}`;
  try {
    const response = await clientWithToken.patch<{ body: unknown }>(url, {});
    return {
      message: response.data.body,
      isSuccess: true,
    };
  } catch (errorObj: any) {
    const { errors } = errorObj;
    return {
      message: errors?.[0]?.message,
      isSuccess: false,
    };
  }
};

export const submitTaskFeedback = async (payload: {
  productId: string;
  versionId: string;
  feedBack: string;
  taskId: string;
}) => {
  const { prescriptiveContents, taskFeedback } =
    ENDPOINTS.contextualizedProducts;
  const url = `${ENDPOINTS.contextualizedProducts.products}/${payload.productId}/${prescriptiveContents}/${payload.versionId}/${taskFeedback}/${payload.taskId}`;
  try {
    const response = await clientWithToken.post<{ body: unknown }>(url, {
      feedback: payload.feedBack,
    });
    return {
      message: response.data.body,
      isSuccess: true,
    };
  } catch (errorObj: any) {
    const { errors } = errorObj;
    return {
      message: errors?.[0]?.message,
      isSuccess: false,
    };
  }
};

export const updateTaskStatus = async (payload: any) => {
  const { prescriptiveContents, taskStatus, status, queryParams } =
    ENDPOINTS.contextualizedProducts;
  const url = `${ENDPOINTS.contextualizedProducts.products}/${payload.productId}/${prescriptiveContents}/${payload.versionId}/${taskStatus}/${payload.taskId}?${status}=${payload.status}&${queryParams.submitType}=${payload.submitType}`;
  try {
    const response = await clientWithToken.patch<{ body: unknown }>(url, {});
    return {
      message: response.data.body,
      isSuccess: true,
    };
  } catch (errorObj: any) {
    const { errors } = errorObj;
    return {
      message: errors?.[0]?.message,
      isSuccess: false,
    };
  }
};

export const startReview = async (payload: any) => {
  const { startReview, contextualizedProducts } =
    ENDPOINTS.contextualizedProducts;
  const url = `${contextualizedProducts}/${payload.versionId}/${startReview}/${payload.taskId}`;
  try {
    const response = await clientWithToken.patch<{ body: unknown }>(url, {});
    return {
      message: response.data.body,
      isSuccess: true,
    };
  } catch (errorObj: any) {
    const { errors } = errorObj;
    return {
      message: errors?.[0]?.message,
      isSuccess: false,
    };
  }
};

export const approveContent = async (payload: {
  productId: string;
  versionId: string;
  taskId: string;
}) => {
  const { approveTask, contextualizedProducts } =
    ENDPOINTS.contextualizedProducts;
  const url = `${contextualizedProducts}/${payload.versionId}/${approveTask}/${payload.taskId}`;
  try {
    const response = await clientWithToken.patch<{ body: unknown }>(url, {});
    return {
      message: response.data.body,
      isSuccess: true,
    };
  } catch (errorObj: any) {
    const { errors } = errorObj;
    return {
      message: errors?.[0]?.message,
      isSuccess: false,
    };
  }
};

export const fetchPrescriptiveSettings = async (payload: any) => {
  const { products, settings, prescriptiveContents } =
    ENDPOINTS.contextualizedProducts;
  const url = `${products}/${payload.productId}/${prescriptiveContents}/${settings}`;
  try {
    const response = await clientWithToken.get<{
      body: {
        versionRatioList: LegacyVersion.Settings[];
        globalActive: boolean;
      };
    }>(url, {});
    return {
      data: response.data.body,
      isSuccess: true,
    };
  } catch (errorObj: any) {
    const { errors } = errorObj;
    return {
      message: errors?.[0]?.message,
      isSuccess: false,
    };
  }
};

export const updatePrescriptiveSettings = async (
  payload: any,
): Promise<
  | {
      isSuccess: true;
      data: { globalActive: boolean };
    }
  | { isSuccess: false; message?: string; isBilling: boolean }
> => {
  const { products, settings, prescriptiveContents } =
    ENDPOINTS.contextualizedProducts;
  const url = `${products}/${payload.productId}/${prescriptiveContents}/${settings}`;
  try {
    const response = await clientWithToken.patch<{
      body: { globalActive: boolean };
    }>(url, payload.data);
    return {
      data: response.data.body,
      isSuccess: true,
    };
  } catch (errorObj: any) {
    const { errors, status } = errorObj;
    return {
      message: errors?.[0]?.message,
      isBilling: status === 426,
      isSuccess: false,
    };
  }
};

export const fetchPrescriptiveDeliveryRatio = async (
  payload: any,
): Promise<
  | {
      isSuccess: true;
      data: { versionRatioList: LegacyVersion.Settings[] };
    }
  | { isSuccess: false; message?: string; data?: unknown }
> => {
  const { contextualizedProducts, deliveryRatio } =
    ENDPOINTS.contextualizedProducts;
  const url = `${contextualizedProducts}/${deliveryRatio}/${payload.productId}`;
  try {
    const response = await clientWithToken.get<{
      body: { versionRatioList: LegacyVersion.Settings[] };
    }>(url, {});
    return {
      isSuccess: true,
      data: response.data.body,
    };
  } catch (errorObj: any) {
    const { errors } = errorObj;
    return {
      message: errors?.[0]?.message,
      isSuccess: false,
    };
  }
};

export const updateDeliveryRatio = async (payload: any) => {
  const { contextualizedProducts, deliveryRatio } =
    ENDPOINTS.contextualizedProducts;
  const url = `${contextualizedProducts}/${deliveryRatio}/${payload.productId}`;
  try {
    const response = await clientWithToken.patch<{
      body: {
        versionRatioList: LegacyVersion.Settings[];
      };
    }>(url, payload.data);
    return {
      data: response.data.body,
      isSuccess: true,
    };
  } catch (errorObj: any) {
    const { errors } = errorObj;
    return {
      message: errors?.[0]?.message,
      isSuccess: false,
    };
  }
};

export const getContextualizedVersions = async (ids: Readonly<string[]>) => {
  try {
    const url = `${
      ENDPOINTS.contextualizedProducts.contextualisedVersions
    }/${ids.join(',')}`;
    const response = await clientWithToken.get<{
      body: { list: LegacyProductVersion[] };
    }>(url);
    return {
      data: response.data.body,
      isSuccess: true,
    };
  } catch (errorObj: any) {
    const { errors } = errorObj;
    return {
      message: errors?.[0]?.message,
      isSuccess: false,
    };
  }
};

export const getProductVersion = async (productId: string, id: string) => {
  const url = `${ENDPOINTS.contextualizedProducts.products}/${productId}/${ENDPOINTS.contextualizedProducts.prescriptiveContents}/${id}`;
  const response = await clientWithToken.get<{
    body: LegacyVersion.PostVersion & LegacyProductVersion;
  }>(url);
  return response.data.body;
};

export const fetchProductVersions = async (
  productId: string,
  payload: LegacyVersion.Filter,
): Promise<
  | {
      isSuccess: true;
      data: {
        content: LegacyProductVersion[];
        totalElements: number;
        number: number;
      };
    }
  | {
      isSuccess: false;
      message?: string;
    }
> => {
  try {
    const url = `${ENDPOINTS.contextualizedProducts.products}/${productId}/${ENDPOINTS.contextualizedProducts.prescriptiveContents}/${ENDPOINTS.contextualizedProducts.filter}`;
    if (!payload.pageSize) payload.pageSize = PAGE_SIZE;
    const response = await clientWithToken.post<{
      body: {
        content: LegacyProductVersion[];
        totalElements: number;
        number: number;
      };
    }>(url, payload);
    return {
      data: response.data.body,
      isSuccess: true,
    };
  } catch (error: any) {
    const { errors } = error;
    return {
      message: errors?.[0]?.message,
      isSuccess: false,
    };
  }
};

export const fetchLiveVersions = async (payload: {
  productId: string;
  pageSize: any;
  page: number;
}): Promise<
  | {
      isSuccess: true;
      data: { content: LegacyProductVersion[] };
    }
  | { isSuccess: false; message?: string }
> => {
  const url = `${ENDPOINTS.contextualizedProducts.products}/${payload.productId}/${ENDPOINTS.contextualizedProducts.published}?${ENDPOINTS.contextualizedProducts.queryParams.page}=${payload.page}&${ENDPOINTS.contextualizedProducts.queryParams.size}=${payload.pageSize}`;
  try {
    const response = await clientWithToken.get<{
      body: { content: LegacyProductVersion[] };
    }>(url);
    return {
      data: response.data.body,
      isSuccess: true,
    };
  } catch (error: any) {
    const { errors } = error;
    return {
      message: errors?.[0]?.message,
      isSuccess: false,
    };
  }
};

export const createVersionPreview = async (payload: {
  productId: string;
  body: {
    id: string;
    templateId?: string;
    // TODO some functions call this with `IPersonalisedSection` but that's not accepted by the API. Someone just magically transformed anys and I don't want to fix it right now
    contentList: (
      | LegacyVersion.PostPersonalizedSection
      | LegacyVersion.PersonalizedSection
    )[];
  };
  eventType: 'AD_HOC' | 'MAKEUP';
}) => {
  try {
    const url = `${ENDPOINTS.contextualizedProducts.products}/${payload.productId}/${ENDPOINTS.contextualizedProducts.prescriptiveContents}/${ENDPOINTS.contextualizedProducts.preview}?eventType=${payload.eventType}`;
    const response = await clientWithToken.post<{ body: unknown }>(
      url,
      payload.body,
    );
    await new Promise((resolve) => setTimeout(resolve, 4000)); // Wait for 4 seconds
    return {
      data: response.data.body,
      isSuccess: true,
    };
  } catch (error: any) {
    const { errors } = error;
    return {
      message: errors?.[0]?.message,
      isSuccess: false,
    };
  }
};
