import './SkeletonText.scss';

export const SkeletonText: React.FC<{
  className?: string;
  color?: 'emerald' | 'default' | 'oxford-blue';
  style?: React.CSSProperties;
}> = ({ className = '', color = 'default', style }) => (
  <div
    className={`SkeletonText SkeletonText__${color} ${className}`}
    {...(style && { style })}
  />
);

export const SkeletonSpan: React.FC<{
  className?: string;
  color?: 'emerald' | 'default' | 'oxford-blue';
  style?: React.CSSProperties;
}> = ({ className = '', color = 'default', style }) => (
  <span
    className={`SkeletonText SkeletonText__${color} ${className}`}
    {...(style && { style })}
  />
);
