const MODULE = {
  dashboard: 'dashboard',
  products: 'products',
  pages: 'pages',
  digitalAssets: 'digital-assets',
  richContent: 'rich-contents',
  context: 'contexts',
  taskMaster: 'task-master',
  settings: 'settings',
  notifications: 'notifications',
  myAccount: 'my-account',
  strategyBoard: 'onboarding',
} as const;

const COMMON_KEYWORDS = {
  id: 'id',
  tab: 'tab',
  productId: 'productId',
  versionId: 'versionId',
  version: 'version',
  versions: 'versions',
  settings: 'settings',
  overview: 'overview',
  utm: 'utm',
} as const;

export const ROUTES = {
  dashboard: {
    base: `/${MODULE.dashboard}`,
  },
  startGuide: '/start-guide',
  products: {
    base: `/${MODULE.products}`,
    productDetails: `/${MODULE.products}/:${COMMON_KEYWORDS.id}`,
    settings: `/${MODULE.settings}`,
    channels: `/channels`,
    versionHistory: `/history`,
    tasks: `/tasks`,
    comingSoon: '/soon/future',
    contextualizeVersion: {
      base: `/${MODULE.products}/:${COMMON_KEYWORDS.productId}/${COMMON_KEYWORDS.version}/:${COMMON_KEYWORDS.versionId}`,
      versions: `/${COMMON_KEYWORDS.versions}`,
      edit: '/edit',
      create: `${COMMON_KEYWORDS.versions}/create`,
      initialize: `/initialize`,
      overview: `/${COMMON_KEYWORDS.overview}`,
      performance: `/performance`,
      testVersionDelivery: '/test-version',
      cvDelivery: '/contextualize-version',
      versionSettings: '/version-setting',
    },
    queryParams: {
      draftId: 'draftId',
    },
  },
  pages: {
    base: `/${MODULE.pages}`,
    detailView: `/${MODULE.pages}/:${COMMON_KEYWORDS.id}`,
    versions: {
      base: `/${MODULE.pages}/:${COMMON_KEYWORDS.id}/${COMMON_KEYWORDS.versions}/:${COMMON_KEYWORDS.versionId}`,
      versions: `/${MODULE.pages}/:${COMMON_KEYWORDS.id}/${COMMON_KEYWORDS.versions}`,
      create: 'create',
      overview: COMMON_KEYWORDS.overview,
      performance: 'performance',
    },
  },
  digitalAssets: {
    base: `/${MODULE.digitalAssets}`,
    digitalAssetsDetail: `/${MODULE.digitalAssets}/:${COMMON_KEYWORDS.id}`,
  },
  richContent: {
    base: `/${MODULE.richContent}`,
  },
  context: {
    base: `/${MODULE.context}`,
    utm: `/${MODULE.context}/${COMMON_KEYWORDS.utm}`,
    settings: {
      base: `/${MODULE.context}/${COMMON_KEYWORDS.settings}`,
      weightageSettings: `/${MODULE.context}/${COMMON_KEYWORDS.settings}/weightage-settings`,
      contextSettings: `/${MODULE.context}/${COMMON_KEYWORDS.settings}/context-settings`,
    },
  },
  taskMaster: {
    base: `/${MODULE.taskMaster}`,
  },
  settings: {
    base: `/${MODULE.settings}`,
    billing: `/${MODULE.settings}/billing`,
    users: `/${MODULE.settings}/users`,
    workflow: `/${MODULE.settings}/workflow`,
    permissions: `/${MODULE.settings}/permissions`,
  },
  notifications: {
    base: `/${MODULE.notifications}`,
  },
  myAccount: {
    base: `/${MODULE.myAccount}`,
  },
  strategyBoard: {
    base: `/${MODULE.strategyBoard}`,
    creatingTests: `/${MODULE.strategyBoard}/creatingTests`,
    testVersions: `/${MODULE.strategyBoard}/customize/:${COMMON_KEYWORDS.productId}`,
    dashboard: `/${MODULE.dashboard}/`,
    scanCatalog: `/${MODULE.strategyBoard}/welcome`,
    selectProduct: `/${MODULE.strategyBoard}/products`,
    scanCatalogProduct: `/${MODULE.strategyBoard}/recommendations`,
  },
} as const;
