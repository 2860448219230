import { ITaskListRootObject } from '@models/task-master';

export const STATUS_MAP = {
  TO_DO: 'TO_DO',
  IN_PROGRESS: 'IN_PROGRESS',
  PAUSED: 'PAUSED',
  SUBMITTED: 'SUBMITTED',
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED',
  APPROVED: 'APPROVED',
  NEW: 'NEW',
  DRAFT: 'DRAFT',
  EXPIRED: 'EXPIRED',
  LIVE: 'LIVE',
  AWAITING_PUBLICATION: 'AWAITING_PUBLICATION',
  ON_HOLD: 'ON_HOLD',
  IN_TEST: 'IN_TEST',
  READY_FOR_REVIEW: 'READY_FOR_REVIEW',
} as const;

export const SUBMIT_TYPE = {
  SAVE: 'SAVE',
  SUBMIT: 'SUBMIT',
} as const;

export const STATUS_ORDER = {
  TO_DO: 1,
  IN_PROGRESS: 2,
  SUBMITTED: 3,
  COMPLETED: 4,
} as const;

export const STATUS_PROPERTIES = [
  {
    id: 'TO_DO',
    label_type_two: 'to do',
    label: 'To Do',
    css_class: 'to_do',
  },
  {
    id: 'IN_PROGRESS',
    label_type_two: 'in progress',
    label: 'In Progress',
    css_class: 'in_progress',
  },
  {
    id: 'SUBMITTED',
    label_type_two: 'submitted',
    label: 'Submitted',
    css_class: 'submitted',
  },
  {
    id: 'PENDING',
    label_type_two: 'pending',
    label: 'Pending',
    css_class: 'pending',
  },
  {
    id: 'COMPLETED',
    label_type_two: 'completed',
    label: 'Complete',
    css_class: 'completed',
  },
  {
    id: 'APPROVED',
    label_type_two: 'approved',
    label: 'Approved',
    css_class: 'completed',
  },
] as const;

export const TASK_TYPE = {
  AUTHOR_CONTENT: { key: 'AUTHOR_CONTENT', name: 'authorContent' },
  VISUAL_ASSETS: { key: 'VISUAL_ASSETS', name: 'visualAsset' },
  REVIEW: { key: 'REVIEW', name: 'reviewer' },
  REVIEW_CONTENT: { key: 'REVIEW_CONTENT', name: 'reviewer' },
  REVIEW_VISUAL_ASSET: { key: 'REVIEW_VISUAL_ASSET', name: 'reviewer' },
} as const;

export const defaultState: ITaskListRootObject['taskMapList'] = [
  {
    status: 'TO_DO',
    tasks: [],
  },
  {
    status: 'IN_PROGRESS',
    tasks: [],
  },
  {
    status: 'COMPLETED',
    tasks: [],
  },
  {
    status: 'SUBMITTED',
    tasks: [],
  },
];
