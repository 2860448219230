import { useDispatch } from 'react-redux';
import { useRef, useState, memo } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { RModal } from '@redux/slices/modal';
import { toast } from '@components/ToastNotification/ToastManager';
import {
  approveRichContentTask,
  feedbackOnRichContentTask,
  fetchRichContent,
} from '@apis/richContent';
import { RRichContent } from '@redux/slices/richContent';
import { STATUS_MAP } from '@constants/status';
import { capitalizeFirstLetter } from '@utils/textTransform';

import { PrimaryButton } from '@components/PrimaryButton/PrimaryButton';
import { TextareaWithoutBorder } from '@components/TextareaWithoutBorder/TextareaWithoutBorder';
import { AuthorContentIcon } from '@components/Icons/AuthorContentIcon';
import { AssetPlaceholder } from '@components/Icons/AssetPlaceholder';
import { isError } from '@utils/ImproperError';

export const TemplateSelectionFooter: React.FC<{
  id: string;
  permissions: any;
  taskAssigneeData: any;
  submitTemplateData: any;
  status: string;
}> = memo(
  ({ permissions, id, taskAssigneeData, submitTemplateData, status }) => {
    const { t } = useTranslation();
    const contentType = useRef<any>(null);
    const dispatch = useDispatch();
    const [feedBackText, setFeedBackText] = useState('');
    const history = useHistory();

    const { status: authorStatus } = taskAssigneeData['AUTHOR_CONTENT'] || {
      status: STATUS_MAP['TO_DO'],
    };
    const { status: visualAssetStatus } = taskAssigneeData['VISUAL_ASSETS'] || {
      status: STATUS_MAP['TO_DO'],
    };
    const { status: reviewerStatus } = taskAssigneeData['REVIEW'] ||
      taskAssigneeData['REVIEW_VISUAL_ASSET'] ||
      taskAssigneeData['REVIEW_CONTENT'] || {
        status: STATUS_MAP['PENDING'],
      };

    const onCancelModal = () => {
      dispatch(RModal.close());
    };

    const sendFeedback = async () => {
      const result = feedbackOnRichContentTask({
        contentId: id,
        taskId: taskAssigneeData[contentType.current]?.id,
      });
      if (isError(result)) toast.show(result);
      onCancelModal();
    };

    const isAnyTaskApproved = () => {
      return (
        taskAssigneeData['AUTHOR_CONTENT'].status === STATUS_MAP['COMPLETED'] ||
        taskAssigneeData['VISUAL_ASSETS'].status === STATUS_MAP['COMPLETED']
      );
    };

    const feedBack = (type: string) => {
      contentType.current = type;
      dispatch(
        RModal.open({
          type: 'typeB',
          icon: <AuthorContentIcon />,
          danger: false,
          className: 'feedback-richcontent-template-model',
          heading:
            type === 'AUTHOR_CONTENT'
              ? capitalizeFirstLetter(
                  t('taskMaster.feedback_button', {
                    for: t('taskMaster.visual_assets'),
                  }),
                )
              : capitalizeFirstLetter(
                  t('taskMaster.feedback_button', {
                    for: t('taskMaster.author_content'),
                  }),
                ),
          supportingText: t('taskMaster.enter_comment'),
          buttons: [
            {
              text: capitalizeFirstLetter(t('global.cancel')),
              variant: 'gray',
              method: onCancelModal,
            },
            {
              text: capitalizeFirstLetter(t('forms.submit')),
              variant: 'green',
              method: sendFeedback,
            },
          ],
          children: feedbackInput,
        }),
      );
    };
    const handlerChange = (event: any) => {
      setFeedBackText(event.target.value);
      dispatch(RRichContent.UPDATE_FEEDBACK_CONTENT(event.target.value));
    };
    const feedbackInput = (
      <TextareaWithoutBorder
        className="RichContentTemplate__feedbackTextArea"
        value={feedBackText}
        onChange={handlerChange}
      />
    );
    const doneHandler = async () => {
      const result = await fetchRichContent({
        pageNumber: 1,
        pageSize: 20,
        ids: [id],
      });
      if (isError(result)) toast.show(result);
      else dispatch(RRichContent.SET_SELECTED(result.items[0]));

      history.push(`/rich-contents/${id}`);
    };
    const approveTemplateData = async (type: string) => {
      contentType.current = type;
      const result = await approveRichContentTask(
        id,
        taskAssigneeData[contentType.current].id,
      );
      if (isError(result)) toast.show(result);
      else {
        const result = await fetchRichContent({
          pageNumber: 1,
          pageSize: 20,
          ids: [id],
        });
        if (isError(result)) toast.show(result);
        else dispatch(RRichContent.SET_SELECTED(result.items[0]));
      }
      !isAnyTaskApproved()
        ? dispatch(
            RModal.open({
              type: 'typeB',
              icon:
                type === 'AUTHOR_CONTENT' ? (
                  <AuthorContentIcon />
                ) : (
                  <AssetPlaceholder />
                ),
              danger: false,
              heading:
                type === 'AUTHOR_CONTENT'
                  ? capitalizeFirstLetter(
                      t('taskMaster.for_approved', {
                        for: t('taskMaster.author_content'),
                      }),
                    )
                  : capitalizeFirstLetter(
                      t('taskMaster.for_approved', {
                        for: t('taskMaster.visual_assets'),
                      }),
                    ),
              supportingText: t('taskMaster.notify_statement'),
              buttons: [
                {
                  text: capitalizeFirstLetter(
                    t('taskMaster.continue_reviewing'),
                  ),
                  variant: 'gray',
                  method: onCancelModal,
                },
                {
                  text: capitalizeFirstLetter(t('global.done')),
                  variant: 'green',
                  method: doneHandler,
                },
              ],
            }),
          )
        : history.push(`/rich-contents/${id}`);
    };

    return (
      <>
        <div className="RichContentTemplate-footer">
          <div>
            {permissions['REVIEW'] &&
              process.env.REACT_APP_WORKFLOW === 'ON' &&
              status === 'READY_FOR_REVIEW' &&
              taskAssigneeData.VISUAL_ASSETS.status === 'SUBMITTED' && (
                <>
                  <PrimaryButton
                    onClick={() => feedBack('REVIEW_VISUAL_ASSET')}
                  >
                    {capitalizeFirstLetter(
                      t('rich_content.feedback_on_visual_assets'),
                    )}
                  </PrimaryButton>
                  <PrimaryButton
                    onClick={() => approveTemplateData('REVIEW_VISUAL_ASSET')}
                  >
                    {capitalizeFirstLetter(
                      t('rich_content.approve_visual_assets'),
                    )}
                  </PrimaryButton>
                </>
              )}
          </div>
          <div>
            {permissions['REVIEW'] &&
              status === 'READY_FOR_REVIEW' &&
              process.env.REACT_APP_WORKFLOW === 'ON' &&
              taskAssigneeData.AUTHOR_CONTENT.status === 'SUBMITTED' && (
                <>
                  <PrimaryButton onClick={() => feedBack('REVIEW_CONTENT')}>
                    {capitalizeFirstLetter(
                      t('rich_content.feedback_on_authored_content'),
                    )}
                  </PrimaryButton>
                  <PrimaryButton
                    onClick={() => approveTemplateData('REVIEW_CONTENT')}
                  >
                    {capitalizeFirstLetter(
                      t('rich_content.approve_authored_content'),
                    )}
                  </PrimaryButton>
                </>
              )}
            {(permissions.AUTHOR_CONTENT || permissions.VISUAL_ASSETS) &&
              authorStatus !== 'SUBMITTED' &&
              visualAssetStatus !== 'SUBMITTED' &&
              authorStatus !== 'COMPLETED' &&
              visualAssetStatus !== 'COMPLETED' &&
              status !== 'LIVE' &&
              status !== 'COMPLETED' && (
                <PrimaryButton onClick={submitTemplateData}>
                  {capitalizeFirstLetter(t('global.save'))}
                </PrimaryButton>
              )}
            {permissions?.REVIEW &&
              reviewerStatus === STATUS_MAP['COMPLETED'] && (
                <PrimaryButton onClick={submitTemplateData}>
                  {capitalizeFirstLetter(t('global.save'))}
                </PrimaryButton>
              )}
          </div>
        </div>
      </>
    );
  },
);
