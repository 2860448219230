/**
 * A wrapper for a thrown value that isn't an instance of {@link Error}.
 *
 * Best used with {@link ensureError} to convert non-errors to {@link ImproperError}s.
 *
 * @example
 * try {
 *   throw "Bad Error"
 * } catch(err) {
 *   if(err instanceof Error) return err;
 *   else return new ImproperError(err);
 *   // return ensureError(err); // Simplified version of
 * }
 */
export class ImproperError extends Error {
  constructor(public value: unknown) {
    super('Improper Error');
  }
}

/**
 * Ensures that a (thrown) value is an instance of error.
 *
 * @param thrown Any value
 * @returns The error if it was an error already or the value as an {@link ImproperError} if not an error
 *
 * @example
 * try {
 *   throw "Bad Error"
 * } catch(err) {
 *   return ensureError(err); // Now guarantees an error type is returned
 * }
 */
export const ensureError = (thrown: unknown): Error =>
  thrown instanceof Error ? thrown : new ImproperError(thrown);

/**
 * Type guard for {@link Error}
 *
 * @param value The value to check
 * @returns Whether the value is an error
 */
export const isError = (value: unknown): value is Error =>
  value instanceof Error;
