import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Attribute,
  IPermission,
  IRichContentSection,
} from '@models/rich-content';
import { characterLimits } from '../../../../heuristic.config';
import { capitalizeEveryFirstLetter } from '@utils/textTransform';

import SectionHeader from '../RichContentSectionHeader';
import { SectionFieldsFactory } from '../../[id]/template/SectionFieldsFactory';

import './style.scss';

const ReviewLayout: React.FC<{
  image: Attribute;
  title: Attribute;
  description: Attribute;
  handleChange: Function;
  permissions: IPermission;
  index: number;
  sectionOrder: number;
}> = ({
  image,
  title,
  description,
  index,
  permissions,
  sectionOrder,
  handleChange,
}) => {
  const sectionFieldsFactory = (
    id: string,
    key: string,
    attribute: Attribute,
    characterLimits = 0,
  ) => (
    <SectionFieldsFactory
      id={id}
      key={key}
      attributeKey={key}
      sectionOrder={sectionOrder}
      attribute={attribute}
      permissions={permissions}
      handleChange={handleChange}
      characterLimits={characterLimits}
    />
  );

  const { reviewTitleLimit, reviewContentLimit } = characterLimits.richContent;
  return (
    <>
      <div className="RichContentThreeReview">
        <div className="RichContentThreeReview-image">
          {image.standardText ? (
            <div className="mainImage">
              <img src={image.standardText} alt="Section Main" />
              {sectionFieldsFactory(
                `threeReviewImage${sectionOrder}-${index}`,
                `image${index}`,
                image,
              )}
            </div>
          ) : (
            sectionFieldsFactory(
              `threeReviewPlaceholder${sectionOrder}-${index}`,
              `image${index}`,
              image,
            )
          )}
        </div>
        <div className="RichContentThreeReview-name">
          {sectionFieldsFactory(
            `threeReviewName${sectionOrder}-${index}`,
            `name${index}`,
            title,
            reviewTitleLimit,
          )}
        </div>
        <div className="RichContentThreeReview-content">
          {sectionFieldsFactory(
            `threeReviewDescription${sectionOrder}-${index}`,
            `review${index}`,
            description,
            reviewContentLimit,
          )}
        </div>
      </div>
    </>
  );
};

const RichContentThreeReview: React.FC<IRichContentSection> = ({
  index: sectionOrder,
  length,
  moveSection,
  attributes,
  handleChange,
  permissions,
}) => {
  const { t } = useTranslation();
  const { headingLimit } = characterLimits.richContent;

  const defaultData = [1, 2, 3].map((el) => ({
    image: attributes[`image${el}`],
    name: attributes[`name${el}`],
    review: attributes[`review${el}`],
  }));

  return (
    <>
      <SectionHeader
        title={capitalizeEveryFirstLetter(t('rich_content.reviews'))}
        moveSection={moveSection}
        showDropdown={permissions.AUTHOR_CONTENT}
        index={sectionOrder}
        length={length}
      />
      <div className="RichContentThreeReview-title">
        <SectionFieldsFactory
          id={`threeReviewTitle-${sectionOrder}`}
          attributeKey={'title'}
          sectionOrder={sectionOrder}
          attribute={attributes['title']}
          permissions={permissions}
          handleChange={handleChange}
          characterLimits={headingLimit}
        />
      </div>
      <div className="RichContentThreeReview-container">
        {Object.values(defaultData).map((data, index) => (
          <ReviewLayout
            key={index}
            image={data.image}
            title={data.name}
            description={data.review}
            handleChange={handleChange}
            index={++index}
            sectionOrder={sectionOrder}
            permissions={permissions}
          />
        ))}
      </div>
    </>
  );
};

export default memo(RichContentThreeReview);
