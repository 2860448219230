import { colors } from '@constants/colors';

export const CloseIcon = ({
  height = '16px',
  width = '16px',
  fill = colors.manatte5,
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M14.2221 14.6374L8.02868 8.43652L1.83529 14.6374L1.05737 13.8595L7.25076 7.66609L1.44633 1.85417L2.21676 1.08374L8.02868 6.88817L13.8331 1.08374L14.611 1.85417L8.79911 7.66609L15 13.8595L14.2221 14.6374Z"
      fill={fill}
    />
  </svg>
);
