import { capitalizeFirstLetter } from '@utils/textTransform';

import './OptionsGrid.scss';

export type OptionGridOption = {
  id: string;
  icon: JSX.Element;
  label: string;
};

const computeBalancedGrid = (
  itemCount: number,
): [columns: number, rows: number] => {
  const sqrt = Math.floor(Math.sqrt(itemCount));
  return [sqrt, Math.ceil(itemCount / sqrt)];
};

export const OptionsGrid: React.FC<{
  options: OptionGridOption[];
  onSelect: (option: OptionGridOption) => unknown;
  size?: string | [width: string, height: string];
  layout?: 'balance' | [columns: number, rows: number];
  disabled?: boolean;
}> = ({
  options,
  onSelect,
  size = '5rem',
  layout = 'balance',
  disabled = false,
}) => {
  const [columns, rows] =
    layout === 'balance' ? computeBalancedGrid(options.length) : layout;

  return (
    <div
      className="OptionsGrid"
      style={{
        gridTemplateColumns: `repeat(${columns}, ${size})`,
        gridTemplateRows: `repeat(${rows}, ${size})`,
      }}
    >
      {options.map((option) => (
        <div
          key={option.id}
          className={`Option${disabled ? ' disabled' : ''}`}
          onClick={() => onSelect(option)}
        >
          {option.icon}
          <p className="Option-label">{capitalizeFirstLetter(option.label)}</p>
        </div>
      ))}
    </div>
  );
};
