import { ENDPOINTS } from '@constants/endpoints';
import { clientWithToken } from '.';
import { Notification, NotificationListPayload } from '@models/notifications';

export const listNotifications = async (payload: NotificationListPayload) => {
  const url = ENDPOINTS.notification.base;
  const response = await clientWithToken.post<{
    body: {
      items: Notification[];
      totalItems: number;
    };
  }>(url, payload);
  return response.data.body;
};

export const countNotifications = async ({
  userId,
  channels,
  readStatus,
}: {
  userId: string;
  channels: Array<Notification['channel']>;
  readStatus: Array<Notification['readStatus']>;
}) => {
  const url = ENDPOINTS.notification.count;
  const response = await clientWithToken.post<{ body: number }>(url, {
    channels,
    readStatus,
    userId,
  });
  return response.data.body;
};

export const markNotificationAsRead = async (notificationId: string) => {
  const url = `${ENDPOINTS.notification.update}?${ENDPOINTS.notification.queryParams.notificationIds}=${notificationId}&${ENDPOINTS.notification.queryParams.toStatus}=READ`;
  await clientWithToken.patch<unknown>(url);
};

export const markNewNotificationsAsUnread = async () => {
  const url = `${ENDPOINTS.notification.update}?${ENDPOINTS.notification.queryParams.fromStatus}=NEW&${ENDPOINTS.notification.queryParams.toStatus}=UNREAD`;
  await clientWithToken.patch<unknown>(url);
};

export const sendCustomerFeedback = async (
  customerEmail: string,
  message: string,
  tenantId: string,
) => {
  const url = ENDPOINTS.notification.postNotification;
  await clientWithToken.post<unknown>(url, {
    subject: `Please reach out to ${customerEmail}(Email) from ${tenantId}(Tenant Name)`,
    body: message,
    channel: 'EMAIL',
    eventType: 'WELCOME_USER',
    receiverId: 'support@heuristiccommerce.com',
    receiverAddress: 'support@heuristiccommerce.com',
    tenantId: tenantId,
  });
};
