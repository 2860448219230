import { generatePreview } from '@utils/version';
import fallbackImage from '@assets/illustrations/57_NoImage.svg';
import { ExperienceTargets } from '@components/ExperienceTargets/ExperienceTargets';
import { PrimaryButton } from '@components/PrimaryButton/PrimaryButton';
import { Experience } from '@models/experience';
import {
  AnyAttributeValue,
  AttributeValueMap,
  Product,
  Variant,
} from '@models/product';
import { Template } from '@models/productTemplate';
import {
  capitalizeEveryFirstLetter,
  capitalizeFirstLetter,
} from '@utils/textTransform';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RModal } from '@redux/slices/modal';
import { AttributeField } from './AttributeField/AttributeField';
import { AttributeView } from './AttributeView/AttributeView';
import { useAssets } from '../../hooks/useAssets';
import { PromptComponent } from '@components/PromptComponent/PromptComponent';
import { DiscardChangesModal } from './DiscardChangesModal/DiscardChangesModal';
import { Page } from '@models/pages';
import { RVersion } from '@redux/slices/version';

import './ContentEditor.scss';

export const ContentEditor: React.FC<{
  experience: Experience;
  base: Product | Page | null;
  template: Template;
  variant: Variant | Page | null;
  referenceContent?: AttributeValueMap;
  /** If attributes not on the version should be filled by the base */
  baseFill?: boolean;
}> = ({
  experience,
  base,
  template,
  variant,
  referenceContent = {},
  baseFill,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fallbackVersionId = useMemo(
    () =>
      crypto.randomUUID() ??
      Math.round(Math.random() * Number.MAX_SAFE_INTEGER) + '',
    [],
  );
  const { storeUrl } = useSelector((state) => state.tenant);

  const { isContentChanged, content } = useSelector((s) => s.version);

  /** A cache for full assets from asset IDs */
  const { assetCache, cacheAssets } = useAssets();

  const cacheAllAttributes = useCallback(
    (attributes: AttributeValueMap) => {
      for (const attribute in attributes) {
        const value = attributes[attribute];
        if (typeof value !== 'string' && attribute !== 'richContent')
          cacheAssets(...value);
      }
    },
    [cacheAssets],
  );

  // Cache assets
  useEffect(() => cacheAllAttributes(content), [content, cacheAllAttributes]);
  useEffect(() => {
    if (variant) cacheAllAttributes(variant.attributes);
  }, [variant, cacheAllAttributes]);

  const customizableAttributes = template.attributes.filter(
    (attribute) => attribute.customizable,
  );
  const experienceAttributes = customizableAttributes.filter((attribute) => {
    const list = experience.attributes;
    const name = attribute.name;
    const nonVariantName = name.substring(0, name.indexOf('_'));
    return list.includes(name) || list.includes(nonVariantName);
  });

  const { pageVersions, productVersion } = useSelector((s) => s);

  const versionId =
    productVersion.version?.id ||
    pageVersions.currentItem?.id ||
    fallbackVersionId;

  return (
    <>
      <div className="content-editor">
        <div className="top-box">
          <div className="info">
            <div className="experience">
              <ExperienceTargets experience={experience} />
              <h3>
                <span>
                  {capitalizeFirstLetter(t('contentEditor.testingFor'))}
                </span>
                <span>{experience.title}</span>
              </h3>
            </div>
            <div className="product">
              <img
                src={base?.thumbnail || fallbackImage}
                alt={'Product Thumbnail'}
              />
              <h3>
                <span>
                  1{' '}
                  {capitalizeEveryFirstLetter(
                    t(
                      'global.' +
                        (base && 'pageId' in base ? 'page' : 'product'),
                    ),
                  )}
                </span>
                <span>{base?.title}</span>
              </h3>
            </div>
          </div>
          <div className="content">
            <h4>
              {capitalizeEveryFirstLetter(t('contentEditor.versionContent'))}
            </h4>
            {experienceAttributes.map((attribute, i) => {
              let reference = referenceContent[attribute.name];
              // TODO Variants | This is only to bridge no variant assets being present on product
              if (!reference && attribute.name.startsWith('assets_'))
                reference = referenceContent['assets'];
              return (
                <AttributeField
                  attribute={attribute}
                  key={i}
                  value={content[attribute.name]}
                  referenceContent={reference}
                />
              );
            })}
          </div>
        </div>
        <div className="Card compare-view">
          <div className="heading">{t('contentEditor.heuristic_vs_base')}</div>
          <div className="compare">
            <div className="version">
              <div className="info">
                <h4>
                  {capitalizeEveryFirstLetter(t('contentEditor.testVersion'))}
                </h4>
                <PrimaryButton
                  className="preview-button"
                  size="small"
                  variant="green"
                  onClick={() => {
                    if (!(base && variant)) return;
                    dispatch(RModal.open({ type: 'VersionPreviewModal' }));
                    generatePreview({
                      content,
                      base,
                      variant,
                      versionId,
                      template,
                      storeUrl,
                    })
                      .catch(() => {
                        // Do nothing
                      })
                      .then(() => dispatch(RModal.close()));
                  }}
                >
                  {capitalizeEveryFirstLetter(t('global.preview'))}
                </PrimaryButton>
              </div>
              <div className="content">
                {customizableAttributes.map((attribute, i) => {
                  const { name } = attribute;
                  let value: AnyAttributeValue | undefined = content[name];
                  if (
                    !value &&
                    baseFill !== false &&
                    !experienceAttributes.includes(attribute) &&
                    variant
                  ) {
                    value = variant.attributes[name];
                    if (!value && name.startsWith('assets_'))
                      value = variant.attributes['assets'];
                  }
                  return (
                    <AttributeView
                      attribute={attribute}
                      value={value}
                      assetCache={assetCache}
                      key={i}
                    />
                  );
                })}
              </div>
            </div>
            <div className="version">
              <div className="info">
                <h4>{capitalizeEveryFirstLetter(t('global.base_version'))}</h4>
              </div>
              <div className="content">
                {variant ? (
                  customizableAttributes.map((attribute, i) => {
                    let value = variant.attributes[attribute.name];
                    // TODO Variants | This is only to bridge no variant assets being present on product
                    if (!value && attribute.name.startsWith('assets_'))
                      value = variant.attributes['assets'];
                    return (
                      <AttributeView
                        attribute={attribute}
                        value={value}
                        assetCache={assetCache}
                        key={i}
                      />
                    );
                  })
                ) : (
                  <div />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <PromptComponent
        whenToActive={isContentChanged}
        modal={
          <DiscardChangesModal
            discardChanges={() => dispatch(RVersion.setContent({}))}
          />
        }
      />
    </>
  );
};
