import './StatusTag.scss';

export const StatusTag: React.FC<{
  id?: string;
  backgroundColor?:
    | 'green'
    | 'yellow'
    | 'orange'
    | 'red'
    | 'gray'
    | 'blue'
    | string;
  className?: string;
  filled?: boolean;
  label: string;
  showDot?: boolean;
  variant?: 'rectangular' | 'rounded';
  isTextUppercase?: boolean;
}> = ({
  id,
  backgroundColor = 'gray',
  filled = false,
  className = '',
  label,
  showDot = false,
  variant = 'rectangular',
  isTextUppercase = true,
}) => (
  <div
    id={id}
    className={`StatusTag ${`StatusTag-${variant}`} ${`StatusTag-${backgroundColor}`} ${`StatusTag-${
      filled ? 'filled' : ''
    }`} ${className}`}
  >
    {showDot && <div className="StatusTag-dot" />}
    <span
      className={`StatusTag-label ${
        isTextUppercase ? 'text-uppercase' : 'text-capitalize'
      }`}
    >
      {label}
    </span>
  </div>
);
