import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';

import './TextInput.scss';

export const TextInput: React.FC<{
  className?: string;
  label?: string;
  labelIcon?: JSX.Element;
  id: string;
  placeholder?: string;
  value: string;
  limit?: number;
  postText?: string;
  hasUpdatePermission?: boolean;
  showRemainingCharacters?: boolean;
  onChange: (value: string) => void;
  onOutsideClickHandler?: Function;
}> = ({
  className,
  label,
  labelIcon,
  id,
  placeholder = '',
  value,
  limit,
  postText = '',
  hasUpdatePermission = true,
  showRemainingCharacters = true,
  onChange,
  onOutsideClickHandler,
}) => {
  const { t } = useTranslation();
  const [active, setActive] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [remainingCharacters, setRemainingCharacters] = useState(limit);

  useEffect(() => {
    setInputValue(value);
    updateLimit(value);
  }, [value]);

  const updateLimit = (value: string) => {
    limit && setRemainingCharacters(limit - value.length);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setInputValue(value);
    updateLimit(value);
    onChange(value);
  };

  const handleOutsideClick = () => {
    if (onOutsideClickHandler && id && active) {
      setActive(false);
      onOutsideClickHandler(id, inputValue);
    }
  };

  return (
    <div className={`TextInput ${className ?? ''} `}>
      <div className="TextInput-labelContainer">
        {label && (
          <label className="TextInput-labelContainer__label" htmlFor={id}>
            {labelIcon && labelIcon}
            {label}
          </label>
        )}
        {limit && showRemainingCharacters && (
          <span
            className={`TextInput-labelContainer__textRemaining ${
              remainingCharacters === 0 ? 'limit-exceeded' : ''
            }`}
          >
            {remainingCharacters} {t('global.characters_remaining')}
          </span>
        )}
      </div>
      <OutsideClickHandler onOutsideClick={handleOutsideClick}>
        <input
          type="text"
          className={` ${
            remainingCharacters === 0 ? 'TextInput-error' : 'TextInput-input'
          }${!hasUpdatePermission ? 'disabled' : ''} `}
          placeholder={placeholder}
          value={inputValue}
          readOnly={!hasUpdatePermission}
          onChange={handleChange}
          onFocus={() => hasUpdatePermission && setActive(true)}
          {...(!!id && { id })}
          {...(limit && { maxLength: limit })}
          data-testid={id}
        />
      </OutsideClickHandler>
      {postText && <div className="TextInput-postText">{postText}</div>}
    </div>
  );
};
