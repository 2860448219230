import { LegacyProduct } from '@models/product';
import { clientWithToken } from '.';

export type Plan = 'FREE' | 'PRO' | 'ENTERPRISE';
export type Billing = {
  plan: Plan;
  allowedActiveProducts: number;
  trialStartedOn: number | null;
  intervalMonths: number;
};
export type Rank = {
  rank: number;
  type: 'revenue' | 'price' | 'newest' | 'images' | 'videos' | 'description';
};
export type RankedProduct = {
  id: string;
  ranks: Rank[];
  score: number;
};

const ADAPTER_PATH = 'connector/adapter';

const getPlan = async () =>
  clientWithToken.get<Billing>(`${ADAPTER_PATH}/billing/plan`);

const getEnterpriseCharge = async () =>
  clientWithToken.get<{ hasEnterpriseCharge: boolean; price: number }>(
    `${ADAPTER_PATH}/billing/plan/enterprise`,
  );

export type GalleryStatus = 'disabled' | 'pending' | 'enabled';

const getGalleryStatus = async () =>
  clientWithToken.get<{ galleryEnabled: GalleryStatus }>(
    `${ADAPTER_PATH}/billing/gallery`,
  );

const changePlan = async (
  newPlan:
    | {
        plan: 'FREE';
      }
    | {
        // Pick plan "PRO" from plan enum
        plan: 'PRO';
        activeProducts: number;
        intervalMonths: number;
        returnUrl: string;
      },
) =>
  await clientWithToken.post<unknown>(`${ADAPTER_PATH}/billing/plan`, newPlan);

const payEnterpriseUpgrade = async (returnUrl: string) =>
  await clientWithToken.post<unknown>(
    `${ADAPTER_PATH}/billing/plan/enterprise`,
    { returnUrl },
  );

const buyGallery = async (returnUrl: string) =>
  await clientWithToken.post<unknown>(`${ADAPTER_PATH}/billing/gallery`, {
    returnUrl,
  });

const onboardProduct = async (
  productId: string,
): Promise<{ errors: null | any[] }> => {
  try {
    await clientWithToken.post<LegacyProduct>(
      `${ADAPTER_PATH}/products/onboard`,
      { productId },
    );
    return { errors: null };
  } catch (err) {
    if (err && typeof err === 'object' && 'errors' in err) {
      const { errors } = err as { errors: unknown };
      if (Array.isArray(errors)) return { errors };
    }
    return { errors: [err] };
  }
};

const getRecommendedProducts = async (count: number) =>
  clientWithToken.get<{ rankedProducts: RankedProduct[] }>(
    `${ADAPTER_PATH}/products/recommended?pageSize=${count}`,
  );

export const Connector = {
  onboardProduct,
  getPlan,
  getEnterpriseCharge,
  changePlan,
  payEnterpriseUpgrade,
  getGalleryStatus,
  buyGallery,
  getRecommendedProducts,
};
