import { colors } from '@constants/colors';

export const AssetIcon = ({
  height = '24px',
  width = '24px',
  color = colors.manatte5,
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.49239 19.3289C3.19039 19.2659 2.19289 18.1739 2.24539 16.8929V4.66036C2.19289 3.38986 3.19039 2.30836 4.47139 2.24536H20.4944C21.7964 2.30836 22.7939 3.40036 22.7414 4.68136V16.9034C22.7939 18.1844 21.7964 19.2659 20.5154 19.3289H4.49239ZM20.4839 3.09586H4.49239C3.69439 3.12736 3.05389 3.82036 3.08539 4.63936V16.9034C3.05389 17.7434 3.69439 18.4364 4.51339 18.4784H20.5049C21.3029 18.4364 21.9434 17.7434 21.9119 16.9244V4.66036C21.9434 3.82036 21.3029 3.12736 20.4839 3.09586Z"
      fill={color}
    />
    <path
      d="M16.8301 22.7519H8.15706C7.92606 22.7519 7.73706 22.5629 7.73706 22.3319C7.73706 22.1009 7.92606 21.9119 8.15706 21.9119H16.8301C17.0611 21.9119 17.2501 22.1009 17.2501 22.3319C17.2501 22.5629 17.0716 22.7519 16.8301 22.7519Z"
      fill={color}
    />
    <path
      d="M14.4044 14.2677H4.83887V7.19067H14.4044V14.2677ZM5.67887 13.4172H13.5539V8.03067H5.67887V13.4172Z"
      fill={color}
    />
    <path
      d="M10.8133 12.5142L8.27232 9.78415L5.61582 11.9367L5.08032 11.2857L8.35632 8.62915L11.4328 11.9367L10.8133 12.5142Z"
      fill={color}
    />
    <path
      d="M13.7222 11.9471L11.4542 10.1516L10.1942 11.2436L9.6377 10.6136L11.4227 9.05957L14.2472 11.2751L13.7222 11.9471Z"
      fill={color}
    />
    <path
      d="M19.7387 9.72086H15.3812C15.1502 9.72086 14.9612 9.53186 14.9612 9.30086C14.9612 9.06986 15.1502 8.88086 15.3812 8.88086H19.7387C19.9697 8.88086 20.1587 9.06986 20.1587 9.30086C20.1587 9.53186 19.9697 9.72086 19.7387 9.72086Z"
      fill={color}
    />
    <path
      d="M19.1192 11.8947H15.3812C15.1502 11.8947 14.9612 11.7057 14.9612 11.4747C14.9612 11.2437 15.1502 11.0547 15.3812 11.0547H19.1192C19.3502 11.0547 19.5392 11.2437 19.5392 11.4747C19.5392 11.7057 19.3502 11.8947 19.1192 11.8947Z"
      fill={color}
    />
    <path
      d="M11.2647 22.6992C11.0337 22.6992 10.8447 22.5102 10.8447 22.2792V19.1502C10.8447 18.9192 11.0337 18.7302 11.2647 18.7302C11.4957 18.7302 11.6847 18.9192 11.6847 19.1502V22.2792C11.6952 22.5102 11.5062 22.6992 11.2647 22.6992Z"
      fill={color}
    />
    <path
      d="M13.7218 22.6992C13.4908 22.6992 13.3018 22.5102 13.3018 22.2792V19.1502C13.3018 18.9192 13.4908 18.7302 13.7218 18.7302C13.9528 18.7302 14.1418 18.9192 14.1418 19.1502V22.2792C14.1523 22.5102 13.9633 22.6992 13.7218 22.6992Z"
      fill={color}
    />
  </svg>
);
