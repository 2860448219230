import mixpanel from 'mixpanel-browser';
import store from '@redux/store';

const mixpanelToken = process.env.REACT_APP_MIX_PANEL_KEY || '';

if (mixpanelToken) {
  mixpanel.init(mixpanelToken);
} else {
  console.error('No mix panel token found');
}

export const Mixpanel = {
  track: (eventName: string, payload: any) => {
    const user = store?.getState()?.currentUser?.data;
    if (!user) return;
    const tenant = window.location.hostname.split('.')[0];
    mixpanel.track(eventName, {
      tenant: tenant,
      userId: user.id,
      userName: `${user.firstName} ${user.lastName}`,
      ...payload,
    });
  },
};
