import { useTranslation } from 'react-i18next';

import { ModalLayout } from '@components/ModalLayout/ModalLayout';
import AddSectionList from '../AddSectionList';

import './style.scss';

const AddSectionModal: React.FC<any> = ({
  showModal,
  setShowModal,
  onClose,
  addNewSection,
}) => {
  const { t } = useTranslation();

  return (
    <ModalLayout
      showModal={showModal}
      setShowModal={setShowModal}
      onClose={onClose}
      defaultClose
      className="RichContentAddSelectionModal"
    >
      <h3>{t('rich_content.section_selection_heading')}</h3>
      <p>{t('rich_content.section_selection_sub_text')}</p>
      <AddSectionList addNewSection={addNewSection} />
    </ModalLayout>
  );
};
export default AddSectionModal;
