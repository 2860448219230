export namespace LegacyTemplate {
  export type Taxonomy = {
    name: string;
    parent: string | null;
  };

  export interface AttributeGroup {
    name?: string;
    sectionName?: string;
    groupName: string;
    attributes: Attribute[];
  }

  export interface Attribute {
    attributeName: string;
    dataType: string | null;
    visualTag:
      | LegacyTemplate.EditorField.Popup_Lookup_Checkbox
      | LegacyTemplate.EditorField.Image_Picker
      | LegacyTemplate.EditorField.Text_Box
      | LegacyTemplate.EditorField.Text_Box_Html
      | LegacyTemplate.EditorField.Popup;
    placeholder: string;
    nameInSourceSystem: string | null;
    metaTags: MetaTag[];
    attributeValidators: LegacyTemplate.IAttributeValidator[];
  }

  export type MetaTag =
    | 'Contextualizable'
    | 'Searchable'
    | 'Is_Customizable'
    | 'Is_Searchable'
    | 'Is_Filterable';

  export enum EditorField {
    'Popup_Lookup_Checkbox' = 'Popup_Lookup_Checkbox',
    'Image_Picker' = 'Image_Picker',
    'Text_Box' = 'Text_Box',
    'Text_Box_Html' = 'Text_Box_Html',
    'Popup' = 'Popup',
  }

  export interface IAttributeValidator {
    name:
      | 'MAX_LENGTH'
      | 'MAX_MIN_SIZE'
      | 'NULL_VALIDATOR'
      | 'NUMERIC_VALIDATOR'
      | null;
    value: string | null;
    regex: string | null;
    errorMessage: string | null;
  }

  export interface CharacterLimitsArray {
    characterLimits: number;
    attributeName: string;
    personalisedText: string;
  }

  export interface IProductKeysObj {
    [x: string]: string | undefined;
  }

  export type GlobalTemplateConfigurations = {
    globalTemplateConfigurations: LegacyTemplate.GlobalTemplateConfigurationsObj[];
  };

  export interface GlobalTemplateConfigurationsObj {
    key:
      | 'PRODUCT_NAME'
      | 'PRODUCT_PRICE'
      | 'PRODUCT_DESCRIPTION'
      | 'PV_THUMBNAIL';
    value: string[];
  }
}

export type LegacyTemplate = {
  attributeGroups: LegacyTemplate.AttributeGroup[];
  createdAtTime: number;
  createdBy: string;
  taxonomies: LegacyTemplate.Taxonomy[];
  templateId: string;
  tenantId: string;
  updatedAtTime: number;
  updatedBy: string;
};

/** The different types of attributes that can be created */
export type AttributeTypes = 'text' | 'html' | 'assets' | 'richContent';
/** An attribute definition on the template level */
export type TemplateAttribute<T extends AttributeTypes> = {
  /** The internal name of the attribute to query an {@link AttributeValueMap} with */
  name: string;
  /** The type of the attribute */
  type: T;
  /** A human readable name to display */
  displayName: string;
  /** The name of the attribute in an external system */
  sourceKey: string;
  /** Signals if attribute can be used if e.g. product versions */
  customizable: boolean;
  /** An optional set of constraints on the content that can be used */
  validator?: TemplateValidator;
};
/** A set of constraints on the content that can be used */
export type TemplateValidator = {
  /** If there is a minimum amount of content required */
  min?: number;
  /** If there is a maximum amount of content allowed */
  max?: number;
  /** Limitations on what text can be entered */
  regex?: string;
};

/** A template defining the order, type, constraints, and details of a product's or set of products' attributes */
export type Template = {
  /** The internal ID of the template */
  id: string;
  /** The tenant ID */
  tenantId: string;
  /** The attribute definitions of the product the Template targets */
  attributes: TemplateAttribute<AttributeTypes>[];

  // Details on activity
  createdAt: number;
  createdBy: string;
  updatedAt: number;
  updatedBy: string;
};
