import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Checkbox } from '../../Checkbox/Checkbox';
import { RadioButton } from '../../RadioButton/RadioButton';
import { useTranslation } from 'react-i18next';
import {
  ListConfiguration,
  ListableActions,
  ListableState,
} from '@models/filter';
import { capitalizeEveryFirstLetter } from '@utils/textTransform';

import './FilterItem.scss';

/**
 * Component to display filter items in sidebar, like in products. Automatically renders a "show more" option if there are more than 9 values in a filter.
 *
 * @author Ritam Bandyopdahyay <ritam.bandyopadhyay@corevaluetech.com>
 * @component
 */
export const FilterItem = <T extends ListConfiguration>({
  filterNamespace,
  filter,
  listableState,
  listableAction,
  fixed,
}: {
  /** Used to get the translation of the values */
  filterNamespace: string;
  filter: DeepReadonly<T['filters'][number]>;
  listableState: DeepReadonly<ListableState<unknown, T>>;
  listableAction: ListableActions<T>;
  /** Whether the filter is fixed, i.e., cannot be modified by the user */
  fixed?: boolean;
}): React.ReactElement => {
  const { filters } = listableState;
  const [showMore, setShowMore] = useState<boolean>(true);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const filterName = t(
    `filters.${filterNamespace}.${filter.name}._title`,
  ).toUpperCase();
  const literalOptions = filter.literal;

  const currentlyActive = filters[filter.name as keyof typeof filters];

  const toOptionElement = (option: string) => {
    const isOptionActive = Array.isArray(currentlyActive)
      ? currentlyActive.includes(option)
      : currentlyActive === option;
    const name = literalOptions
      ? option
      : capitalizeEveryFirstLetter(
          t(`filters.${filterNamespace}.${filter.name}.${option}`),
        );

    const onChange = () => {
      if (filter.multiple) {
        const newOptions = [...(currentlyActive as string[])];
        if (isOptionActive) newOptions.splice(newOptions.indexOf(option), 1);
        else newOptions.push(option);
        dispatch(
          listableAction.updateFilter({
            filter: filter.name,
            value: newOptions as any,
          }),
        );
      }
      // Only change if not yet selected
      else if (!isOptionActive) {
        dispatch(
          listableAction.updateFilter({
            filter: filter.name,
            value: option as any,
          }),
        );
      }
    };

    return (
      <label className={`filter-item__label`} key={option}>
        {filter.multiple ? (
          <>
            <span>{name}</span>
            <Checkbox
              color="light"
              name={name}
              value={option}
              checked={isOptionActive}
              onChange={onChange}
              disabled={fixed}
            />
          </>
        ) : (
          <>
            <RadioButton
              name={name}
              value={option}
              checked={isOptionActive}
              onChange={onChange}
            />
            <span>{name}</span>
          </>
        )}
      </label>
    );
  };

  return (
    <details className="filter-item">
      <summary className="filter-item__summary">{filterName}</summary>
      <div>
        {filter.options
          .slice(0, showMore ? 9 : filter.options.length)
          .map(toOptionElement)}
      </div>

      {filter.options.length > 9 && (
        <p
          className="filter-item__show-more"
          onClick={() => setShowMore((prev) => !prev)}
        >
          {showMore ? t('global.showMore') : t('global.showLess')}
        </p>
      )}
    </details>
  );
};
