import { ActionButtonProps } from '@components/ConfirmationModal/ConfirmationModal';
import { capitalizeFirstLetter } from '@utils/textTransform';

import i18n from 'i18next';
import HTMLReactParser from 'html-react-parser';

import { ReactComponent as VisualContentIcon } from '@assets/icons/pictogram-author-content.svg';
import { ReactComponent as VisualAssetsIcon } from '@assets/icons/pictogram-visual-assets.svg';
import { ReactComponent as PubVersionIcon } from '@assets/icons/Group 7706.svg';

export const MODAL_METADATA = {
  CHECK_VERSION_MODAL: {
    modalId: 'CHECK_VERSION_MODAL_ID',
    title: 'Prescriptive product exists!',
    confirmationStatement:
      'The contextualized version already exists, do you still want to proceed?',
    actionButtons: [
      {
        text: 'No, cancel',
        color: 'gray',
        type: 'cancel',
      },
      {
        text: 'Yes, proceed',
        color: 'green',
        type: 'submit',
      },
    ] as ActionButtonProps[],
  },

  DISCARD_CHANGES_MODAL: {
    modalId: 'DISCARD_CHANGES_MODAL_ID',
    title: 'Discard changes?',
    confirmationStatement:
      'Canceling these will not save the changes which you have made, still you want to proceed?',
    actionButtons: [
      {
        text: 'Cancel',
        color: 'gray',
        type: 'cancel',
      },
      {
        text: 'Yes, discard',
        color: 'red',
        type: 'submit',
      },
    ] as ActionButtonProps[],
  },
  DISCARD_EDITOR_CHANGES_MODAL: {
    modalId: 'DISCARD_EDITOR_CHANGES_MODAL_ID',
    title: 'Are you sure you want to move to another page?',
    confirmationStatement:
      'Look like you have made changes on this page content. If you move away from this page without saving the changes, they will be discarded. Are you sure you want to move away from this page?',
    actionButtons: [
      {
        text: 'No',
        color: 'gray',
        type: 'cancel',
      },
      {
        text: 'Yes',
        color: 'red',
        type: 'submit',
      },
    ] as ActionButtonProps[],
  },

  VERSION_TURN_ON_OFF: {
    modalId: 'turnOffModalId',
    title: capitalizeFirstLetter(
      i18n.t('createContextualizedVersion.turn_all_versions', {
        value: i18n.t('global.this'),
      }),
    ),

    confirmationStatement: `${capitalizeFirstLetter(
      i18n.t('createContextualizedVersion.selected_version_will_be_turned_off'),
    )} ${capitalizeFirstLetter(
      i18n.t('createContextualizedVersion.turn_off_confirmation_line2', {
        value: i18n.t('global.it'),
      }),
    )}`,
    actionButtons: [
      {
        text: capitalizeFirstLetter(i18n.t('global.cancel')),
        color: 'gray',
        type: 'cancel',
      },
      {
        text: capitalizeFirstLetter(i18n.t('global.turn_off')),
        color: 'red',
        type: 'submit',
      },
    ] as ActionButtonProps[],
  },
  TOGGLE_WORKFLOW_MODAL_USER: {
    modalId: 'userWorkflowModalId',
    title: i18n.t('settings.do_you_want_to_activate_workflow'),
    confirmationStatement: `${i18n.t(
      'settings.activate_workflow_statement_1',
    )} ${i18n.t('settings.activate_workflow_statement_2', {
      text: '<b className="text-oxford-blue">settings > Workflow</b>',
    })}`,
    actionButtons: [
      {
        text: capitalizeFirstLetter(i18n.t('global.cancel')),
        color: 'gray',
        type: 'cancel',
      },
      {
        text: capitalizeFirstLetter(i18n.t('global.activate')),
        color: 'green',
        type: 'submit',
      },
    ] as ActionButtonProps[],
  },
  VISUAL_ASSET_APPROVED: {
    modalId: 'visualAssetApprovedId',
    icon: <VisualAssetsIcon />,
    title: i18n.t('version.for_approved', {
      for: capitalizeFirstLetter(i18n.t('version.visual_assets')),
    }),
    confirmationStatement: i18n.t('version.notify_statement'),
    actionButtons: [
      {
        text: capitalizeFirstLetter(i18n.t('version.continue_reviewing')),
        color: 'gray',
        type: 'cancel',
      },
      {
        text: capitalizeFirstLetter(i18n.t('global.done')),
        color: 'green',
        type: 'submit',
      },
    ] as ActionButtonProps[],
  },
  AUTHOR_CONTENT_APPROVED: {
    modalId: 'authorContentApproveId',
    icon: <VisualContentIcon />,
    title: i18n.t('version.for_approved', {
      for: capitalizeFirstLetter(i18n.t('version.author_content')),
    }),
    confirmationStatement: i18n.t('version.notify_statement'),
    actionButtons: [
      {
        text: capitalizeFirstLetter(i18n.t('version.continue_reviewing')),
        color: 'gray',
        type: 'cancel',
      },
      {
        text: capitalizeFirstLetter(i18n.t('global.done')),
        color: 'green',
        type: 'submit',
      },
    ] as ActionButtonProps[],
  },
  AUTHOR_CONTENT_FEEDBACK: {
    modalId: 'authorContentFeedbackId',
    icon: <VisualContentIcon />,
    title: i18n.t('version.feedback_button', {
      for: capitalizeFirstLetter(i18n.t('version.author_content')),
    }),
    confirmationStatement: i18n.t('version.enter_comment'),
    actionButtons: [
      {
        text: capitalizeFirstLetter(i18n.t('global.cancel')),
        color: 'gray',
        type: 'cancel',
      },
      {
        text: capitalizeFirstLetter(i18n.t('forms.submit')),
        color: 'green',
        type: 'submit',
      },
    ] as ActionButtonProps[],
  },
  VISUAL_ASSET_FEEDBACK: {
    modalId: 'visualAssetFeedbackId',
    icon: <VisualAssetsIcon />,
    title: i18n.t('version.feedback_button', {
      for: capitalizeFirstLetter(i18n.t('version.visual_assets')),
    }),
    confirmationStatement: i18n.t('version.enter_comment'),
    actionButtons: [
      {
        text: capitalizeFirstLetter(i18n.t('global.cancel')),
        color: 'gray',
        type: 'cancel',
      },
      {
        text: capitalizeFirstLetter(i18n.t('forms.submit')),
        color: 'green',
        type: 'submit',
      },
    ] as ActionButtonProps[],
  },

  PUBLISH_CONTENT: {
    modalId: 'publishModalId',
    icon: <PubVersionIcon />,
    title: i18n.t('version.for_published', {
      for: i18n.t('global.version'),
    }),
    confirmationStatement: i18n.t('version.notify_statement'),
    actionButtons: [
      {
        text: capitalizeFirstLetter(i18n.t('global.go_to_dashboard')),
        color: 'green',
        type: 'submit',
      },
    ] as ActionButtonProps[],
  },
  SAME_ASSIGNEES_MODAL: {
    modalId: 'sameAssgineeConfirmationId',
    title: 'Workflow updated',
    confirmationStatement:
      'Since all the tasks are assigned to same user, workflow will be disabled for this version creation process. You can always enable it back and assign tasks to different members.',
    actionButtons: [
      {
        text: 'No, cancel',
        color: 'gray',
        type: 'cancel',
      },
      {
        text: 'Yes, proceed',
        color: 'green',
        type: 'submit',
      },
    ] as ActionButtonProps[],
  },
  WORKFLOW_ON_CONFIRMATION: {
    modalId: 'workflowConfirmationId',
    title: 'Workflow Engine On?',
    confirmationStatement: HTMLReactParser(
      `<p>Turning “Workflow On” will let you create Product version with the help of your team members. This is really useful when you work with team and want other members to work on the product version along with you.</p>
      <p>Workflow lets you allocate team members specific tasks which they can work on. Once task are completed, you can review the information provided by them and can publish it if all looks good to you. Incase you want modifications in the provided information, you can allocate product version back to them or can update it on your own. </p>`,
    ),
    actionButtons: [
      {
        text: capitalizeFirstLetter(i18n.t('global.cancel')),
        color: 'gray',
        type: 'cancel',
      },
      {
        text: capitalizeFirstLetter(i18n.t('global.turn_on')),
        color: 'green',
        type: 'submit',
      },
    ] as ActionButtonProps[],
  },
  WORKFLOW_OFF_CONFIRMATION: {
    modalId: 'workflowConfirmationId',
    title: 'Turn Workflow Engine Off?',
    confirmationStatement: HTMLReactParser(
      `<p>You are about to turn-off the workflow engine. Incase you have allocated tasks to team member for any product version, the team member will be removed from the task and all the product version will be allocated to the user who had created them.</p>
      <p>Also incase you turn on workflow engine later in future, you will have to individually re-allocate the tasks to team member, system will not remember the current allocation. Do you want to continue?</p>`,
    ),
    actionButtons: [
      {
        text: capitalizeFirstLetter(i18n.t('global.cancel')),
        color: 'gray',
        type: 'cancel',
      },
      {
        text: capitalizeFirstLetter(i18n.t('global.turn_off')),
        color: 'red',
        type: 'submit',
      },
    ] as ActionButtonProps[],
  },
};
