import { CreateButton } from '../CreateButton/CreateButton';

import './AddButtonWithSeparator.scss';

export const AddButtonWithSeparator: React.FC<{
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
}> = ({ onClick, className = '' }) => (
  <div className={`AddButtonWithSeparator ${className}`}>
    <CreateButton onClick={onClick} />
  </div>
);
