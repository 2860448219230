import { useEffect, useState } from 'react';

import { AssetSelector } from '@components/AssetSelector/AssetSelector';

import { Attribute, IPermission } from '@models/rich-content';
import { AttributeEditors } from '@components/ContentEditor/AttributeField/AttributeField';

export const SectionFieldsFactory: React.FC<{
  id: string;
  attribute: Attribute;
  attributeKey: string;
  handleChange: Function;
  characterLimits: number;
  permissions: IPermission;
  sectionOrder: number;
  className?: string;
}> = ({
  id,
  attribute,
  attributeKey,
  handleChange,
  characterLimits,
  permissions,
  sectionOrder,
}) => {
  const [templatePermission, setTemplatePermission] = useState({
    authorContentPermission: false,
    visualAssetsPermission: false,
  });

  useEffect(() => {
    setTemplatePermission({
      authorContentPermission: permissions.AUTHOR_CONTENT,
      visualAssetsPermission: permissions.VISUAL_ASSETS,
    });
  }, [permissions]);

  const ContentFieldsMap: { [key: string]: JSX.Element } = {
    TextInput: AttributeEditors.html(
      attribute.standardText,
      (value) => handleChange(value, attributeKey, sectionOrder),
      false,
    ),
    ImageUrl: (
      <AssetSelector
        type="image"
        enabled={templatePermission.visualAssetsPermission}
        assetSrc={attribute.standardText}
        maxImages={1}
        handleChange={(value: string, id: string) =>
          templatePermission.visualAssetsPermission &&
          handleChange(value, attributeKey, sectionOrder, true, [id])
        }
      />
    ),
    VideoUrl: (
      <AssetSelector
        type="video"
        enabled={templatePermission.visualAssetsPermission}
        assetSrc={attribute.standardText}
        handleChange={(value: string, id: string) =>
          templatePermission.visualAssetsPermission &&
          handleChange(value, attributeKey, sectionOrder, true, [id])
        }
      />
    ),
  };
  return ContentFieldsMap[attribute['attributeType']] || null;
};
