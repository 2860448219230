import { CreateIcon } from '@components/Icons/CreateIcon';

import './CreateButton.scss';

export const CreateButton: React.FC<{
  isDisabled?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}> = ({ isDisabled, onClick }) => (
  <button
    className={`CreateButton ${isDisabled ? 'disabled' : ''}`}
    onClick={onClick}
  >
    <CreateIcon />
  </button>
);
