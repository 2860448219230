import { IPermission, ITemplate } from '@models/rich-content';
import { sections } from '../../+page/defaultData';

import RichContentHTML from '../../+page/RichContentHTML';
import RichContentURL from '../../+page/RichContentURL';
import RichContentHighlights from '../../+page/RichContentHighlights';
import RichContentImageGrid from '../../+page/RichContentImageGrid';
import RichContentImageSection from '../../+page/RichContentImageSection';
import RichContentImageTextUploader from '../../+page/RichContentImageTextUploader';
import RichContentTestimonials from '../../+page/RichContentTestimonials';
import RichContentThreeReview from '../../+page/RichContentThreeReview';
import RichContentVideoUploader from '../../+page/RichContentVideoUploader';
import RichContentVideoUploaderWithTitle from '../../+page/RichContentVideoUploaderWithTitle';
import RichContentVideoUploaderWithTitleAndDesc from '../../+page/RichContentVideoUploaderWithTitleAndDesc';

const SectionsMap: {
  [key in keyof typeof sections]: React.NamedExoticComponent<any>;
} = {
  html: RichContentHTML,
  url: RichContentURL,
  imageText: RichContentImageTextUploader,
  textImage: RichContentImageTextUploader,
  testimonials: RichContentTestimonials,
  video: RichContentVideoUploader,
  videoWithTitle: RichContentVideoUploaderWithTitle,
  videoWithTitleAndDesc: RichContentVideoUploaderWithTitleAndDesc,
  threeReviews: RichContentThreeReview,
  image: RichContentImageSection,
  highlights: RichContentHighlights,
  imageGrid: RichContentImageGrid,
};

export const SectionFactory: React.FC<{
  permissions: IPermission;
  sectionOrder: number;
  section: ITemplate;
  totalSections: number;
  moveSection: Function;
  handleChange: Function;
}> = ({
  permissions,
  sectionOrder,
  section,
  totalSections,
  moveSection,
  handleChange,
}) => {
  const Section = SectionsMap[section.sectionName];
  return Section ? (
    <Section
      index={sectionOrder}
      length={totalSections}
      moveSection={moveSection}
      permissions={permissions}
      attributes={section.attributes}
      handleChange={handleChange}
      {...(section.sectionName === 'imageText' && { alignment: 'right' })}
    />
  ) : null;
};
