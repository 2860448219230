import { Mixpanel } from './mixpanel';

const removeActiveClassIfPresent = (list: any) => {
  if (list) {
    for (const li of list)
      if (li.classList.contains('item-active')) {
        li.classList.remove('item-active');
        break;
      }
  }
};
const addClass = (node: HTMLElement) => node.classList.add('item-active');

const removeClass = (node: HTMLElement) => node.classList.remove('item-active');

const isActiveClassPresent = (node: HTMLElement) =>
  node.classList.contains('item-active');

const updateClassAndIndex = (index: number, shouldIncrease: boolean) => {
  const itemIndex = shouldIncrease ? index + 1 : index - 1;
  const newElement = document.getElementById(`listItem-${itemIndex}`);
  const currentElement = document.getElementById(`listItem-${index}`);

  if (currentElement) {
    if (isActiveClassPresent(currentElement)) {
      if (newElement) {
        removeClass(currentElement);
        index = itemIndex;
        addClass(newElement);
      } else return index;
    } else addClass(currentElement);
  }
  return index;
};

export const handleUpDownEnterKeyEvent = (
  e: React.KeyboardEvent<HTMLElement>,
  enterkeyHandler: Function,
  dropdownId = '',
  index: number,
  query: string,
): number => {
  switch (e.keyCode) {
    case 13:
      {
        const currentElement = document.getElementById(`listItem-${index}`);
        if (currentElement) {
          enterkeyHandler(
            document.getElementById(`listItem-${index}`)?.innerText,
          );
          Mixpanel.track('search', {
            eventName: 'search_query',
            kpi: 'search',
            propertyType: 'event_property',
            search: document.getElementById(`listItem-${index}`)?.innerText,
          });
        } else {
          enterkeyHandler(query);
          Mixpanel.track('search', {
            eventName: 'search_query',
            kpi: 'search',
            propertyType: 'event_property',
            search: query,
          });
        }
      }
      break;

    case 38:
      e.preventDefault();
      index = updateClassAndIndex(index, false);
      break;

    case 40:
      e.preventDefault();
      index = updateClassAndIndex(index, true);
      break;
    default:
      removeActiveClassIfPresent(document.getElementById(dropdownId)?.children);
      break;
  }
  return index;
};
