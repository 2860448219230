import axios from 'axios';
import fileDownload from 'js-file-download';

import { DigitalAssetBrief } from '@models/digital-asset';

export const downloadAsset = (
  assetUrl: string,
  assetName?: string,
  assetType = 'png',
): void => {
  axios
    .get(assetUrl, {
      responseType: 'blob',
    })
    .then((res) => {
      fileDownload(
        res.data,
        `${assetName ? assetName : 'asset'}${assetName ? '' : `.${assetType}`}`,
      );
    });
};

export const isAssetImage = (
  assetDetails: DeepReadonly<DigitalAssetBrief>,
): boolean => {
  const assetTypes = ['png', 'jpg', 'jpeg', 'gif', 'svg'];
  return assetTypes.includes(assetDetails.format);
};

export const isAssetSVG = (
  assetDetails: DeepReadonly<DigitalAssetBrief>,
): boolean => {
  return assetDetails.format === 'svg';
};

export const isAssetGIF = (
  assetDetails: DeepReadonly<DigitalAssetBrief>,
): boolean => {
  return assetDetails.format === 'gif';
};

export const isAssetVideo = (
  assetDetails: DeepReadonly<DigitalAssetBrief> | null,
): boolean => {
  if (!assetDetails) return false;
  return (
    assetDetails.format === 'mp4' ||
    assetDetails.format === 'mvp' ||
    assetDetails.format === 'mov'
  );
};
