import { gql, TypedDocumentNode } from '@apollo/client';
import { NoVariables, StartEndDates } from '.';

/** @returns the analytics metadata for a tenant such as it's currency and default date range */
export const TENANT_ANALYTICS_METADATA: TypedDocumentNode<
  {
    metadata: {
      currency: string;
      onboardedOn: string;
      storeUrl: string;
      defaultRange: string;
    };
  },
  NoVariables
> = gql`
  query tenant_analytics_metadata {
    metadata {
      currency
      onboardedOn
      storeUrl
      defaultRange
    }
  }
`;

/** @returns information around data re-aggregation requests */
export const DATA_REFRESH_TIMES: TypedDocumentNode<
  {
    metadata?: { batch?: { cooldown_period?: number; refreshed_at?: number } };
  },
  NoVariables
> = gql`
  query data_refresh_times {
    metadata {
      batch {
        cooldown_period
        refreshed_at
      }
    }
  }
`;

/** @returns the response time performance of Heuristics RACE API for the selected range */
export const RACE_DELIVERY_SPEED: TypedDocumentNode<
  {
    system: { delivery_speed: { range: string; deliveries: number }[] };
  },
  StartEndDates
> = gql`
  query race_delivery_speed($start: String!, $end: String!) {
    system(start: $start, end: $end) {
      delivery_speed {
        range
        deliveries
      }
    }
  }
`;

/** @returns the default analytics period to select */
export const TENANT_DEFAULT_ANALYTICS_PERIOD: TypedDocumentNode<
  {
    insight: { data_period: StartEndDates };
  },
  NoVariables
> = gql`
  query tenant_default_analytics_period {
    insight {
      data_period {
        start
        end
      }
    }
  }
`;

/** @returns grouping of each context delivered and the amount of deliveries to that context */
export const CONTEXT_DELIVERY_OVERVIEW: TypedDocumentNode<
  {
    context: { aggregate: { name: string; visits: number }[] | null };
  },
  StartEndDates
> = gql`
  query context_delivery_overview($start: String!, $end: String!) {
    context(start: $start, end: $end) {
      aggregate {
        name
        visits
      }
    }
  }
`;

/** @returns The video URL of the video to feature in the spotlight section */
export const DASHBOARD_SPOTLIGHT_VIDEO: TypedDocumentNode<
  {
    metadata: { spotlight: { video: string } };
  },
  NoVariables
> = gql`
  query dashboard_spotlight_video {
    metadata {
      spotlight {
        video
      }
    }
  }
`;

/** @returns The section of the dashboard that can be viewed by this tenant */
export const DASHBOARD_VIEWABLE_SECTIONS: TypedDocumentNode<
  {
    metadata: {
      dashboard: {
        product: {
          product: boolean;
          sessions: boolean;
        };
        conversion: {
          cart: boolean;
          conversion: boolean;
        };
        rate: {
          cart: boolean;
          bounceRate: boolean;
          conversion: boolean;
        };
        performance: {
          cart: boolean;
          bounceRate: boolean;
          experienceScore: boolean;
          conversion: boolean;
        };
        system: {
          deliverySpeed: boolean;
          uptime: boolean;
        };
        delivery: {
          context: boolean;
          contextualization: boolean;
        };
        blog: {
          blogs: boolean;
          spotlight: boolean;
        };
      };
    };
  },
  NoVariables
> = gql`
  query dashboard_viewable_sections {
    metadata {
      dashboard {
        product {
          product
          sessions
        }
        conversion {
          cart
          conversion
        }
        rate {
          cart
          bounceRate
          conversion
        }
        performance {
          cart
          bounceRate
          experienceScore
          conversion
        }
        system {
          deliverySpeed
          uptime
        }
        delivery {
          context
          contextualization
        }
        blog {
          blogs
          spotlight
        }
      }
    }
  }
`;
