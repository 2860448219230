import i18n from 'i18next';
import { ITemplateAttribute } from '@models/rich-content';
import { colors } from '@constants/colors';

import { ReactComponent as HtmlIllustration } from '@assets/icons/HTMLIcon.svg';
import { UrlIcon } from '@components/Icons/UrlIcon';
import { ReactComponent as AssetIllustration } from '@assets/illustrations/rich-content-template/asset.svg';
import { ReactComponent as ReviewIllustration } from '@assets/illustrations/rich-content-template/review.svg';
import { ReactComponent as TextIllustration } from '@assets/illustrations/rich-content-template/text.svg';
import { ReactComponent as QuoteIllustration } from '@assets/illustrations/rich-content-template/quote.svg';
import { ReactComponent as SectionIllustration } from '@assets/illustrations/rich-content-template/section.svg';
import { ReactComponent as VideoIllustration } from '@assets/illustrations/rich-content-template/video.svg';

const reviewDefaultValues = (index: string) => {
  return {
    [`name${index}`]: {
      label: i18n.t('taskMaster.customer_name'),
      attributeType: 'TextInput',
      standardText: '',
      enrichedText: [],
    },
    [`image${index}`]: {
      label: i18n.t('global.image'),
      attributeType: 'ImageUrl',
      standardText: '',
      enrichedText: [],
    },
    [`review${index}`]: {
      label: i18n.t('rich_content.testimonial_content'),
      attributeType: 'TextInput',
      standardText: '',
      enrichedText: [],
    },
  };
};

const highlightDefaultValues = (index: string) => {
  return {
    [`highlight${index}`]: {
      label: i18n.t('global.title'),
      attributeType: 'TextInput',
      standardText: '',
      enrichedText: [],
    },
    [`image${index}`]: {
      label: i18n.t('global.image'),
      attributeType: 'ImageUrl',
      standardText: '',
      enrichedText: [],
    },
    [`description${index}`]: {
      label: i18n.t('taskMaster.highlights'),
      attributeType: 'TextInput',
      standardText: '',
      enrichedText: [],
    },
  };
};

const defaultValues: ITemplateAttribute = {
  heading: {
    label: i18n.t('global.heading'),
    attributeType: 'TextInput',
    standardText: '',
    enrichedText: [],
  },
  description: {
    label: i18n.t('context.description'),
    attributeType: 'TextInput',
    standardText: '',
    enrichedText: [],
  },
  image: {
    label: i18n.t('global.image'),
    attributeType: 'ImageUrl',
    standardText: '',
    enrichedText: [],
    assetIds: [],
  },
  callActionBtnText: {
    label: i18n.t('rich_content.button_text'),
    attributeType: 'TextInput',
    standardText: '',
    enrichedText: [],
  },
  callActionBtnURL: {
    label: i18n.t('global.url'),
    attributeType: 'TextInput',
    standardText: '',
    enrichedText: [],
  },
};

const IconGrid = ({
  children,
  columns,
}: {
  children: React.ReactNode;
  columns: number;
}) => {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
        gap: '10%',
      }}
    >
      {children}
    </div>
  );
};

export const sections = {
  html: {
    order: 0,
    rawContent: '24345082',
    attributes: {
      html: {
        label: i18n.t('global.html'),
        attributeType: 'TextInput',
        standardText: '',
        enrichedText: [],
      },
    },
    preview: {
      textKey: 'global.html',
      icon: <HtmlIllustration fill={colors.emerald} />,
    },
  },
  url: {
    order: 0,
    rawContent: '24345082',
    attributes: {
      url: {
        label: i18n.t('global.url'),
        attributeType: 'TextInput',
        standardText: '',
        enrichedText: [],
      },
    },
    preview: {
      textKey: 'global.url',
      icon: <UrlIcon fill={colors.emerald} />,
    },
  },
  imageText: {
    order: 0,
    rawContent: '24345082',
    attributes: { ...defaultValues },
    preview: {
      textKey: 'rich_content.image_and_text',
      icon: (
        <IconGrid columns={2}>
          <AssetIllustration />
          <TextIllustration />
        </IconGrid>
      ),
    },
  },
  textImage: {
    order: 0,
    rawContent: '24345082',
    attributes: { ...defaultValues },
    preview: {
      textKey: 'rich_content.text_and_image',
      icon: (
        <IconGrid columns={2}>
          <TextIllustration />
          <AssetIllustration />
        </IconGrid>
      ),
    },
  },
  testimonials: {
    order: 0,
    rawContent: '24345082',
    attributes: {
      testimonialText: {
        label: i18n.t('global.content'),
        attributeType: 'TextInput',
        standardText: '',
        enrichedText: [],
      },
      testimonialAuthor: {
        label: i18n.t('global.attribution'),
        attributeType: 'TextInput',
        standardText: '',
        enrichedText: [],
      },
    },
    preview: {
      textKey: 'rich_content.testimonials',
      icon: <QuoteIllustration />,
    },
  },
  video: {
    order: 0,
    rawContent: '24345082',
    attributes: {
      video: {
        label: i18n.t('global.video'),
        attributeType: 'VideoUrl',
        standardText: '',
        enrichedText: [],
        assetIds: [],
      },
    },
    preview: {
      textKey: 'global.video',
      icon: <VideoIllustration />,
    },
  },
  videoWithTitle: {
    order: 0,
    rawContent: '24345082',
    attributes: {
      video: {
        label: i18n.t('global.videoWithTitle'),
        attributeType: 'VideoUrl',
        standardText: '',
        enrichedText: [],
        assetIds: [],
      },
      heading: {
        label: i18n.t('global.heading'),
        attributeType: 'TextInput',
        standardText: '',
        enrichedText: [],
        assetIds: [],
      },
    },
    preview: {
      textKey: 'global.videoWithTitle',
      icon: <VideoIllustration />,
    },
  },
  videoWithTitleAndDesc: {
    order: 0,
    rawContent: '24345082',
    attributes: {
      video: {
        label: i18n.t('global.videoWithTitleAndDesc'),
        attributeType: 'VideoUrl',
        standardText: '',
        enrichedText: [],
        assetIds: [],
      },
      heading: {
        label: i18n.t('global.heading'),
        attributeType: 'TextInput',
        standardText: '',
        enrichedText: [],
        assetIds: [],
      },
      description: {
        label: i18n.t('global.sub_heading'),
        attributeType: 'TextInput',
        standardText: '',
        enrichedText: [],
        assetIds: [],
      },
    },
    preview: {
      textKey: 'global.videoWithTitleAndDesc',
      icon: <VideoIllustration />,
    },
  },
  image: {
    order: 0,
    rawContent: '24345082',
    attributes: {
      heading: { ...defaultValues['heading'] },
      subHeading: {
        label: i18n.t('global.sub_heading'),
        attributeType: 'TextInput',
        standardText: '',
        enrichedText: [],
      },
      notes: {
        label: i18n.t('global.notes'),
        attributeType: 'TextInput',
        standardText: '',
        enrichedText: [],
      },
      image: { ...defaultValues['image'] },
    },
    preview: {
      textKey: 'rich_content.image_section',
      icon: <SectionIllustration />,
    },
  },
  threeReviews: {
    order: 0,
    rawContent: '24345082',
    attributes: {
      title: {
        label: i18n.t('global.title'),
        attributeType: 'TextInput',
        standardText: '',
        enrichedText: [],
      },
      ...reviewDefaultValues('1'),
      ...reviewDefaultValues('2'),
      ...reviewDefaultValues('3'),
    },
    preview: {
      textKey: 'rich_content.reviews',
      icon: (
        <IconGrid columns={3}>
          {Array(3)
            .fill(0)
            .map((_, i) => (
              <ReviewIllustration key={i} />
            ))}
        </IconGrid>
      ),
    },
  },
  highlights: {
    order: 0,
    rawContent: '24345082',
    attributes: {
      ...highlightDefaultValues('1'),
      ...highlightDefaultValues('2'),
      ...highlightDefaultValues('3'),
    },
    preview: {
      textKey: 'taskMaster.highlights',
      icon: (
        <IconGrid columns={3}>
          {Array(3)
            .fill(0)
            .map((_, i) => (
              <ReviewIllustration key={i} />
            ))}
        </IconGrid>
      ),
    },
  },
  imageGrid: {
    order: 0,
    rawContent: '24345082',
    attributes: {
      heading: defaultValues['heading'],
      img1: defaultValues['image'],
      img2: defaultValues['image'],
      img3: defaultValues['image'],
      img4: defaultValues['image'],
      img5: defaultValues['image'],
      img6: defaultValues['image'],
    },
    preview: {
      textKey: 'rich_content.image_grid',
      icon: (
        <IconGrid columns={3}>
          {Array(6)
            .fill(0)
            .map((_, i) => (
              <AssetIllustration key={i} />
            ))}
        </IconGrid>
      ),
    },
  },
} as const satisfies {
  [sectionName: string]: {
    order: number;
    rawContent: string;
    attributes: { [key: string]: ITemplateAttribute[string] };
    /** Any data to use to preview the section when selecting a section to add */
    preview: {
      /** The key to use in `t(key)` as a name for the section */
      textKey: string;
      /** The SVG icon to use */
      icon: JSX.Element;
    };
  };
};
