/**
 *
 * @author Rishabh Jain
 * @param user User data from keycloak
 * @param permissionName the page to check permissions for
 * @returns if the given user can access the given page
 */

export const checkPermission = (user: any, permissionName: string): boolean => {
  return (
    user.permissions?.findIndex(
      (permission: any) => permission.name === permissionName,
    ) !== -1
  );
};
