import './MultiToggle.scss';

export const MultiToggle = ({
  options,
  selected,
  selectedColor,
  setSelected,
}: {
  options: string[];
  selected: string;
  selectedColor: string;
  setSelected: (newSelected: string) => unknown;
}) => (
  <span className="multi-toggle-option">
    {options.map((option) => {
      const active = selected === option;

      return (
        <label
          className={'multi-toggle-option-item' + (active ? ' active' : '')}
          htmlFor={'multi-toggle-option-' + option}
          style={active ? { backgroundColor: selectedColor } : {}}
          key={option}
        >
          {option}
          <input
            type="radio"
            id={'multi-toggle-option-' + option}
            name={'multi-toggle-options'}
            value={option}
            checked={active}
            onChange={() => setSelected(option)}
          ></input>
        </label>
      );
    })}
  </span>
);
