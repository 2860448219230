import { ENDPOINTS } from '@constants/endpoints';
import { clientWithToken } from '.';
import store from '@redux/store';
import { IRichContent } from '@models/rich-content';
import { ensureError, isError } from '@utils/ImproperError';

export const fetchRichContent = async (payload: {
  pageNumber?: number;
  pageSize?: number;
  searchText?: string;
  sortBy?: 'UPDATED_AT';
  sortOrder?: 'DESC';
  workFlowStatus?: 'LIVE'[];
  productId?: string;
  ids?: string[];
}): Promise<
  { items: IRichContent[]; pageNumber: number; totalItems: number } | Error
> => {
  const response = await clientWithToken
    .post<{
      body: { items: IRichContent[]; pageNumber: number; totalItems: number };
    }>(ENDPOINTS.richContent.fetchAll, payload)
    .catch(ensureError);
  return isError(response) ? response : response.data.body;
};

export const feedbackOnRichContentTask = async (payload: {
  contentId: string;
  taskId: string;
}): Promise<unknown | Error> => {
  const response = await clientWithToken
    .post<{ body: unknown }>(
      `${ENDPOINTS.richContent.task}/${payload.contentId}/task-feedback/${payload.taskId}`,
      store.getState().richContent.feedbackContent,
    )
    .catch(ensureError);
  return isError(response) ? response : response.data.body;
};

export const patchSelectedRichContentData = async (payload: {
  richContentId: string;
  data: unknown;
  updateAttributes: string;
}): Promise<IRichContent | Error> => {
  const response = await clientWithToken
    .patch<{ body: IRichContent }>(
      `${ENDPOINTS.richContent.base}/enrich/${payload.richContentId}?taskType=${payload.updateAttributes}`,
      payload.data,
    )
    .catch(ensureError);
  return isError(response) ? response : response.data.body;
};

export const updateRichContent = async (
  payload: any,
): Promise<IRichContent | Error> => {
  const result = await clientWithToken
    .put<{ body: IRichContent }>(
      `${ENDPOINTS.richContent.base}/enrich`,
      payload,
    )
    .catch(ensureError);
  return isError(result) ? result : result.data.body;
};

export const createRichContent = async (
  payload: any,
): Promise<IRichContent | Error> => {
  const result = await clientWithToken
    .post<{ body: IRichContent }>(
      `${ENDPOINTS.richContent.base}/enrich`,
      payload,
    )
    .catch(ensureError);
  return isError(result) ? result : result.data.body;
};

export const createRichContentTask = async (
  richContentId: string,
  payload: any,
): Promise<unknown | Error> => {
  return clientWithToken
    .post<unknown>(
      `${ENDPOINTS.richContent.task}/${richContentId}/tasks`,
      payload,
    )
    .catch(ensureError);
};

export const updateRichContentTask = async (
  richContentId: string,
  payload: any,
): Promise<
  { body: 'Assignees are updated successfully.'; errors: string[] } | Error
> => {
  const response = await clientWithToken
    .put<{
      body: 'Assignees are updated successfully.';
      errors: string[];
    }>(
      `${ENDPOINTS.richContent.task}/${richContentId}/update-assignee`,
      payload,
    )
    .catch(ensureError);
  return isError(response) ? response : response.data;
};

export const moveRichContentTaskForReview = async (
  richContentId: string,
  taskId: string,
): Promise<unknown | Error> => {
  const response = await clientWithToken
    .patch<unknown>(
      `${ENDPOINTS.richContent.task}/${richContentId}/task-status/${taskId}`,
    )
    .catch(ensureError);
  return isError(response) ? response : response.data;
};

/** @important Only for workflow off */
export const moveRichContentTaskToComplete = async (
  richContentId: string,
): Promise<unknown | Error> => {
  const response = await clientWithToken
    .patch<unknown>(`${ENDPOINTS.richContent.submitTasks}/${richContentId}`)
    .catch(ensureError);
  return isError(response) ? response : response.data;
};

export const approveRichContentTask = async (
  richContentId: string,
  taskId: string,
): Promise<unknown | Error> => {
  const response = await clientWithToken
    .patch<{ body: unknown }>(
      `${ENDPOINTS.richContent.task}/${richContentId}/approve/${taskId}`,
    )
    .catch(ensureError);
  return isError(response) ? response : response.data.body;
};

export const startRichContentReview = async (
  richContentId: string,
  taskId: string,
): Promise<unknown | Error> => {
  const response = await clientWithToken.patch<unknown>(
    `${ENDPOINTS.richContent.task}/${richContentId}/start-review/${taskId}`,
  );
  return isError(response) ? response : response.data;
};

export const publishRichContent = async (
  richContentId: string,
): Promise<unknown | Error> => {
  const response = await clientWithToken
    .patch<{ body: unknown }>(
      `${ENDPOINTS.richContent.publish}/${richContentId}`,
      {},
    )
    .catch(ensureError);
  return isError(response) ? response : response.data.body;
};

export const deleteRichContents = async (
  richContentIds: string[],
): Promise<unknown | Error> => {
  const response = await clientWithToken.delete<unknown>(
    `${ENDPOINTS.richContent.enrich}/${richContentIds.join(',')}`,
    {},
  );
  return isError(response) ? response : response.data;
};

export const removeRichContentUsage = async (
  richContentId: string,
  prescriptiveId: string,
): Promise<{ message: string } | Error> => {
  const searchParams = new URLSearchParams({
    [ENDPOINTS.dam.queryParams.consumerId]: prescriptiveId,
    [ENDPOINTS.dam.queryParams.contentConsumer]: 'CONTEXTUALISED_VERSION',
  });
  const response = await clientWithToken.patch<{ body: { message: string } }>(
    `${ENDPOINTS.richContent.removeUsage}/${richContentId}?${searchParams}`,
    {},
  );
  return isError(response) ? response : response.data.body;
};
