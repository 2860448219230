import './ProgressBar.scss';

export const ProgressBar: React.FC<{ value: number }> = ({ value }) => (
  <div className="ProgressBar__progressBar">
    <div
      className="ProgressBar__progressBar--percentage"
      // Using inline styles as it is a dynamic value
      style={{ width: `${Math.round(value)}%` }}
    />
  </div>
);
