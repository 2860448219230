import { Experiment } from '@models/experiment';
import {
  ListablePayload,
  ListableState,
  ListConfiguration,
} from '@models/filter';
import { clientWithToken, PAGE_SIZE } from '.';

export namespace ExperimentApi {
  export namespace List {
    export type State = ListableState<Experiment, typeof experimentListConfig>;
    export type Query = ListablePayload<typeof experimentListConfig>;
  }
}

export const experimentListConfig = {
  sort: [
    { sortBy: 'name', sortOrder: 'asc' },
    { sortBy: 'name', sortOrder: 'desc' },
    { sortBy: 'lastUsedAt', sortOrder: 'desc' },
    { sortBy: 'lastUsedAt', sortOrder: 'asc' },
    { sortBy: 'updatedAt', sortOrder: 'desc' },
    { sortBy: 'updatedAt', sortOrder: 'asc' },
    { sortBy: 'createdAt', sortOrder: 'desc' },
    { sortBy: 'createdAt', sortOrder: 'asc' },
  ],
  filters: [],
} as const satisfies ListConfiguration;

export const getExperiment = async (
  experimentId: string,
): Promise<Experiment> =>
  clientWithToken
    .get<Experiment>(`/experiments/${experimentId}`)
    .then((r) => r.data);

export const listExperiments = async ({
  page,
  searchText,
  sortOrder,
  sortBy,
  filters,
}: ExperimentApi.List.Query): Promise<{
  items: Experiment[];
  totalCount: number;
}> => {
  const query = new URLSearchParams({
    page: page + '',
    size: PAGE_SIZE + '',
  });
  if (searchText) query.append('query', searchText);
  query.append('sortOrder', sortOrder);
  query.append('sortBy', sortBy);
  for (const filter in filters) {
    const value = filters[filter as keyof typeof filters];
    if (value) {
      if (typeof value === 'string') query.append(filter, value);
      // TODO else for (const val of value) query.append(filter, val);
    }
  }

  const result = await clientWithToken.get<Experiment[]>(
    `/experiments?${query}`,
  );
  return {
    totalCount: Number(result.headers['total-item-count']),
    items: result.data,
  };
};

export const createExperiment = async (
  experiment: Experiment.PostBody,
): Promise<Experiment> =>
  clientWithToken
    .post<Experiment>('/experiments', experiment)
    .then((r) => r.data);

export const deleteExperiment = async (id: string): Promise<Experiment> =>
  clientWithToken.delete<Experiment>('/experiments/' + id).then((r) => r.data);

export const tagVersionToExperiment = (
  experimentId: string,
  version: Experiment['versions'][number],
) =>
  clientWithToken
    .post<Experiment>(`/experiments/${experimentId}/versions`, version)
    .then((r) => r.data);

export const removeVersionToExperiment = (
  experimentId: string,
  version: Experiment['versions'][number],
) =>
  clientWithToken
    .delete<Experiment>(
      `/experiments/${experimentId}/versions?${new URLSearchParams(version)}`,
    )
    .then((r) => r.data);

export const updateVersionExperimentTags = ({
  experimentIds,
  prevExperimentIds,
  ...versionTag
}: Experiment['versions'][number] & {
  experimentIds: string[];
  prevExperimentIds: string[];
}) => {
  const promises: Promise<Experiment>[] = [];
  for (const id of prevExperimentIds) {
    if (!experimentIds.includes(id))
      promises.push(removeVersionToExperiment(id, versionTag));
  }
  for (const id of experimentIds) {
    if (!prevExperimentIds.includes(id))
      promises.push(tagVersionToExperiment(id, versionTag));
  }
  return Promise.all(promises);
};
