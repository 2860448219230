import { OutlinedPlayIcon } from '../Icons/OutlinedPlayIcon';

import './VideoOverlay.scss';

/** Adds overlay over asset to indicate that media is video type */
export const VideoOverlay: React.FC = ({ children }) => (
  <div className="VideoOverlay">
    {children}
    <div className="VideoOverlay__overlay">
      <OutlinedPlayIcon className="VideoOverlay_playlogo" />
    </div>
  </div>
);
