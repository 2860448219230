import { rootReducer } from './slices';
import { ThunkAction, UnknownAction, configureStore } from '@reduxjs/toolkit';
import { DefaultRootState } from 'react-redux';
import { SideEffectsMiddleware } from './sideEffects';

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  DefaultRootState,
  void,
  UnknownAction
>;

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // TODO this would probably not hurt
      serializableCheck: false,
    }).concat(SideEffectsMiddleware),
});

export type AppDispatch = typeof store.dispatch;

export default store;
