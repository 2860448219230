import moment from 'moment';
import i18n from 'i18next';
import { DATE_FORMAT } from '@constants/dateFormats';

/**
 *
 * @author Jappreet Singh, Ritam Bandyopadhyay <ritam.bandyopadhyay@corevaluetech.com>
 * @param {Date | string} date date to be formatted
 * @param {string} format desired date format, format is a string of numbers contained in `dateFormats` file
 * @returns {string} formatted date
 */
export const formatDate = (
  date: Date | string | number,
  format = 'format1',
): string => {
  let formattedDate = date;
  switch (format) {
    case 'format1':
      formattedDate = moment(date).format(DATE_FORMAT['format1']);
      break;
    case 'format2':
      formattedDate = moment(date).format(DATE_FORMAT['format2']);
      break;
    case 'dateTime':
      formattedDate = moment(date).format(DATE_FORMAT['dateTime']);
      break;
    case 'isoFormat':
      formattedDate = moment(date).format(DATE_FORMAT['isoFormat']);
      break;
    case 'isoFormat2':
      formattedDate = moment(date).format(DATE_FORMAT['isoFormat2']);
      break;
    default:
      formattedDate = moment(date).format(DATE_FORMAT['format1']);
      break;
  }
  return formattedDate.toString();
};

/**
 *
 * @param {date} date
 * @param {entity} it should include in ['days', 'weeks' 'months', 'years']
 * @param {quantity} quantity
 * @param {operation} it can be either 'add' or 'subtract'
 */

export const getDate = (
  date: Date | string | number,
  quantity: number,
  operation: string,
  entity = 'days',
): moment.Moment => {
  let expectedDate;
  switch (entity) {
    case 'weeks':
      expectedDate =
        operation === 'add'
          ? moment.utc(date).add(quantity, 'w')
          : moment.utc(date).subtract(quantity, 'w');
      break;

    default:
      expectedDate =
        operation === 'add'
          ? moment.utc(date).add(quantity, 'd').startOf('day')
          : moment.utc(date).subtract(quantity, 'd').startOf('day');
      break;
  }
  return expectedDate;
};

export const setDate = (value: string, date?: string): moment.Moment => {
  let newDate;
  switch (value) {
    case 'Today':
      newDate = moment.utc(formatDate(moment().toDate()));
      break;

    case 'Yesterday':
      newDate = moment.utc(getDate(moment().toDate(), 1, 'subtract'));
      break;

    case 'Last 7 days':
      newDate = moment.utc(getDate(moment().toDate(), 6, 'subtract'));
      break;

    case 'Last 14 days':
      newDate = moment.utc(getDate(moment().toDate(), 13, 'subtract'));
      break;

    case 'Last 30 days':
      newDate = moment.utc(getDate(moment().toDate(), 29, 'subtract'));
      break;

    default:
      newDate = moment.utc(getDate(moment().toDate(), 29, 'subtract'));
      break;
  }
  return newDate;
};

export const timeSince = (seconds: number): string => {
  let interval = seconds / 31536000;
  let currentTime = '';
  if (interval > 1) {
    seconds = seconds - Math.floor(interval) * 31536000;
    currentTime = Math.floor(interval) + 'yr';
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    seconds = seconds - Math.floor(interval) * 2592000;
    currentTime += Math.floor(interval) + 'months ';
  }
  interval = seconds / 86400;
  if (interval > 1) {
    seconds = seconds - Math.floor(interval) * 86400;
    currentTime += Math.floor(interval) + 'd ';
  }
  interval = seconds / 3600;
  if (interval > 1) {
    seconds = seconds - Math.floor(interval) * 3600;
    currentTime += Math.floor(interval) + 'h ';
  }
  interval = seconds / 60;
  if (interval > 1) {
    seconds = seconds - Math.floor(interval) * 60;
    currentTime += Math.floor(interval) + 'm ';
  }
  if (seconds > 1) {
    currentTime += Math.floor(seconds) + 's';
  }
  return currentTime;
  //  Math.floor(seconds) + ' seconds';
};

export const calculateDayDifferenceTimeFromPercentage = (
  percentage: number,
): string => timeSince((percentage * 86400) / 100);

/** @returns Last updated message string */
export const getTimeDifference = (time: number): string => {
  const minute = Math.floor(time / 60),
    hour = Math.ceil(time / 3600),
    day = Math.ceil(time / 86400);
  let message = '';
  if (time < 60) {
    message = `${time} ${
      time > 1
        ? i18n.t('digitalAssets.second_plural')
        : i18n.t('digitalAssets.second')
    }`;
  } else if (time < 3600) {
    message = `${minute} ${
      time > 60
        ? i18n.t('digitalAssets.minute_plural')
        : i18n.t('digitalAssets.minute')
    }`;
  } else if (time < 86400) {
    message = `${hour} ${
      time > 3600
        ? i18n.t('digitalAssets.hour_plural')
        : i18n.t('digitalAssets.hour')
    }`;
  } else if (time < new Date().getDate() * 86400) {
    message = `${day} ${
      day > 1 ? i18n.t('context.days') : i18n.t('digitalAssets.day')
    }`;
  }
  return message;
};

export const MILLISECONDS_IN_A_SECOND = 1000;

export const timeDifferenceInSeconds = (value: number): number =>
  Math.floor(Math.abs(Date.now() - value) / MILLISECONDS_IN_A_SECOND);

export const timeDifferenceFromStartOfDay = (value: number): number => {
  return Math.floor(
    Math.abs(moment().startOf('day').valueOf() - value) /
      MILLISECONDS_IN_A_SECOND,
  );
};

export const getStringDate = (milliSeconds = NaN) => {
  const formattedDate = new Date(milliSeconds);
  return `${formattedDate.getFullYear()}-${String(
    formattedDate.getMonth() + 1,
  ).padStart(2, '0')}-${String(formattedDate.getDate()).padStart(2, '0')}`;
};
