import { registerSideEffects } from '@redux/sideEffects';
import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '@redux/store';
import { LegacyTemplate } from '@models/productTemplate';
import { fetchGlobalTemplateConfiguration } from '@apis/productTemplate';

type TemplateState = {
  globalTemplateConfigurations: {
    data: null | {
      globalTemplateConfigurations: LegacyTemplate.GlobalTemplateConfigurationsObj[];
    };
    error: null;
  };
};

const initialState: TemplateState = {
  globalTemplateConfigurations: {
    data: null,
    error: null,
  },
};

const { actions, reducer } = createSlice({
  name: 'template',
  initialState,
  reducers: {
    FETCH_GLOBAL_TEMPLATE_CONFIGURATION_DONE: (
      state,
      {
        payload,
      }: { payload: LegacyTemplate.GlobalTemplateConfigurationsObj[] },
    ) => {
      state.globalTemplateConfigurations.data = {
        globalTemplateConfigurations: payload,
      };
    },
    FETCH_GLOBAL_TEMPLATE_CONFIGURATION_ERROR: (
      state,
      { payload }: { payload: null },
    ) => {
      state.globalTemplateConfigurations.data = null;
      state.globalTemplateConfigurations.error = payload;
    },
  },
});

const thunks = {
  fetchGlobalTemplateConfigurationSaga: (): AppThunk => async (dispatch) => {
    try {
      const configurations = await fetchGlobalTemplateConfiguration();

      dispatch(
        RTemplate.FETCH_GLOBAL_TEMPLATE_CONFIGURATION_DONE(configurations),
      );
    } catch (errorObj: any) {
      const { errors } = errorObj;
      dispatch(RTemplate.FETCH_GLOBAL_TEMPLATE_CONFIGURATION_ERROR(errors[0]));
    }
  },
} satisfies { [key: string]: (...args: any[]) => AppThunk };

registerSideEffects();

export const RTemplate = Object.assign(actions, thunks);

export default reducer;
