import './ActionOverlay.scss';

type Action = {
  icon: JSX.Element;
  tipText: string;
  position?: 'top' | 'center' | 'bottom';
  handler?: React.MouseEventHandler<HTMLDivElement>;
  showAction?: boolean;
};

export const ActionOverlay: React.FC<
  React.HTMLAttributes<HTMLDivElement> & {
    disabled?: boolean;
    actions: Action[];
  }
> = ({ actions, children, className = '', disabled = false }) => (
  <div className={`ActionOverlay ${className}`}>
    {children}
    {!disabled && (
      <div className="ActionOverlay__container">
        <div className="ActionOverlay__container___actions">
          {actions.map(
            (action: Action) =>
              action.showAction !== false && (
                <div
                  role="button"
                  className={`ActionOverlay__container___actions__action ${
                    action.position ?? ''
                  }`}
                  onClick={action.handler}
                  title={action.tipText}
                >
                  {action.icon}
                </div>
              ),
          )}
        </div>
      </div>
    )}
  </div>
);
