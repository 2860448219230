import { registerSideEffects } from '@redux/sideEffects';
import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '@redux/store';
import { IMetadataHighlight, IMetadataSegment } from '@models/metadata';
import { getMetadataHighlights, getMetadataSegments } from '@apis/metadata';

type MetadataState = {
  highlights: null | IMetadataHighlight[];
  highlightsError: null | string;
  segments: null | IMetadataSegment[];
  segmentsError: null | string;
};

const initialState: DeepReadonly<MetadataState> = {
  // TODO highlights should be in template slice
  highlights: null,
  highlightsError: null,
  // TODO segments should be in context slice
  segments: null,
  segmentsError: null,
};

const { actions, reducer } = createSlice({
  name: 'metadata',
  initialState,
  reducers: {
    FETCH_METADATA_HIGHLIGHTS_DONE: (
      state,
      { payload }: { payload: MetadataState['highlights'] },
    ) => {
      state.highlights = payload;
    },
    FETCH_METADATA_HIGHLIGHTS_ERROR: (
      state,
      { payload }: { payload: string },
    ) => {
      state.highlightsError = payload;
    },
    FETCH_METADATA_SEGMENTS_DONE: (
      state,
      { payload }: { payload: MetadataState['segments'] },
    ) => {
      state.segments = payload;
    },
    FETCH_METADATA_SEGMENTS_ERROR: (
      state,
      { payload }: { payload: string },
    ) => {
      state.segmentsError = payload;
    },
  },
});

const thunks = {
  getMetadataHighlightsSaga: (): AppThunk => async (dispatch) => {
    try {
      const highlights = await getMetadataHighlights();
      dispatch(RMetadata.FETCH_METADATA_HIGHLIGHTS_DONE(highlights));
    } catch (errorObj: any) {
      const { errors } = errorObj;
      dispatch(RMetadata.FETCH_METADATA_HIGHLIGHTS_ERROR(errors));
    }
  },
  getMetadataSegmentsSaga: (): AppThunk => async (dispatch) => {
    try {
      const segments = await getMetadataSegments();
      dispatch(RMetadata.FETCH_METADATA_SEGMENTS_DONE(segments));
    } catch (errorObj: any) {
      const { errors } = errorObj;
      dispatch(RMetadata.FETCH_METADATA_SEGMENTS_ERROR(errors));
    }
  },
} satisfies { [key: string]: (...args: any[]) => AppThunk };

registerSideEffects();

export const RMetadata = Object.assign(actions, thunks);

export default reducer;
