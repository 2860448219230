import { colors } from '@constants/colors';

export const Button: React.FC<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > & {
    round?: number;
    background?: string;
    border?: string;
    padding?: string;
    action?: React.MouseEventHandler<HTMLButtonElement>;
  }
> = ({
  id,
  round,
  background = `linear-gradient(270deg, ${colors.capri} 2.12%, ${colors.emerald} 96.61%)`,
  border = '0',
  padding = '4px 24px',
  children,
  className,
  disabled,
  action,
}) => (
  <button
    style={{
      background,
      padding,
      outline: 'none',
      border,
      borderRadius: round,
      opacity: disabled ? 0.4 : 1,
    }}
    id={id}
    disabled={disabled}
    className={className}
    onClick={action}
  >
    {children}
  </button>
);
