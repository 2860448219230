/**
 * @author Aayush Singh Ritam Bandyopadhyay <ritam.bandyopadhyay@corevaluetech.com>
 * @param {string} sentence string to be capitalized
 * @returns {string} string with first letter capitalized
 */
export const capitalizeFirstLetter = (str: string): string =>
  str?.replace(/(^|\. |\? |! )(\w)/g, (sub) => {
    const lastIndex = sub.length - 1;
    return sub.substring(0, lastIndex) + sub[lastIndex].toUpperCase();
  });
/**
 * @author Aayush Singh Ritam Bandyopadhyay <ritam.bandyopadhyay@corevaluetech.com>
 * @param {string} sentence string to be capitalized
 * @returns {string} string with first letter of each sentence capitalized
 */
export const capitalizeEveryFirstLetter = function (sentence: string): string {
  return sentence.replace(/\b\w/g, (l) => l.toUpperCase());
};

/**
 * @author Rishabh Jain
 * @param htmlString
 * @returns {string}
 */
export const removeHTML = (htmlString: string): string => {
  return htmlString.replace(/<\/?[^>]+(>|$)/g, '');
};
