import { ConfirmationModal } from '@components/ConfirmationModal/ConfirmationModal';
import { MODAL_METADATA } from '@constants/productVersionModals';

export const DiscardChangesModal: React.FC<{
  goBack?: Function;
  onNotNowClick?: Function;
  discardChanges: () => any;
}> = ({ discardChanges, goBack, onNotNowClick }) => {
  const { DISCARD_EDITOR_CHANGES_MODAL } = MODAL_METADATA;

  const onDiscard = () => {
    discardChanges();
    if (goBack) goBack();
  };

  return (
    <ConfirmationModal
      showModal
      buttons={DISCARD_EDITOR_CHANGES_MODAL.actionButtons}
      title={DISCARD_EDITOR_CHANGES_MODAL.title}
      statement={DISCARD_EDITOR_CHANGES_MODAL.confirmationStatement}
      clickHandler={onDiscard}
      setShowModal={onNotNowClick || (() => 0)}
      isDanger
    />
  );
};
