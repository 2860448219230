import { useTranslation } from 'react-i18next';

import { IRichContent } from '@models/rich-content';
import { capitalizeFirstLetter } from '@utils/textTransform';

import { PrimaryButton } from '../PrimaryButton/PrimaryButton';
import { HeaderFooterModalLayout } from '../Modals/HeaderFooterModalLayout/HeaderFooterModalLayout';
import { PageComponent as TemplateMainContent } from '../../pages/rich-contents/[id]/template/+page';

export const RichContentPreviewModal: React.FC<{
  showModal: boolean;
  closeModal: Function;
  richContentData: IRichContent;
}> = ({ showModal, closeModal, richContentData }) => {
  const { t } = useTranslation();

  return (
    <HeaderFooterModalLayout
      onClose={closeModal}
      showModal={showModal}
      setShowModal={closeModal}
      header={
        <header className="CVRichContent__modal--header">
          <h2 className="CVRichContent__modal--header-title ">
            {`${capitalizeFirstLetter(t('version.content_for'))} ${
              richContentData?.title
            }`}
          </h2>
        </header>
      }
      footer={
        <footer className="CVRichContent__modal--footer">
          <PrimaryButton
            onClick={() => closeModal()}
            variant="green"
            size="small"
          >
            {capitalizeFirstLetter(t('global.close'))}
          </PrimaryButton>
        </footer>
      }
      body={
        <div className="CVRichContent__modal--template">
          <TemplateMainContent
          // TODO | This was present but is incorrect
          // templateData={templateData}
          // setTemplateData={setTemplateData}
          // contentList={richContentData?.contentList || []}
          // permissions={{
          //   AUTHOR_CONTENT: false,
          //   VISUAL_ASSETS: false,
          //   REVIEW: false,
          // }}
          />
        </div>
      }
    />
  );
};
