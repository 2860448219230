export const characterLimits = {
  context: {
    name: 35,
    description: 1000,
    insight: 200,
    regionName: 35,
  },
  workFlow: {
    titleLimit: 500,
    descriptionLimit: 1000,
    likeFactorLimit: 1000,
    ugcCustomerNameLimit: 1000,
    ugcTitleLimit: 1000,
    ugcReviewLimit: 1000,
  },
  dam: {
    title: 35,
    description: 500,
    tags: 50,
    altText: 200,
  },
  common: {
    tagText: 35,
  },
  setTaskLimit: {
    titleLimit: 35,
    descriptionLimit: 1000,
  },
  taskNotes: {
    notesLimit: 1000,
  },
  richContent: {
    headingLimit: 100,
    descriptionLimit: 10000,
    ctaTextLimit: 40,
    authorTextLimit: 500,
    authorAttributionLimit: 40,
    reviewTitleLimit: 100,
    reviewContentLimit: 1000,
    subHeadingLimit: 1000,
    notesLimit: 1000,
    highlightTitle: 35,
    highlightDescription: 250,
    imageSectionHeading: 500,
  },
} as const;

export const highlightLimits = {
  workflow: {
    highlightLimit: 3,
  },
} as const;

export const imageLimits = {
  dam: {
    contextualImageLimit: 10,
  },
  workflow: {
    contextualImageLimit: 50,
  },
} as const;

export const callTimer = {
  notification: {
    refreshRate: 200000,
  },
} as const;

export const validations = {} as const;

export const priceConfig = {
  style: 'currency',
  currency: 'USD',
} satisfies Intl.NumberFormatOptions;
export const EN_US_LANG = 'en-US';

export const damImageDownloadWidth = {
  large: 2400,
  medium: 1920,
  small: 640,
} as const;
