import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import SectionHeader from '../RichContentSectionHeader';
import { ReactComponent as HtmlIllustration } from '@assets/icons/HTMLIcon.svg';
import { SectionFieldsFactory } from '../../[id]/template/SectionFieldsFactory';
import { colors } from '@constants/colors';
import { IRichContentSection } from '@models/rich-content';

import './style.scss';

const RichContentHTML: React.FC<IRichContentSection> = ({
  index,
  length,
  moveSection,
  permissions,
  attributes,
  handleChange,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div
        className={`RichContentHTML ${
          permissions.AUTHOR_CONTENT ? '' : 'RichContentTemplate__readOnly'
        }`}
      >
        <SectionHeader
          title={t('global.html')}
          moveSection={moveSection}
          showDropdown={permissions.AUTHOR_CONTENT}
          index={index}
          length={length}
          icon={<HtmlIllustration fill={colors.emerald} />}
        />
        <div className="RichContentHTML__container">
          <div className="RichContentHTML__container--html">
            <SectionFieldsFactory
              id={`html${index}`}
              key={'html'}
              attributeKey={'html'}
              sectionOrder={index}
              attribute={attributes['html']}
              permissions={permissions}
              handleChange={handleChange}
              characterLimits={Number.MAX_SAFE_INTEGER}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default memo(RichContentHTML);
