import { getAsset } from '@apis/digitalAssets';
import { DigitalAssetBrief } from '@models/digital-asset';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

export type AssetCache = { [id: string]: DigitalAssetBrief };

const cache: {
  [assetId: string]:
    | ((
        | { state: 'pending'; asset: Promise<DigitalAssetBrief> }
        | { state: 'ready'; asset: DigitalAssetBrief }
        | { state: 'error' }
      ) & { subscribers: Set<symbol> })
    | undefined;
} = {};

const cacheDispatches = new Set<Dispatch<SetStateAction<AssetCache>>>();

const loadAssets = (
  subscriber: symbol,
  ...assetIds: string[]
): {
  loaded: AssetCache;
  pending: { [id: string]: Promise<DigitalAssetBrief> };
} => {
  const loaded: AssetCache = {};
  const pending: { [id: string]: Promise<DigitalAssetBrief> } = {};

  for (const id of assetIds) {
    // If already requested simply add subscriber
    if (cache[id]) {
      const entry = cache[id];
      if (entry.state === 'ready') loaded[id] = entry.asset;
      else if (entry.state === 'pending') pending[id] = entry.asset;
      else {
        // TODO | Correctly attempt reload
      }
      entry.subscribers.add(subscriber);
    } else {
      const assetPromise = getAsset(id);

      assetPromise.then((asset) => {
        const currentValue = cache[id];
        if (!currentValue) return; // Has been unloaded for some reason;

        cache[id] = {
          state: 'ready',
          asset,
          subscribers: currentValue.subscribers,
        };
        const assetCache = getCurrentAssetCache();
        cacheDispatches.forEach((dispatch) => dispatch(assetCache));

        return asset;
      });

      cache[id] = {
        state: 'pending',
        asset: assetPromise,
        subscribers: new Set([subscriber]),
      };
    }
  }

  return { loaded, pending };
};

const unregisterSubscriber = (subscriber: symbol) => {
  for (const id in cache) {
    const { subscribers } = cache[id]!;

    subscribers.delete(subscriber);

    // Allow 1 second for someone else to claim the asset
    setTimeout(() => {
      if (subscribers.size === 0) delete cache[id];
    }, 1_000);
  }
};

const getCurrentAssetCache = (): AssetCache => {
  const assetCache: AssetCache = {};
  for (const id in cache) {
    if (cache[id]?.state === 'ready') assetCache[id] = cache[id].asset;
  }
  return assetCache;
};

/**
 * A function that should be used to speed up asset fetching by checking whether it is in the cache.
 *
 * Does not claim the asset, only to use if present
 */
export const getCachedAsset = (
  id: string,
): DigitalAssetBrief | Promise<DigitalAssetBrief> | null => {
  const entry = cache[id];
  if (!entry) return null;
  if (entry.state === 'ready') return entry.asset;
  if (entry.state === 'pending') return entry.asset;
  return null;
};

/**
 * Allows you to use a unified cache for assets in order not to refetch assets unnecessarily.
 *
 * @returns The cache and a function to cache assets
 */
export const useAssets = (): {
  assetCache: AssetCache;
  cacheAssets: (...assetIds: string[]) => void;
} => {
  const subscriber = useRef(Symbol());
  const [assetCache, setAssetCache] = useState<AssetCache>(() =>
    getCurrentAssetCache(),
  );

  useEffect(() => {
    cacheDispatches.add(setAssetCache);
    return () => void cacheDispatches.delete(setAssetCache);
  }, [setAssetCache]);

  const cacheAssets = useCallback((...assetIds: string[]) => {
    const { loaded } = loadAssets(subscriber.current, ...assetIds);
    if (Object.keys(loaded).length > 0)
      setAssetCache((prev) => ({ ...prev, ...loaded }));
  }, []);

  useEffect(() => () => unregisterSubscriber(subscriber.current), []);

  return { assetCache, cacheAssets };
};
