import './PrimaryButton.scss';

export type PrimaryButtonVariant =
  | 'gradient'
  | 'gray'
  | 'green'
  | 'red'
  | 'white';

export const PrimaryButton: React.FC<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > & {
    size?: 'small' | 'medium' | 'large';
    variant?: PrimaryButtonVariant;
  }
> = ({
  id,
  className = '',
  disabled = false,
  size = 'medium',
  variant = 'gradient',
  children,
  onClick = () => null,
}) => (
  <button
    className={`PrimaryButton-${size}__${variant} ${
      disabled ? 'disabled' : ''
    } ${className ? className : ''}`}
    disabled={disabled}
    onClick={onClick}
    id={id}
  >
    {children}
  </button>
);
