import './RoundCheckbox.scss';

export const RoundCheckBox: React.FC<
  React.InputHTMLAttributes<HTMLInputElement>
> = ({ className, ...props }) => (
  <input
    type="checkbox"
    {...props}
    className={`round-checkbox ${className || ''}`}
  />
);
