import { colors } from '@constants/colors';

export const TestimonialIcon = ({
  height = '24px',
  width = '24px',
  fill = colors.emerald2,
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 11 8"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2551_137400)">
      <path
        d="M8.22248 8.00131C7.88965 8.00454 7.56033 7.93299 7.25885 7.79194C6.95736 7.65089 6.69139 7.44394 6.48057 7.18637C6.00525 6.56384 5.76246 5.79474 5.79422 5.01214C5.79422 3.06905 7.04464 1.5867 9.22518 0.945312V1.21512C8.48393 1.40606 7.81027 1.79911 7.27927 2.35043C6.74828 2.90176 6.38083 3.58972 6.21786 4.33762H6.35198C6.56482 4.05119 6.84535 3.82199 7.16848 3.67055C7.4916 3.5191 7.84724 3.45014 8.20355 3.46982C8.50968 3.46 8.81472 3.51115 9.1009 3.62032C9.38708 3.72948 9.64869 3.89447 9.87051 4.10568C10.0736 4.31108 10.2337 4.55499 10.3413 4.8231C10.4488 5.0912 10.5017 5.37809 10.4969 5.66693C10.5027 6.27282 10.2769 6.85806 9.86578 7.30313C9.655 7.52676 9.40014 7.70427 9.1173 7.82444C8.83446 7.94461 8.52977 8.00483 8.22248 8.00131ZM2.78611 8.00131C2.45316 8.00454 2.12373 7.933 1.82212 7.79195C1.5205 7.65091 1.25439 7.44396 1.04341 7.18637C0.567627 6.56403 0.32454 5.79487 0.356272 5.01214C0.356272 3.06984 1.60669 1.58748 3.78802 0.945312V1.21512C3.04681 1.40615 2.37319 1.79921 1.84221 2.35053C1.31124 2.90184 0.943752 3.58976 0.780705 4.33762H0.915608C1.12835 4.05147 1.40864 3.82245 1.73145 3.67103C2.05427 3.5196 2.40956 3.45047 2.7656 3.46982C3.07197 3.46 3.37724 3.51116 3.66368 3.62032C3.95011 3.72947 4.212 3.89446 4.43414 4.10568C4.63731 4.31105 4.79738 4.55496 4.90494 4.82307C5.01251 5.09118 5.06541 5.37809 5.06053 5.66693C5.0664 6.27284 4.84064 6.85811 4.42941 7.30313C4.2186 7.52672 3.96374 7.7042 3.6809 7.82437C3.39807 7.94454 3.0934 8.00479 2.78611 8.00131Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_2551_137400">
        <rect
          width="10.1398"
          height="7.056"
          fill={colors.white}
          transform="translate(0.355469 0.945312)"
        />
      </clipPath>
    </defs>
  </svg>
);
