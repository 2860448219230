import { useEffect } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ROUTES } from '@constants/routes';
import { ON_BOARD_STATUS_KEY_MAPPING } from '@constants/users';

import SideBar from './SideBar';
import { Modals } from '@components/Modals/Modals';
import { FullScreenSpinner } from '@components/Spinner/FullScreenSpinner';
import { MainContent } from '@components/NavItems/NavItems';
import { RSidebar } from '@redux/slices/sidebar';

import './style.scss';

interface ApplicationLayoutProps extends RouteComponentProps {
  showSidebar?: boolean;
}
const ApplicationLayout: React.FC<ApplicationLayoutProps> = (props) => {
  const { children, showSidebar = true } = props;
  const history = useHistory();
  const sidebarCollapsed = useSelector((state) => state.sidebar.collapsed);
  const showSpinner = useSelector((state) => state.spinner.show);
  const { onboardStatus } = useSelector((state) => state.currentUser);

  const dispatch = useDispatch();
  const toggleCollapsed = () =>
    dispatch(RSidebar.setCollapsed(!sidebarCollapsed));
  useEffect(() => {
    onboardStatus &&
      onboardStatus[ON_BOARD_STATUS_KEY_MAPPING.newUser] &&
      history.push(ROUTES.startGuide);
  }, [onboardStatus]);

  return (
    <>
      <div className="vh-100 position-relative">
        {showSidebar && (
          <SideBar
            history={props}
            toggle={sidebarCollapsed}
            settoggle={toggleCollapsed}
          />
        )}
        <MainContent active={sidebarCollapsed} sidebarMargin={showSidebar}>
          {children}
        </MainContent>
        <div id="toast-container-div"></div>
      </div>
      <Modals />
      <div id="full-screen-model"></div>
      {showSpinner && <FullScreenSpinner />}
    </>
  );
};

export default ApplicationLayout;
