import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { uuid4 } from '@sentry/utils';

import { AllFilesType, allowedFileSizes } from '@models/files';
import { ALL_TYPES_TO_EXTENSIONS_MAP } from '@utils/files';
import { ModalProps } from '@models/modal';
import {
  capitalizeEveryFirstLetter,
  capitalizeFirstLetter,
} from '@utils/textTransform';
import { compressFile } from '@utils/compressFile';
import { uploadDigitalAsset, uploadDigitalAssetUrl } from '@apis/digitalAssets';
import useLegacyModal from '../../ModalLayout/useModal';
import { toast } from '../../ToastNotification/ToastManager';
import { colors } from '@constants/colors';
import {
  ConfirmationModal,
  ActionButtonProps,
} from '../../ConfirmationModal/ConfirmationModal';
import { CloseIcon } from '@components/Icons/CloseIcon';
import { TickMarkIcon } from '@components/Icons/TickMarkIcon';
import { TranscriptIcon } from '@components/Icons/TranscriptIcon';
import { VideoIcon } from '@components/Icons/VideoIcon';
import { AssetIcon } from '../../Icons/AssetIcon';
import { ModalLayout } from '../../ModalLayout/ModalLayout';
import { PrimaryButton } from '../../PrimaryButton/PrimaryButton';
import { TextInput } from '../../TextInput/TextInput';
import { useDispatch } from 'react-redux';
import { RSpinner } from '@redux/slices/spinner';

import { ReactComponent as UploadImageIcon } from '@assets/icons/56_UploadImage.svg';
import { ReactComponent as PdfIcon } from '@assets/icons/pdf.svg';

import './FileUploadModal.scss';

const FileProgressView: React.FC<{
  file?: File;
  urlFile?: any;
  progress: number;
  onCancel(): void;
}> = ({ file, urlFile, progress = 0, onCancel }) => {
  const [progressData, setProgressData] = useState<number>(0);

  useEffect(() => {
    setProgressData(progress);
  }, [progress]);

  const getImageUrl = (file: File) => {
    const imgUrl = URL.createObjectURL(file);
    return <img alt={file.name} className="w-100 h-100" src={imgUrl} />;
  };

  const getFileTypeIcon = () => {
    let fileTypeIcon;
    switch (file?.type) {
      case 'image/svg+xml':
      case 'image/jpg':
      case 'image/jpeg':
      case 'image/png':
        fileTypeIcon = getImageUrl(file);
        break;
      case 'text/vtt':
        fileTypeIcon = <TranscriptIcon />;
        break;
      case 'application/pdf':
        fileTypeIcon = <PdfIcon />;
        break;
      case 'video/mp4':
      case 'video/quicktime':
      case 'video/mvp':
        fileTypeIcon = <VideoIcon />;
        break;
      default:
        fileTypeIcon = <AssetIcon />;
        break;
    }
    return fileTypeIcon;
  };

  return (
    <div className="FileProgressView d-flex align-items-center">
      <div className="FileProgressView-iconContainer d-flex justify-content-center align-items-center">
        {getFileTypeIcon()}
      </div>
      <div className="FileProgressView-fileContainer">
        <div className="FileProgressView-fileContainer__name d-flex justify-content-between align-items-center">
          {file && `${file.name} - ${file.type}`}
          {urlFile && `${urlFile?.fileName} - ${urlFile?.format}`}
          {progressData < 100 && (
            <CloseIcon
              className="close-icon cursor-pointer"
              fill={colors.white}
              onClick={onCancel}
            />
          )}
          {progressData === 100 && (
            <TickMarkIcon className="tick-icon cursor-pointer" />
          )}
        </div>
        <div className="FileProgressView-fileContainer__progressBar">
          <div
            className="FileProgressView-fileContainer__progressBar--percentage"
            style={{ width: `${Math.round(progressData)}%` }}
          />
        </div>
      </div>
    </div>
  );
};

export const FileUploadModal: React.FC<
  ModalProps & {
    maxFiles?: number;
    allowedFiles: AllFilesType[];
    onUpload: (value: any, fileName: string) => void;
    endpoint?: string;
    method?: 'post' | 'put' | 'patch';
    sendConfig?: boolean;
    compress?: boolean;
  }
> = ({
  showModal,
  setShowModal,
  onClose,
  onUpload,
  maxFiles,
  allowedFiles,
  sendConfig,
  compress,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { openModal, closeModal, showModalId } = useLegacyModal();
  const cancel = useRef<any>([]);
  const [selectedFiles, setSelectedFiles] = useState<(File | null)[]>([]);
  const [urlFiles, setUrlFiles] = useState<any[]>([]);
  const [error, setError] = useState<string>('');
  const [progress, setProgress] = useState<{ [id: string]: number }>({});
  const [uploadCount, setUploadCount] = useState(0);

  const [assetUrl, setAssetUrl] = useState('');

  const confirmationModalId = 'delete-confirmation-modal';

  const confirmationModal = {
    modalId: confirmationModalId,
    openModal: () => {
      openModal(confirmationModalId);
    },
    title: capitalizeFirstLetter(
      t('digitalAssets.upload_modal_close_confirmation_title'),
    ),
    confirmationStatement: `${capitalizeFirstLetter(
      t('digitalAssets.upload_modal_close_confirmation_statement1'),
    )} ${capitalizeFirstLetter(
      t('digitalAssets.upload_modal_close_confirmation_statement2'),
    )}`,
    clickHandler: () => {
      closeModal();
      onClose();
    },

    actionButtons: [
      {
        text: capitalizeFirstLetter(`${t('global.no_cancel')}`),
        color: 'gray',
        type: 'cancel',
      },
      {
        text: capitalizeFirstLetter(`${t('global.yes')}, ${t('global.close')}`),
        color: 'red',
        type: 'submit',
      },
    ] as ActionButtonProps[],
  };

  const handleCloseModal = () => {
    if (
      !Object.values(progress).every(
        (currentProgress) => currentProgress === 100,
      )
    ) {
      confirmationModal.openModal();
    } else {
      setProgress({});
      setSelectedFiles([]);
      setUrlFiles([]);
      setAssetUrl('');
      setError('');
      onClose();
    }
  };

  const handleUrlUpload = async () => {
    dispatch(RSpinner.show(true));
    const response = await uploadDigitalAssetUrl(assetUrl);
    if (response.isSuccess) {
      setUrlFiles([response.data, ...urlFiles]);
      setProgress({
        ...progress,
        [response.data.fileName]: 100,
      });
      setAssetUrl('');
      onUpload(response.data, response.data.fileName);
      dispatch(RSpinner.show(false));
    } else {
      setAssetUrl('');
      dispatch(RSpinner.show(false));
    }
  };

  const handleUploadFile = (file: File, index: number, prevLength = 0) => {
    const formData = new FormData();
    formData.append('file', file, file.name);
    if (sendConfig) {
      formData.append(
        'createAssetsDTOStr',
        `{"publicId": "${uuid4()}", "referenceID": "${uuid4()}", "tags": []}`,
      );
    }
    uploadDigitalAsset(formData, {
      onUploadProgress: (progressEvent) => {
        setProgress((prev) => ({
          ...prev,
          [file.name]: (progressEvent.loaded * 100) / progressEvent.total,
        }));
        if (progressEvent.loaded === progressEvent.total) {
          // removing array from [index - uploadCount] index as we are deleting file object from cancel ref after succesful upload...
          // so, index is going to be changed every time in that case
          cancel.current.splice(index - uploadCount, 1);
          setUploadCount((prev) => prev + 1);
        }
      },
      cancelToken: new axios.CancelToken(function executor(cancelFn) {
        cancel.current.push({
          cancelFn,
          index: prevLength + index,
          fileName: file.name,
        });
      }),
    })
      .then((data) => onUpload(data, file.name))
      .catch((errorObj: any) => {
        if (axios.isCancel(errorObj)) {
          toast.show({
            message: capitalizeEveryFirstLetter(t('global.upload_canceled')),
            error: true,
          });
        } else {
          const { errors } = errorObj;
          toast.show({
            message: errors?.[0]?.message,
            error: true,
          });
        }
      });
  };

  const handleCancelUpload = (index: number, fileName: string) => {
    const clonedProgress = JSON.parse(JSON.stringify(progress));
    const clonedSelectedFiles = [...selectedFiles];
    const cancelFileIndex = cancel.current.findIndex(
      (file: any) => file.fileName === fileName,
    );
    cancel.current[cancelFileIndex].cancelFn();
    cancel.current.splice(cancelFileIndex, 1);
    delete clonedProgress[fileName];
    clonedSelectedFiles.splice(index, 1);
    setSelectedFiles(clonedSelectedFiles);
    setProgress(clonedProgress);
  };

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      let errorText = '';
      if (acceptedFiles.length > 0) {
        const sizeExceedingFileIndex = acceptedFiles.findIndex(
          (file) =>
            file.size >
            allowedFileSizes[
              ALL_TYPES_TO_EXTENSIONS_MAP[
                file.type as keyof typeof ALL_TYPES_TO_EXTENSIONS_MAP
              ]
            ],
        );
        if (maxFiles && acceptedFiles.length > maxFiles) {
          errorText = `${capitalizeFirstLetter(t('global.you_can_select'))} ${
            maxFiles > 1 ? t('global.upto') : t('global.only')
          } ${maxFiles} ${
            maxFiles > 1 ? t('global.file_plural') : t('global.file')
          }`;
        } else if (sizeExceedingFileIndex !== -1) {
          const fileType =
            ALL_TYPES_TO_EXTENSIONS_MAP[
              acceptedFiles[sizeExceedingFileIndex]
                .type as keyof typeof ALL_TYPES_TO_EXTENSIONS_MAP
            ];
          errorText = `${fileType} ${t(
            'global.files_should_not_exceed',
          )} ${Math.round(allowedFileSizes[fileType] / 1024 ** 2)}MB ${t(
            'global.size_limit',
          )}`;
        } else {
          const copySelectedFiles = [...selectedFiles];
          // filtering the choosen uploaded files to only upload files with different name which is not uploaded in current upload modal instance
          const filteredAcceptedFiles = acceptedFiles.filter(
            (file) =>
              copySelectedFiles.findIndex(
                (selectedFile) =>
                  !!selectedFile && selectedFile.name === file.name,
              ) === -1,
          );
          if (compress) {
            acceptedFiles = await Promise.all(
              acceptedFiles.map(async (file) => await compressFile(file)),
            );
          }
          setSelectedFiles([...selectedFiles, ...filteredAcceptedFiles]);
          filteredAcceptedFiles.forEach((file, index) =>
            handleUploadFile(file, index, selectedFiles.length),
          );
        }
      }

      setError(errorText);
    },
    [selectedFiles, progress, t],
  );

  const onRejectDrop = (err: any) => {
    if (err[0].errors[0].code === 'file-invalid-type') {
      setError(`${t('global.invalid_file_type_selected')}.`);
    } else {
      setError(err[0].errors?.[0]?.message);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: allowedFiles.join(','),
    onDropRejected: onRejectDrop,
  });

  return (
    <>
      <ModalLayout
        showModal={showModal}
        setShowModal={setShowModal}
        onClose={handleCloseModal}
        defaultClose
      >
        <div className="FileUploadModal">
          <div className="FileUploadModal-title text-center">
            {capitalizeEveryFirstLetter(t('global.file_upload'))}
          </div>
          <div className="FileUploadModal-top">
            <div className="FileUploadModal-top__left">
              <div
                className="FileUploadModal-top__left--uploadContainer"
                {...getRootProps()}
              >
                {isDragActive ? (
                  <div className="uploadContainer-dropzone">
                    <UploadImageIcon className="uploadContainer-dropzone__iconLarge" />
                  </div>
                ) : (
                  <>
                    <input {...getInputProps()} type="file" multiple />
                    <div className="uploadContainer-dropzone">
                      <UploadImageIcon className="uploadContainer-dropzone__icon" />
                      <p className="uploadContainer-dropzone__textPrimary">
                        {t('global.drag_and_drop_file_here')}
                      </p>

                      <p className="uploadContainer-dropzone__textSecondary">
                        (
                        {allowedFiles
                          .map((file) => ALL_TYPES_TO_EXTENSIONS_MAP[file])
                          .join(', ')
                          .toUpperCase()}
                        )
                      </p>
                      <p className="uploadContainer-dropzone__textTertiary">
                        {t('global.or')}
                      </p>
                      <PrimaryButton size="small" variant="green">
                        {capitalizeEveryFirstLetter(
                          `${t('global.browse')} ${t('global.files')}`,
                        )}
                      </PrimaryButton>
                      {maxFiles === 1 && (
                        <p className="uploadContainer-dropzone__maxFilesMessage">
                          {capitalizeFirstLetter(
                            t('digitalAssets.max_1_file_upload_message'),
                          )}
                        </p>
                      )}
                    </div>
                  </>
                )}
              </div>
              <div className="FileUploadModal-urlUpload">
                <h1 className="FileUploadModal-urlUpload__title">
                  {t('digitalAssets.paste_an_asset_URL_to_upload')}
                </h1>
                <p className="FileUploadModal-urlUpload__desc">
                  {t('digitalAssets.multiple_url_upload')}
                </p>
                <div className="FileUploadModal-urlUpload__input">
                  <TextInput
                    id="asset-upload"
                    onChange={(url) => setAssetUrl(url)}
                    value={assetUrl}
                    placeholder={t('digitalAssets.paste_url_here')}
                  />
                  <PrimaryButton
                    onClick={() => handleUrlUpload()}
                    size="small"
                    disabled={!assetUrl.length}
                  >
                    {t('global.upload')}
                  </PrimaryButton>
                </div>
              </div>
              {error && (
                <p className="FileUploadModal-top__left--error">{error}</p>
              )}
            </div>
            <div className="FileUploadModal-top__right">
              {selectedFiles.length > 0 || urlFiles.length > 0 ? (
                <div className="FileUploadModal-top__right--files">
                  {/* Selected files from User System */}
                  {selectedFiles.length > 0 &&
                    selectedFiles.map(
                      (file, index) =>
                        file && (
                          <FileProgressView
                            key={index}
                            file={file}
                            progress={progress?.[file.name]}
                            onCancel={() =>
                              handleCancelUpload(index, file.name)
                            }
                          />
                        ),
                    )}
                  {/* Direct Upload Urls */}
                  {urlFiles.length > 0 &&
                    urlFiles.map(
                      (file, index) =>
                        file && (
                          <FileProgressView
                            key={index}
                            urlFile={file}
                            progress={progress?.[file.fileName]}
                            onCancel={() => null}
                          />
                        ),
                    )}
                </div>
              ) : (
                <div className="FileUploadModal-top__right--emptyText">
                  <p>{capitalizeFirstLetter(t('global.no_file_uploaded'))}</p>
                </div>
              )}
            </div>
          </div>
          <div className="FileUploadModal-bottom">
            <div className="FileUploadModal-bottom__line" />
            <div className="FileUploadModal-bottom__footer d-flex justify-content-end">
              <PrimaryButton size="small" onClick={handleCloseModal}>
                {capitalizeEveryFirstLetter(t('global.done'))}
              </PrimaryButton>
            </div>
          </div>
        </div>
      </ModalLayout>
      <ConfirmationModal
        setShowModal={closeModal}
        showModal={confirmationModal.modalId === showModalId}
        title={confirmationModal.title}
        statement={confirmationModal.confirmationStatement}
        buttons={confirmationModal.actionButtons}
        clickHandler={confirmationModal.clickHandler}
        isDanger
      />
    </>
  );
};
