import { useState } from 'react';

// Custom Hook for modal
/**
 * @deprecated Use `dispatch(RModal.open)` and `dispatch(RModal.close)` instead
 * // TODO Remove
 */
const useLegacyModal = () => {
  const [showModalId, setShowModalId] = useState<string | null>();
  const openModal = (modalId: string) => {
    setShowModalId(modalId);
  };

  const closeModal = () => {
    setShowModalId(null);
  };

  return {
    openModal,
    closeModal,
    showModalId,
  };
};

export default useLegacyModal;
