export enum WorkflowOptions {
  useWorkflow = 'USE_WORKFLOW',
  skipWorkflow = 'SKIP_WORKFLOW',
}

export enum TaskType {
  authorContent = 'AUTHOR_CONTENT',
  visualAsset = 'VISUAL_ASSETS',
  review = 'REVIEW',
  reviewAuthorContent = 'REVIEW_CONTENT',
  reviewAssetContent = 'REVIEW_VISUAL_ASSET',
}

export enum TaskName {
  authorContent = 'authorContent',
  visualAsset = 'visualAsset',
  reviewer = 'reviewer',
}

export enum TaskFieldName {
  user = 'user',
  assignee = 'assignee',
  dueDate = 'dueDate',
  taskNotes = 'taskNotes',
  taskType = 'taskType',
  taskName = 'taskName',
  feedback = 'feedback',
}

export const TASKS_INITIAL_DATA = {
  [TaskType.authorContent]: {
    [TaskFieldName.user]: null,
    [TaskFieldName.dueDate]: null,
    [TaskFieldName.taskNotes]: '',
  },
  [TaskType.visualAsset]: {
    [TaskFieldName.user]: null,
    [TaskFieldName.dueDate]: null,
    [TaskFieldName.taskNotes]: '',
  },
  [TaskType.review]: {
    [TaskFieldName.user]: null,
    [TaskFieldName.dueDate]: null,
    [TaskFieldName.taskNotes]: '',
  },
};

export const ASSIGNEES_INITIAL_DATA = {
  [TaskType.authorContent]: [],
  [TaskType.visualAsset]: [],
  [TaskType.review]: [],
};

export const TaskTypeMappingWithTaskName: {
  [key: string]: string;
} = {
  [TaskType.authorContent]: TaskName.authorContent,
  [TaskType.visualAsset]: TaskName.visualAsset,
  [TaskType.review]: TaskName.reviewer,
  [TaskType.reviewAssetContent]: TaskName.reviewer,
  [TaskType.reviewAuthorContent]: TaskName.reviewer,
};

export enum WORKFLOW_ACTION_BUTTONS {
  PREVIEW = 'PREVIEW',
  SAVE_CHANGES = 'SAVE_CHANGES',
  SAVE_AND_EXIT = 'SAVE_AND_EXIT',
  PUBLISH = 'PUBLISH',
  APPROVE_ASSETS = 'APPROVE_ASSETS',
  APPROVE_CONTENT = 'APPROVE_CONTENT',
  REJECT_ASSETS = 'REJECT_ASSETS',
  REJECT_CONTENT = 'REJECT_CONTENT',
  BACK = 'BACK',
  SUBMIT_FOR_REVIEW = 'SUBMIT_FOR_REVIEW',
}

export const WORKFLOW_BUTTONS_META_DATA: {
  [key: string]: {
    label: string;
    variant?: 'gradient' | 'gray' | 'green' | 'red' | 'white';
    showEnabled?: 'onTemplateUpdate';
    skipValidation?: boolean;
  };
} = {
  [WORKFLOW_ACTION_BUTTONS.PREVIEW]: {
    label: 'Preview',
    variant: 'green',
  },
  [WORKFLOW_ACTION_BUTTONS.SAVE_CHANGES]: {
    label: 'Save Changes',
    showEnabled: 'onTemplateUpdate',
  },
  [WORKFLOW_ACTION_BUTTONS.SAVE_AND_EXIT]: {
    label: 'Save and Exit',
    variant: 'gray',
    showEnabled: 'onTemplateUpdate',
    skipValidation: true,
  },
  [WORKFLOW_ACTION_BUTTONS.SUBMIT_FOR_REVIEW]: {
    label: 'Submit for review',
    showEnabled: 'onTemplateUpdate',
  },
  [WORKFLOW_ACTION_BUTTONS.PUBLISH]: {
    label: 'Publish',
  },
  [WORKFLOW_ACTION_BUTTONS.APPROVE_ASSETS]: {
    label: 'Approve Visual assets',
    variant: 'green',
  },
  [WORKFLOW_ACTION_BUTTONS.APPROVE_CONTENT]: {
    label: 'Approve Author content',
    variant: 'green',
  },
  [WORKFLOW_ACTION_BUTTONS.REJECT_ASSETS]: {
    label: 'Feedback for Visual assets',
  },
  [WORKFLOW_ACTION_BUTTONS.REJECT_CONTENT]: {
    label: 'Feedback for Author content',
  },
  [WORKFLOW_ACTION_BUTTONS.BACK]: {
    label: 'Back',
  },
};
