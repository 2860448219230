import { Experience } from '@models/experience';
import { clientWithToken } from '.';

export const listExperiences = async (): Promise<Experience[]> => {
  const { data } = await clientWithToken.get<Experience[]>(
    'context/experiences',
  );
  return data;
};

export const getExperience = async (id: string): Promise<Experience> => {
  const { data } = await clientWithToken.get<Experience>(
    'context/experiences/' + id,
  );
  return data;
};
