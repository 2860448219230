import { ENDPOINTS } from '@constants/endpoints';
import { clientWithToken } from '.';
import { IGuide, IGuideStatus } from '@models/quickStartGuide';

export const fetchGuide = async () => {
  const url = ENDPOINTS.quickStartGuide.guide;
  const res = await clientWithToken.get<IGuide>(url);
  return res.data;
};

export const fetchGuideStatus = async () => {
  const url = ENDPOINTS.quickStartGuide.guideStatus;
  const response = await clientWithToken.get<IGuideStatus>(url);
  return response.data;
};
