import { registerSideEffects } from '@redux/sideEffects';
import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '@redux/store';
import { DirectoryProduct } from '@models/product';
import { ListableActions, ListableState } from '@models/filter';
import { countProducts, productListConfig, listProducts } from '@apis/products';
import { isError } from '@sentry/utils';

type ProductState = ListableState<DirectoryProduct, typeof productListConfig>;

const initialState: ProductState = {
  sortBy: 'relevanceScore',
  sortOrder: 'desc',
  filters: {
    optimizationStatus: 'all',
    status: 'all',
  },
  searchText: '',
  page: 0,
  items: null,
  totalItems: 0,
  selectedItems: [],
};

type ListActions = ListableActions<typeof productListConfig>;

const { actions, reducer, caseReducers } = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setSort: (
      state,
      { payload }: { payload: Parameters<ListActions['setSort']>[0] },
    ) => {
      state.sortBy = payload.sortBy;
      state.sortOrder = payload.sortOrder;
      caseReducers.resetResults(state);
    },
    updateFilter: (
      state,
      {
        payload,
      }: {
        payload: Parameters<ListActions['updateFilter']>[0];
      },
    ) => {
      state.filters[payload.filter] = payload.value as any;
      caseReducers.resetResults(state);
    },
    resetResults: (state) => {
      state.page = 0;
      state.totalItems = 0;
      state.items = null;
    },
    setSearchText: (state, { payload }: { payload: string }) => {
      state.searchText = payload;
      caseReducers.resetResults(state);
    },
    incrementPage: (state) => {
      state.page++;
    },
    setItemCount: (state, { payload }: { payload: number }) => {
      state.totalItems = payload;
    },
    addItems: (state, { payload }: { payload: DirectoryProduct[] }) => {
      const items = state.items || [];
      state.items = [...items, ...payload];
    },
    setSelectedItems: (state, { payload }: { payload: DirectoryProduct[] }) => {
      state.selectedItems = payload;
    },
    setErrorState: (state) => {
      state.items = [];
      state.totalItems = 0;
    },
    clearAllFilters: (state) => {
      state.sortBy = initialState.sortBy;
      state.sortOrder = initialState.sortOrder;
      state.filters = initialState.filters;
      caseReducers.resetResults(state);
    },
    updateProductDetails: (
      state,
      {
        payload,
      }: {
        payload: Pick<DirectoryProduct, 'productId'> &
          Partial<DirectoryProduct>;
      },
    ) => {
      const product = state.items?.find(
        ({ productId }) => productId === payload.productId,
      );
      if (product) Object.assign(product, payload);
    },
  },
});

const thunks = {} satisfies { [key: string]: (...args: any[]) => AppThunk };

registerSideEffects({
  name: 'Fetch next products',
  dependsOn: [
    actions.incrementPage,
    actions.setSearchText,
    actions.resetResults,
    actions.updateFilter,
    actions.setSort,
  ],
  execute: async ({ dispatch, state }) => {
    try {
      const productsResultPromise = listProducts(state.products);
      const countResultPromise =
        state.products.page === 0 ? countProducts(state.products) : null;

      const productsResult = await productsResultPromise;
      if (isError(productsResult)) throw productsResult;

      dispatch(RProduct.addItems(productsResult));

      if (countResultPromise) {
        const countResult = await countResultPromise;
        if (isError(countResult)) throw countResult;
        else dispatch(RProduct.setItemCount(countResult));
      }
    } catch {
      dispatch(RProduct.setErrorState());
    }
  },
});

export const RProduct = Object.assign(actions, thunks);

export default reducer;
