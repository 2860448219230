import { ModalLayout } from '../ModalLayout/ModalLayout';

import './ReadOnlyModal.scss';

export const ReadOnlyModal: React.FC<{
  showModal: boolean;
  setShowModal: Function;
  modalHeader?: JSX.Element;
  modalBody: JSX.Element;
  viewType?: 'landscape' | 'portrait';
  bodyClass?: string;
  showCloseIcon?: boolean;
}> = ({
  showModal,
  setShowModal,
  modalHeader,
  modalBody,
  viewType = 'landscape',
  bodyClass = '',
  showCloseIcon = true,
}) => (
  <ModalLayout
    showModal={showModal}
    setShowModal={setShowModal}
    defaultClose={showCloseIcon}
    className={`ReadOnlyModal ReadOnlyModal__${viewType}`}
  >
    <div className={`ReadOnlyModal__${viewType}-header`}>{modalHeader}</div>
    <div className={`ReadOnlyModal__${viewType}-body ${bodyClass}`}>
      {modalBody}
    </div>
  </ModalLayout>
);
