import { LegacyProduct } from '@models/product';
import { clientWithToken } from '.';

export type AIGeneration = {
  [attribute: string]: string | string[];
};

/** Generates an AI version for the experience on the product and @returns The generated content */
const generate = async (request: {
  experienceId: string;
  productId: string;
}): Promise<AIGeneration> => {
  const searchParams = new URLSearchParams(request);
  type InternalAIGeneration = {
    [attribute: string]: string | LegacyProduct.Asset[];
  };
  const { data } = await clientWithToken.get<InternalAIGeneration>(
    '/intelligence/v1/generate?' + searchParams,
  );

  return Object.fromEntries(
    Object.entries(data).map(([n, v]): [string, string | string[]] => [
      n,
      Array.isArray(v) ? v.map((a) => a.assetId) : v,
    ]),
  );
};

export const AIService = { generate };
