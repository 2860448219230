import './EndOfSearch.scss';

export const EndOfSearch: React.FC<{
  text: string;
}> = ({ text }) => {
  return (
    <div className="EndOfSearch">
      <hr />
      <p>{text}</p>
    </div>
  );
};
