import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import SectionHeader from '../RichContentSectionHeader';
import { SectionFieldsFactory } from '../../[id]/template/SectionFieldsFactory';

import { Attribute, IRichContentSection } from '@models/rich-content';
import { characterLimits } from '../../../../heuristic.config';
import { capitalizeFirstLetter } from '@utils/textTransform';

import './style.scss';

const RichContentImageSection: React.FC<IRichContentSection> = ({
  moveSection,
  index,
  length,
  handleChange,
  attributes,
  permissions,
}) => {
  const { t } = useTranslation();

  const { imageSectionHeading, subHeadingLimit, notesLimit } =
    characterLimits.richContent;

  const sectionFieldsFactory = (
    id: string,
    key: string,
    attribute: Attribute,
    characterLimits = 0,
  ) => (
    <SectionFieldsFactory
      id={id}
      key={key}
      attributeKey={key}
      sectionOrder={index}
      attribute={attribute}
      permissions={permissions}
      handleChange={handleChange}
      characterLimits={characterLimits}
    />
  );

  return (
    <>
      <div className="RichContentImageSection">
        <SectionHeader
          title={capitalizeFirstLetter(t('rich_content.image_section'))}
          moveSection={moveSection}
          showDropdown={permissions.AUTHOR_CONTENT}
          index={index}
          length={length}
        />
        <div className="RichContentImageSection">
          <div className="RichContentImageSection-heading">
            {sectionFieldsFactory(
              `imageSectionHeading${index}`,
              'heading',
              attributes['heading'],
              imageSectionHeading,
            )}
          </div>
          <div className="RichContentImageSection-subHeading">
            {sectionFieldsFactory(
              `imageSectionSubHeading${index}`,
              'subHeading',
              attributes['subHeading'],
              subHeadingLimit,
            )}
            {sectionFieldsFactory(
              `imageSectionNotes${index}`,
              'notes',
              attributes['notes'],
              notesLimit,
            )}
          </div>
          {attributes['image']?.standardText ? (
            <div className="RichContentImageSection-selectedAsset">
              <img src={attributes['image'].standardText} alt="Section main" />
              {sectionFieldsFactory(
                `imageSectionPlaceholder${index}`,
                'image',
                attributes['image'],
              )}
            </div>
          ) : (
            <div className="RichContentImageSection-image">
              {sectionFieldsFactory(
                `imageSectionImage${index}`,
                'image',
                attributes['image'],
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default memo(RichContentImageSection);
