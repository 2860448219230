import { registerSideEffects } from '@redux/sideEffects';
import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '@redux/store';
import { LegacyProduct } from '@models/product';
import { LegacyProductVersion, LegacyVersion } from '@models/productVersion';
import { CREATION_TYPE } from '@constants/productVersion';
import { LegacyTemplate } from '@models/productTemplate';
import { toast } from '@components/ToastNotification/ToastManager';
import i18next from 'i18next';
import { RProduct } from './product';
import { TaskType } from '@constants/versionWorkflow';
import { getFullProduct } from '@apis/products';
import {
  createNewProductVersion,
  createPatchProductVersion,
  createUpdateProductVersion,
  getProductVersion,
} from '@apis/productVersions';
import { migrateVersion } from '@utils/migrate';
import { RVersion } from './version';
import { isError } from '@utils/ImproperError';

interface ProductVersionState {
  version: LegacyProductVersion | null;
  baseProduct: LegacyProduct | null;
  globalTemplateConfig: LegacyTemplate.IProductKeysObj | null;
  permissions: {
    [TaskType.authorContent]: boolean;
    [TaskType.visualAsset]: boolean;
    [TaskType.review]: boolean;
  };
}

const initialState: ProductVersionState = {
  version: null,
  baseProduct: null,
  globalTemplateConfig: null,
  permissions: {
    AUTHOR_CONTENT: true,
    VISUAL_ASSETS: true,
    REVIEW: true,
  },
};

const { actions, reducer } = createSlice({
  name: 'products',
  initialState,
  reducers: {
    SET_BASE_PRODUCT_DONE: (state, { payload }: { payload: LegacyProduct }) => {
      state.baseProduct = payload;
    },
    SET_GLOBAL_TEMPLATE_CONFIG: (
      state,
      { payload }: { payload: LegacyTemplate.IProductKeysObj },
    ) => {
      state.globalTemplateConfig = payload;
    },
    FETCH_PRODUCT_VERSION_DONE: (
      state,
      { payload }: { payload: LegacyProductVersion },
    ) => {
      state.version = payload;
    },
    CREATE_PRODUCT_VERSION_DONE: (
      state,
      { payload }: { payload: LegacyProductVersion },
    ) => {
      state.version = payload;
    },
    UPDATE_PRODUCT_VERSION_DONE: (
      state,
      { payload }: { payload: LegacyProductVersion },
    ) => {
      state.version = payload;
    },
    SET_PRODUCT_VERISON_PERMISSION: (
      state,
      { payload }: { payload: typeof state.permissions },
    ) => {
      state.permissions = payload;
    },
    RESET_PRODUCT_VERSION: (state) => {
      state.version = null;
      state.permissions = initialState.permissions;
    },
  },
});

const thunks = {
  fetchProductSaga:
    (productId: string): AppThunk =>
    async (dispatch) => {
      const result = await getFullProduct(productId);
      if (isError(result)) toast.show(result);
      else dispatch(RProductVersion.SET_BASE_PRODUCT_DONE(result));
    },
  createProductVersionSaga:
    (payload: {
      productId: string;
      body: LegacyVersion.PostVersion;
      creationType: CREATION_TYPE;
      successCallback?: (version: LegacyProductVersion) => unknown;
      failureCallback?: Function;
    }): AppThunk =>
    async (dispatch) => {
      const {
        productId,
        body,
        successCallback,
        failureCallback,
        creationType,
      } = payload;
      const result = await createNewProductVersion(
        productId,
        creationType,
        body,
      );
      if (isError(result)) {
        toast.show(result);
        failureCallback && failureCallback();
        return;
      }

      const productProperties = {
        lastUpdatedAt: Date.now(),
      };
      dispatch(RProductVersion.CREATE_PRODUCT_VERSION_DONE(result));

      dispatch(
        RProduct.updateProductDetails({
          productId: payload.productId,
          ...productProperties,
        }),
      );

      successCallback && successCallback(result);

      toast.show({
        message: result.workflowEnabled
          ? 'Version, task are created and allocated to assigned users'
          : 'Version created successfully',
      });
    },
  getProductVersion:
    (payload: { productId: string; versionId: string }): AppThunk =>
    async (dispatch) => {
      try {
        const version = await getProductVersion(
          payload.productId,
          payload.versionId,
        );
        dispatch(RProductVersion.FETCH_PRODUCT_VERSION_DONE(version));
        const migratedVersion = migrateVersion.toUpdated(version);
        dispatch(RVersion.setContent(migratedVersion.attributes));
      } catch (errorObj: any) {
        const { errors } = errorObj;
        toast.show({
          message: errors?.[0]?.message,
          error: true,
        });
      }
    },
  updateProductVersionSaga:
    (payload: {
      method: 'PUT' | 'PATCH';
      productId: string;
      versionId: string;
      body: LegacyVersion.UpdateBody;
      creationType: string;
      successCallback?: Function;
      failureCallback?: Function;
    }): AppThunk =>
    async (dispatch) => {
      const {
        method,
        productId,
        versionId,
        body,
        successCallback,
        failureCallback,
        creationType,
      } = payload;
      const updated = await (method === 'PUT'
        ? createUpdateProductVersion
        : createPatchProductVersion
      ).call(this, productId, versionId, creationType, body);
      if (isError(updated)) {
        toast.show(updated);
        failureCallback && failureCallback();
        return;
      }

      const productProperties = {
        lastUpdatedAt: Date.now(),
      };
      dispatch(RProductVersion.UPDATE_PRODUCT_VERSION_DONE(updated));
      dispatch(
        RProduct.updateProductDetails({
          productId: payload.productId,
          ...productProperties,
        }),
      );

      successCallback && successCallback();
      toast.show({ message: i18next.t('global.changes_updated_successfully') });
    },
} satisfies { [key: string]: (...args: any[]) => AppThunk };

registerSideEffects();

export const RProductVersion = Object.assign(actions, thunks);

export default reducer;
