import { registerSideEffects } from '@redux/sideEffects';
import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '@redux/store';
import { User } from '@models/user';
import { toast } from '@components/ToastNotification/ToastManager';
import i18next from 'i18next';
import {
  fetchUserOnboardingStatus,
  getUser,
  getUserPermissions,
  updateUser,
  updateUserOnboardingStatus,
} from '@apis/users';

type CurrentUserState = {
  // TODO this is actually nullable but I cleaned up so much $*@% by now I need a break
  data: User;
  error: null | string;
  // TODO this is actually nullable but I cleaned up so much $*@% by now I need a break
  onboardStatus: { [key: string]: boolean };
  // TODO this is actually nullable but I cleaned up so much $*@% by now I need a break
  permissions:
    | {
        id: string;
        name: string;
      }[];
};

const initialState: DeepReadonly<CurrentUserState> = {
  data: null as any, // TODO
  error: null,
  onboardStatus: null as any, // TODO
  permissions: null as any, // TODO
};

const { actions, reducer } = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    FETCH_USER_DONE: (state, { payload }: { payload: User }) => {
      state.data = payload;
    },
    FETCH_USER_ERROR: (state, { payload }: { payload: string }) => {
      state.error = payload;
    },
    FETCH_USER_PERMISSION_DONE: (
      state,
      {
        payload,
      }: {
        payload: {
          id: string;
          name: string;
        }[];
      },
    ) => {
      state.permissions = payload;
    },
    FETCH_USER_PERMISSION_ERROR: (state, { payload }: { payload: string }) => {
      state.error = payload;
    },
    SET_USER_DONE: (state, { payload }: { payload: User }) => {
      state.data = payload;
    },
    SET_USER_ERROR: (state, { payload }: { payload: string }) => {
      state.error = payload;
    },
    SET_USER_AVATAR_DONE: (state, { payload }: { payload: User }) => {
      state.data = payload;
    },
    SET_USER_AVATAR_ERROR: (state, { payload }: { payload: string }) => {
      state.error = payload;
    },
    SET_ON_BOARD_STATUS_DONE: (
      state,
      { payload }: { payload: { [key: string]: boolean } },
    ) => {
      state.onboardStatus = payload;
    },
    SET_ON_BOARD_STATUS_ERROR: (state, { payload }: { payload: string }) => {
      state.error = payload;
    },
  },
});

const thunks = {
  getUserSaga: (): AppThunk => async (dispatch, getState) => {
    const kcInstance = getState().keyCloak.keyCloak;
    try {
      const user = await getUser(kcInstance.tokenParsed!.sub!);
      dispatch(RCurrentUser.FETCH_USER_DONE(user));
      dispatch(RCurrentUser.getUserPermissions());
    } catch (errorObj: any) {
      const { errors } = errorObj;
      dispatch(RCurrentUser.FETCH_USER_ERROR(errors));
    }
  },
  getUserPermissions: (): AppThunk => async (dispatch, getState) => {
    const user = getState().currentUser.data;
    try {
      const permissions = await getUserPermissions(user.id);
      dispatch(RCurrentUser.FETCH_USER_PERMISSION_DONE(permissions));
    } catch (errorObj: any) {
      const { errors } = errorObj;
      dispatch(RCurrentUser.FETCH_USER_PERMISSION_ERROR(errors));
    }
  },
  updateUserSaga:
    (update: Partial<Omit<User, 'id' | 'enabled' | 'roles'>>): AppThunk =>
    async (dispatch, getState) => {
      const currentUser = getState().currentUser.data;
      try {
        const { roles, permissions, ...currentUserRest } = currentUser;
        const updated = await updateUser({
          ...currentUserRest,
          permissions: permissions && [...permissions],
          roles: [...roles.map((r) => ({ ...r }))],
          ...update,
        });
        dispatch(RCurrentUser.SET_USER_DONE(updated));
        toast.show({
          message: 'Profile successfully updated!',
          position: 'bottom-center',
        });
      } catch (errorObj: any) {
        const { errors } = errorObj;
        dispatch(RCurrentUser.SET_USER_ERROR(errors));
        toast.show({
          message: 'Could not update profile, please try again!',
          position: 'bottom-center',
          error: true,
        });
      }
    },
  updateUserAvatarImage:
    (user: User): AppThunk =>
    async (dispatch) => {
      dispatch(RCurrentUser.SET_USER_AVATAR_DONE(user));
      toast.show({
        message: 'Profile successfully updated!',
      });
    },
  fetchUserOnBoardStatusSaga: (): AppThunk => async (dispatch) => {
    try {
      const response = await fetchUserOnboardingStatus();
      dispatch(RCurrentUser.SET_ON_BOARD_STATUS_DONE(response));
    } catch (errorObj: any) {
      const { errors } = errorObj;
      dispatch(RCurrentUser.SET_ON_BOARD_STATUS_ERROR(errors));
      toast.show({
        message: errors?.[0]?.message,
        error: true,
      });
    }
  },
  updateUserOnBoardStatusSaga:
    (target: string): AppThunk =>
    async (dispatch) => {
      try {
        const updated = await updateUserOnboardingStatus(target);
        dispatch(RCurrentUser.SET_ON_BOARD_STATUS_DONE(updated));
        if (target === 'GUIDE') {
          toast.show({
            message: i18next.t('global.changes_updated_successfully'),
          });
        }
      } catch (errorObj: any) {
        const { errors } = errorObj;
        dispatch(RCurrentUser.SET_ON_BOARD_STATUS_ERROR(errors));
        toast.show({
          message: errors?.[0]?.message,
          error: true,
        });
      }
    },
} satisfies { [key: string]: (...args: any[]) => AppThunk };

registerSideEffects();

export const RCurrentUser = Object.assign(actions, thunks);

export default reducer;
