export const colors = {
  white: '#fff',
  black: '#000',

  emerald: '#01dd86',
  emerald1: '#1ae092',
  emerald2: '#34e49e',
  emerald3: '#4de7aa',
  emerald4: '#67ebb6',
  emerald5: '#80eec3',
  emerald6: '#99f5db',
  emerald7: '#b3f5db',
  emerald8: '#ccf8e7',
  emerald9: '#e6fcf3',

  oxfordBlue: '#141e38',
  oxfordBlue1: '#2c354c',
  oxfordBlue2: '#434b60',
  oxfordBlue3: '#5b6274',
  oxfordBlue4: '#727888',
  oxfordBlue5: '#8a8f9c',
  oxfordBlue6: '#a1a5af',
  oxfordBlue7: '#b9bcc3',
  oxfordBlue8: '#d0d2d7',
  oxfordBlue9: '#e8e9eb',

  manatte: '#929aaa',
  manatte1: '#9da4b3',
  manatte2: '#a8aebb',
  manatte3: '#b3b8c4',
  manatte4: '#bec2cc',
  manatte5: '#c9cdd5',
  manatte6: '#d3d7dd',
  manatte7: '#dee1e6',
  manatte8: '#e9ebee',
  manatte9: '#f4f5f7',

  bleuDeFrance: '#4288e8',
  bleuDeFrance1: '#5594ea',
  bleuDeFrance2: '#68a0ed',
  bleuDeFrance3: '#7bacef',
  bleuDeFrance4: '#8eb8f1',
  bleuDeFrance5: '#a1c4f4',
  bleuDeFrance6: '#b3cff6',
  bleuDeFrance7: '#c6dbf8',
  bleuDeFrance8: '#d9e7fa',
  bleuDeFrance9: '#ecf3fd',

  capri: '#0bc4ff',
  capri1: '#23caff',
  capri2: '#3cd0ff',
  capri3: '#54d6ff',
  capri4: '#6ddcff',
  capri5: '#85e2ff',
  capri6: '#9de7ff',
  capri7: '#b6edff',
  capri8: '#cef3ff',
  capri9: '#cef3ff',
  capri10: '#e7f9ff',

  turquoise: '#1ed7c7',
  turquoise1: '#35dbcd',
  turquoise2: '#4bdfd2',
  turquoise3: '#62e3d8',
  turquoise4: '#78e7dd',
  turquoise5: '#8febe3',
  turquoise6: '#a5efe9',
  turquoise7: '#bcf3ee',
  turquoise8: '#d2f7f4',
  turquoise9: '#e9fbf9',

  sizzlingRed: '#f3475c',
  sizzlingRed1: '#f4596c',
  sizzlingRed2: '#f56c7d',
  sizzlingRed3: '#f77e8d',
  sizzlingRed4: '#f8919d',
  sizzlingRed5: '#f9a3ae',
  sizzlingRed6: '#fab5be',
  sizzlingRed7: '#fbc8ce',
  sizzlingRed8: '#fddade',
  sizzlingRed9: '#feedef',

  lightSalmon: '#ff966c',
  lightSalmon1: '#ffa17b',
  lightSalmon2: '#ffab89',
  lightSalmon3: '#ffb698',
  lightSalmon4: '#ffc0a7',
  lightSalmon5: '#ffcbb6',
  lightSalmon6: '#ffd5c4',
  lightSalmon7: '#ffe0d3',
  lightSalmon8: '#ffeae2',
  lightSalmon9: '#fff5f0',

  brightYellowCrayola: '#ffaf1d',
  brightYellowCrayola1: '#ffb734',
  brightYellowCrayola2: '#ffbf4a',
  brightYellowCrayola3: '#ffc761',
  brightYellowCrayola4: '#ffcf77',
  brightYellowCrayola5: '#ffd78e',
  brightYellowCrayola6: '#ffdfa5',
  brightYellowCrayola7: '#ffe7bb',
  brightYellowCrayola8: '#ffefd2',
  brightYellowCrayola9: '#fff7e8',
} as const;
