import { Experience } from '@models/experience';
import { ReactComponent as TargetingSvg } from '@assets/icons/experience-targeting.svg';
import { colors } from '@constants/colors';

import './ExperienceTargets.scss';

type ColorSet = {
  primary: string;
  background: string;
  secondary: string;
};

export const colorsByExperienceLevel: {
  [key in Experience['level']]: ColorSet;
} = {
  basic: {
    primary: colors.emerald,
    background: colors.emerald9,
    secondary: colors.emerald7,
  },
  advanced: {
    primary: colors.capri,
    background: colors.capri10,
    secondary: colors.capri7,
  },
  expert: {
    primary: colors.bleuDeFrance,
    background: colors.bleuDeFrance9,
    secondary: colors.bleuDeFrance7,
  },
};

export const ExperienceTargets: React.FC<{
  experience: Experience;
}> = ({ experience }) => {
  const activeDescendants = [...experience.attributes];
  if (activeDescendants.some)
    if (activeDescendants.some((att) => att.startsWith('assets_')))
      activeDescendants.push('assets');

  const colorSet = colorsByExperienceLevel[experience.level];

  const cssDefinitions = {
    '--experience-targets-primary-color': colorSet.primary,
    '--experience-targets-secondary-color': colorSet.secondary,
    '--experience-targets-background-color': colorSet.background,
  } as React.CSSProperties;

  return (
    <div className="experience-targets" style={cssDefinitions}>
      <TargetingSvg aria-activedescendant={activeDescendants.join(' ')} />
    </div>
  );
};
