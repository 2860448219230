export type ActionType = 'REDIRECT';
export interface IGuideStatus {
  count: number;
  completed: number;
  remaining: number;
  progress: number;
}

export interface IGuide {
  tasks: ITask[];
  isCompleted: boolean;
}

export interface ITask {
  index: number;
  sectionName: string;
  steps: IStep[];
  overview: {
    description: string;
    action: {
      type: string;
      path: string;
    };
  };
}

export interface IStep {
  index: number;
  name: string;
  isCompleted: boolean;
  time: number;
  tooltip: string | JSX.Element | JSX.Element[];
  action: {
    type: ActionType;
    path: string;
  };
}

export enum PAGE_KEY {
  CONTEXT = 'CONTEXT',
  PRODUCT = 'PRODUCT',
  PRODUCT_DASHBOARD = 'PRODUCT_DASHBOARD',
  DIGITAL_ASSETS = 'DIGITAL_ASSETS',
  PERMISSIONS = 'PERMISSIONS',
}
