import { clientWithToken } from '.';
import { ENDPOINTS } from '@constants/endpoints';
import { LegacyTemplate } from '@models/productTemplate';
import { GLOBAL_TEMPLATE_CONFIGURATIONS_KEYS } from '@constants/productTemplate';

export const getProductTemplate = async (sku: string) => {
  const { templateMappings, queryParams } = ENDPOINTS.template;
  const url = `${templateMappings}?${queryParams.sku}=${sku}`;
  const response = await clientWithToken.get<{ body: LegacyTemplate }>(url);
  return response.data.body;
};

export const fetchGlobalTemplateConfiguration = async () => {
  const url = `${ENDPOINTS.template.globalConfig}/${Object.values(
    GLOBAL_TEMPLATE_CONFIGURATIONS_KEYS,
  ).join(',')}`;
  const res = await clientWithToken.get<{
    body: {
      globalTemplateConfigurations: LegacyTemplate.GlobalTemplateConfigurationsObj[];
    };
  }>(url, {});
  return res.data.body.globalTemplateConfigurations;
};
