import { registerSideEffects } from '@redux/sideEffects';
import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '@redux/store';

const initialState = { show: false };

const { actions, reducer } = createSlice({
  name: 'spinner',
  initialState,
  reducers: {
    show: (state, { payload }: { payload: boolean }) => {
      state.show = payload;
    },
  },
});

const thunks = {} satisfies { [key: string]: (...args: any[]) => AppThunk };

registerSideEffects();

export const RSpinner = Object.assign(actions, thunks);

export default reducer;
