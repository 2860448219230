import { useTranslation } from 'react-i18next';

import logo from '@assets/logos/logo_animated.svg';
import { capitalizeFirstLetter } from '@utils/textTransform';

import './Loading.scss';

export const Loading: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="Loading">
      <img src={logo} alt="Heuristic Commerce Logo" className="Loading-logo" />
      <h3 className="Loading-text">
        {`${capitalizeFirstLetter(t('global.loading'))}...`}
      </h3>
    </div>
  );
};
