// TODO | This is potentially a complete duplicate of `RangeDataPicker` which could be simplified to have a `withoutBorder` prop
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { colors } from '@constants/colors';
import { formatDate, setDate } from '@utils/dateFormat';
import { RTenant } from '@redux/slices/tenant';

import { Mixpanel } from '@utils/mixpanel';

import { Text } from '../Text/Text';
import { Button } from '../Button/Button';
import { PredefinedDates } from './PredefinedDates';
import { RangePicker } from './RangePicker';
import { ActionButtons } from './ActionButtons';
import { DropdownIcon } from '@components/Icons/DropdownIcon';
import { UpdatedCalenderIcon } from '@components/Icons/UpdatedCalenderIcon';

import './RangeDatePickerWithoutBorder.scss';

export const RangeDatePickerWithoutBorder: React.FC<{
  datePickerState?: boolean;
  togglePickerState: () => void;
  module: string;
  className?: string;
}> = ({ datePickerState, togglePickerState, module, className }) => {
  const dispatch = useDispatch();

  const onboardingDate =
    Number(useSelector((state) => state.tenant.onboardingDate)) ||
    new Date().valueOf();
  const defaultRange = String(
    useSelector((state) => state.tenant.defaultRange),
  );
  const customDate = Boolean(useSelector((state) => state.tenant.customDate));
  const analyticsPeriod = useSelector((state) => state.tenant.analyticsPeriod);
  const selectedDate = Object(
    useSelector((state) => state.tenant.selectedDate),
  );

  const [endDate, setEndDate] = useState(moment().toDate());
  const [displayRange, setDisplayRange] = useState('');
  const [selectedPredefined, setSelectedPredefined] = useState('');

  const [startDate, setStartDate] = useState(
    setDate(selectedPredefined).toDate(),
  );

  useEffect(() => {
    if (defaultRange !== null && !customDate) {
      setDisplayRange(defaultRange);
      setSelectedPredefined(defaultRange);
    }

    if (customDate) {
      if (!selectedDate?.selectedPredefined.length) {
        const formattedStartDate = formatDate(
          selectedDate.startDate,
          'format2',
        );
        const formattedEndDate = formatDate(selectedDate.endDate, 'format2');

        if (
          formattedStartDate !== formattedEndDate &&
          selectedDate.endDate != null
        ) {
          setDisplayRange(`${formattedStartDate} - ${formattedEndDate}`);
        } else {
          setDisplayRange(`${formattedStartDate}`);
        }

        setStartDate(moment(selectedDate?.startDate).toDate());
        setEndDate(moment(selectedDate?.endDate).toDate());

        //setSelectedPredefined(selectedDate.selectedPredefined);
      } else {
        setDisplayRange(selectedDate?.selectedPredefined);
        setSelectedPredefined(selectedDate?.selectedPredefined);
      }
    }
  }, [defaultRange]);

  const predefinedOptions = [
    'Today',
    'Yesterday',
    'Last 7 days',
    'Last 14 days',
    'Last 30 days',
    'Since Launch',
  ];

  const getDateInTimezone = (dateString: string) => {
    const dateParts = dateString.split('-');

    const year = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1;
    const day = parseInt(dateParts[2], 10);

    const date = new Date(year, month, day);
    return date;
  };

  useEffect(() => {
    if (selectedPredefined) {
      setStartDate(
        selectedPredefined === 'Since Launch' && analyticsPeriod?.start
          ? getDateInTimezone(analyticsPeriod?.start.toString())
          : setDate(selectedPredefined).toDate(),
      );
      setEndDate(
        selectedPredefined === 'Yesterday'
          ? moment().subtract(1, 'd').toDate()
          : selectedPredefined === 'Since Launch' && analyticsPeriod?.end
          ? getDateInTimezone(analyticsPeriod?.end.toString())
          : moment().toDate(),
      );
    }
  }, [selectedPredefined, analyticsPeriod]);

  const setRange = (value: any, set: string) => {
    if (set === 'start') {
      setStartDate(value);
      setSelectedPredefined('');
    } else if (set === 'end') {
      setEndDate(value);
      setSelectedPredefined('');
    } else {
      setSelectedPredefined(value);
    }

    Mixpanel.track('Calender', {
      eventName: module,
      kpi: 'User Learnings',
      propertyType: 'event_property',
      dateRange: value,
    });
  };

  const changeRange = () => {
    let startDateTime;
    let endDateTime;
    if (selectedPredefined) {
      setDisplayRange(selectedPredefined);
      startDateTime =
        selectedPredefined === 'Since Launch' && analyticsPeriod?.start
          ? moment(analyticsPeriod?.start.toString()).valueOf()
          : setDate(selectedPredefined).valueOf();
      endDateTime =
        selectedPredefined === 'Yesterday'
          ? moment
              .utc(moment().toDate())
              .subtract(1, 'd')
              .endOf('day')
              .valueOf()
          : selectedPredefined === 'Since Launch' && analyticsPeriod?.end
          ? moment(analyticsPeriod?.end.toString()).valueOf()
          : moment(moment().toDate()).valueOf();
    } else {
      const formattedStartDate = startDate
        ? formatDate(startDate, 'format2')
        : 'Start Date';
      const formattedEndDate = endDate ? formatDate(endDate, 'format2') : '';

      if (startDate && endDate && startDate.valueOf() === endDate.valueOf()) {
        setDisplayRange(formattedStartDate);
      } else if (startDate || endDate) {
        setDisplayRange(
          formattedStartDate +
            (formattedEndDate ? ' - ' + formattedEndDate : ''),
        );
      }
      startDateTime = moment(startDate).valueOf();
      endDateTime = moment(endDate).valueOf();
      if (isNaN(endDateTime)) endDateTime = startDateTime; // if user selects single date
    }
    dispatch(
      RTenant.SET_SELECTED_DATE({
        startDateTime,
        endDateTime,
        startDate,
        endDate,
        selectedPredefined,
      }),
    );

    togglePickerState();

    dispatch(
      RTenant.SET_SELECTED_DATE({
        startDateTime,
        endDateTime,
        startDate,
        endDate,
        selectedPredefined,
      }),
    );

    Mixpanel.track('Calender', {
      eventName: module,
      kpi: 'User Learnings',
      propertyType: 'event_property',
      startDate: startDateTime,
      endDate: endDateTime,
    });
  };

  const clearRange = () => {
    togglePickerState();
  };

  return (
    <div className={`RangeDatePickerNoBorder ${className}`}>
      <div className="RangeDatePickerNoBorder-input">
        <Button
          id="date-picker-calendar"
          background={colors.white}
          padding={'0px 6px 0px 3px'}
          // padding-left={'10px'}
          action={togglePickerState}
        >
          <UpdatedCalenderIcon />
        </Button>
        <Text
          color={colors.oxfordBlue}
          size={12}
          weight={400}
          block={false}
          id="date-range-text"
        >
          {
            /** # TODO can this ever be nullish? */ (displayRange ?? 'null') ===
            'null'
              ? ''
              : displayRange
          }
        </Text>
        <Button
          id="date-picker-dropdown-btn"
          background={colors.white}
          padding={'0px 0px 0px 12px'}
          // padding-left={'10px'}
          action={togglePickerState}
        >
          {/* <CalenderIcon /> */}
          <DropdownIcon />
        </Button>
      </div>
      {datePickerState && (
        <div className="RangeDatePickerNoBorder-picker">
          <div className="RangeDatePickerNoBorder-picker-main">
            <PredefinedDates
              selectedPredefined={selectedPredefined}
              setRange={setRange}
              predefinedOptions={predefinedOptions}
            />
            <RangePicker
              startDate={startDate}
              setRange={setRange}
              endDate={endDate}
              minDate={onboardingDate ? new Date(onboardingDate) : new Date()}
            />
          </div>
          <ActionButtons changeRange={changeRange} clearRange={clearRange} />
        </div>
      )}
    </div>
  );
};
