import InfiniteScroll from 'react-infinite-scroll-component';

import { EndOfSearch } from '../EndOfSearch/EndOfSearch';
import { LoadingGroup } from '../LoadingGroup/LoadingGroup';

export const UpdatedInfiniteScrolling: React.FC<{
  dataLength: number;
  scrollableTarget: string;
  hasMore: boolean;
  loader: React.ReactNode;
  endMessage: string;
  next: () => unknown;
  className?: string;
  numberOfLoaders?: number;
  isLoading?: boolean;
}> = ({
  dataLength,
  endMessage,
  hasMore,
  loader,
  next,
  scrollableTarget,
  children,
  className,
  numberOfLoaders = 4,
  isLoading,
}) => {
  return (
    <InfiniteScroll
      next={next}
      hasMore={hasMore}
      loader={<></>}
      dataLength={dataLength}
      endMessage={<EndOfSearch text={endMessage} />}
      scrollableTarget={scrollableTarget}
    >
      <div className={className}>
        {children}
        {(hasMore || isLoading) && (
          <LoadingGroup component={loader} numberOfCards={numberOfLoaders} />
        )}
      </div>
    </InfiniteScroll>
  );
};
