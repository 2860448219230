import { registerSideEffects } from '@redux/sideEffects';
import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '@redux/store';
import { KeycloakInstance } from 'keycloak-js';

type KeyClockState = {
  keyCloak: KeycloakInstance;
  token: string;
};

const initialState: DeepReadonly<KeyClockState> = {
  keyCloak: null as any, // TODO this should actually nullable and correctly handled everywhere
  token: '',
};

const { actions, reducer } = createSlice({
  name: 'keyCloak',
  initialState,
  reducers: {
    set: (state, { payload }: { payload: KeycloakInstance }) => {
      state.keyCloak = payload;
    },
    setToken: (state, { payload }: { payload: string }) => {
      state.token = payload;
    },
  },
});

const thunks = {} satisfies { [key: string]: (...args: any[]) => AppThunk };

registerSideEffects();

export const RKeyCloak = Object.assign(actions, thunks);

export default reducer;
