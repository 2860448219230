import { useTranslation } from 'react-i18next';

import { ModalLayout } from '../ModalLayout/ModalLayout';
import { PrimaryButton } from '../PrimaryButton/PrimaryButton';
import { capitalizeFirstLetter } from '@utils/textTransform';
import { colors } from '@constants/colors';
import { AlertIcon } from '@components/Icons/AlertIcon';
import { CrossIcon } from '@components/Icons/CrossIcon';

import './ConfirmationModal.scss';

// TODO | Dissolve into RModal logic instead

export type ActionButtonProps = {
  color?: 'gradient' | 'gray' | 'green' | 'red';
  text: string;
  type?: string;
};

export const ConfirmationModal: React.FC<{
  title: string;
  statement: any;
  modalIcon?: JSX.Element;
  showModal: boolean;
  setShowModal: Function;
  buttons: Array<ActionButtonProps>;
  clickHandlerParams?: any;
  clickHandler: Function;
  className?: string;
  cancelHandler?: any;
  isDanger?: boolean;
  defaultClose?: boolean;
  customComponent?: JSX.Element;
}> = ({
  modalIcon,
  isDanger = false,
  showModal,
  setShowModal,
  title,
  statement,
  buttons,
  clickHandler,
  cancelHandler = () => setShowModal(!showModal),
  clickHandlerParams,
  className = '',
  defaultClose = false,
  customComponent,
}) => {
  const { t } = useTranslation();

  return (
    <ModalLayout
      showModal={showModal}
      setShowModal={setShowModal}
      defaultClose={defaultClose}
      className={className}
    >
      <div className="cross">
        <button className="cross-btn" onClick={cancelHandler}>
          <CrossIcon />
        </button>
      </div>
      <div className="ConfirmationModal">
        <div className="ConfirmationModal_icon">
          {modalIcon ? (
            modalIcon
          ) : (
            <AlertIcon fill={isDanger ? colors.sizzlingRed : colors.emerald} />
          )}
        </div>
        <h6 className="ConfirmationModal_title">{title}</h6>
        <p className="ConfirmationModal_description">{statement}</p>
        {customComponent || null}
        <div className="ConfirmationModal_actions">
          {buttons.map((button: ActionButtonProps) =>
            button.type === 'cancel' ? (
              <PrimaryButton
                key={button.text}
                size="small"
                variant={button.color || 'red'}
                onClick={cancelHandler}
              >
                {button.text
                  ? button.text
                  : capitalizeFirstLetter(`${t('global.no_cancel')}`)}
              </PrimaryButton>
            ) : (
              <PrimaryButton
                key={button.text}
                size="small"
                variant={button.color || 'green'}
                onClick={() => clickHandler(clickHandlerParams)}
              >
                {button.text}
              </PrimaryButton>
            ),
          )}
        </div>
      </div>
    </ModalLayout>
  );
};
