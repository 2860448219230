import { useTranslation } from 'react-i18next';

import { colors } from '@constants/colors';
import { typography } from '@constants/typography';

import { Button } from '../Button/Button';
import { Text } from '../Text/Text';

export const PredefinedDates: React.FC<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > & {
    selectedPredefined?: string;
    setRange?: any;
    predefinedOptions?: string[];
  }
> = ({ selectedPredefined, setRange, predefinedOptions }) => {
  const { t } = useTranslation();
  return (
    <div className="RangeDatePickerNoBorder-picker--predefined">
      <Text
        weight={typography.fontWeight.semi_bold}
        size={typography.fontSize.md}
        className="mb-1_5"
        color={colors.oxfordBlue}
      >
        {t('dashboard.predefined_periods')}
      </Text>
      {predefinedOptions?.map((option, index) => (
        <Button
          key={index}
          background="white"
          padding="4px 0px"
          action={() => setRange(option)}
          className="mb-1 d-block px-0"
        >
          <Text
            color={
              option === selectedPredefined
                ? colors.emerald
                : colors.oxfordBlue5
            }
            size={typography.fontSize.sm}
            weight={typography.fontWeight.normal}
          >
            {option}
          </Text>
        </Button>
      ))}
    </div>
  );
};
