import { AttributeTypes, TemplateAttribute } from '@models/productTemplate';
import {
  capitalizeEveryFirstLetter,
  capitalizeFirstLetter,
} from '@utils/textTransform';
import { AnyAttributeValue } from '@models/product';
import { useTranslation } from 'react-i18next';
import useLegacyModal from '@components/ModalLayout/useModal';
import { DigitalAssetsModal } from '@components/DigitalAssetsModal/DigitalAssetsModal';
import { ALL_IMAGES, ALL_VIDEOS } from '@utils/files';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ConfirmationModal,
  ActionButtonProps,
} from '@components/ConfirmationModal/ConfirmationModal';
import { AssetPreviewModal } from '@components/AssetPreviewModal/AssetPreviewModal';
import { DigitalAssetBrief } from '@models/digital-asset';
import {
  AttributeValueError,
  getHTMLLength,
  isValidAttributeValue,
} from '@utils/productTemplate';
import { useAssets } from '../../../hooks/useAssets';
import { isAssetVideo } from '@utils/digitalAssets';
import { Asset } from '../Asset/Asset';
import { DragDropList, modalIds } from './DragDropList/DragDropList';
import { TextareaWithoutBorder } from '@components/TextareaWithoutBorder/TextareaWithoutBorder';
import { ReadOnlyModal } from '@components/ReadOnlyModal/ReadOnlyModal';

import { ReactComponent as RCHorseIcon } from '@assets/icons/RCHorse.svg';
import { ReactComponent as HTMLIcon } from '@assets/icons/HTMLIcon.svg';
import { UrlIcon } from '@components/Icons/UrlIcon';
import { RichTextEditor } from '@components/EditorBox/EditorBox';
import HTMLReactParser from 'html-react-parser';
import { RichContentSelectModal } from './RichContentSelectModal/RichContentSelectModal';
import { RichContentPreviewModal } from '@components/RichContentPreviewModal/RichContentPreviewModal';
import { IRichContent } from '@models/rich-content';
import { PrimaryButton } from '@components/PrimaryButton/PrimaryButton';
import { RVersion } from '@redux/slices/version';

import './AttributeField.scss';

type FieldCreator<T extends AnyAttributeValue> = (
  value: T | undefined,
  onChange: (newValue: T) => any,
  updating: boolean,
  valid?: boolean,
) => React.ReactNode;

export const AttributeEditors = {
  text: ((value, onChange, updating, valid?) => {
    const [text, setText] = useState(value);

    useEffect(() => {
      if (!text || (updating && value)) setText(value);
    }, [updating, value]);

    return (
      <input
        type="text"
        value={text || ''}
        onChange={(e) => {
          setText(e.target.value);
          onChange(e.target.value);
        }}
        className={valid ? '' : 'invalidInput'}
      />
    );
  }) satisfies FieldCreator<string>,
  html: ((value, onChange, updating, valid?) => {
    const id = useMemo(() => crypto.randomUUID(), []);
    return (
      <RichTextEditor
        id={id}
        value={value}
        onChange={onChange}
        showCopyIntoEditor={updating}
        valid={valid}
      />
    );
  }) satisfies FieldCreator<string>,
  richContent: ((richContents = [], onChange) => {
    const { t } = useTranslation();
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [previewRichContent] = useState<IRichContent>(); // TODO
    const { openModal, closeModal, showModalId } = useLegacyModal();
    const [modalText, setModalText] = useState('');

    const base = useSelector(
      (state) => state.productVersion.baseProduct || state.pages.currentItem,
    );

    const modalIds = {
      createRichContentSelect: 'SELECT_RC_MODAL_ID',
      heuristicRichContent: 'HEURISTIC_RC_MODAL',
      htmlRichContent: 'HTML_RC_MODAL',
      urlRichContent: 'URL_RC_MODAL',
      deleteRichContent: 'DELETE_RC_MODAL',
      previewHeuristicRichContent: 'PREVIEW_RC_MODAL',
    };

    const rcDataUrls = {
      HTML: 'data:text/html,',
      URL: 'data:text/uri-list,',
    } as const;

    const RC_CATEGORIES = [
      {
        type: 'Heuristic',
        icon: <RCHorseIcon />,
        onClickShowModal: modalIds.heuristicRichContent, // TODO
        name: t('global.heuristic_commerce'),
      },
      {
        type: 'HTML',
        icon: <HTMLIcon />,
        onClickShowModal: modalIds.htmlRichContent,
        name: t('global.html'),
      },
      {
        type: 'URL',
        icon: <UrlIcon />,
        onClickShowModal: modalIds.urlRichContent, // TODO
        name: t('global.url'),
      },
    ] as const;

    const openModalOnIndex = (modalId: string, index: number) => {
      setSelectedIndex(index);
      if (index === richContents.length && modalId === 'update-modal')
        return openModal(modalIds.createRichContentSelect);

      const [type] = Object.entries(rcDataUrls).find(([_, start]) =>
        richContents[index].startsWith(start),
      ) || ['Heuristic'];

      if (modalId === 'fullscreenModalId' && type === 'Heuristic')
        openModal(modalIds.previewHeuristicRichContent);
      else if (modalId === 'update-modal' || modalId === 'fullscreenModalId') {
        const category = RC_CATEGORIES.find((i) => i.type === type)!;
        if (type !== 'Heuristic') {
          const rawText = richContents[index];
          setModalText(rawText?.substring(rcDataUrls[type].length) || '');
        }
        openModal(category.onClickShowModal);
      } else if (modalId === 'delete-modal')
        openModal(modalIds.deleteRichContent);
    };

    return (
      <>
        <DragDropList
          listClassName="rich-content-list"
          childClassName={() => 'rich-content-item'}
          items={richContents}
          mapItem={(item) => {
            if (item.startsWith(rcDataUrls.HTML)) return <HTMLIcon />;
            if (item.startsWith(rcDataUrls.URL)) return <UrlIcon />;
            return <RCHorseIcon />;
          }}
          onReordered={onChange}
          openModalOnIndex={openModalOnIndex}
          canAdd={true}
          canDelete={true}
        />

        {/** Create RC Modal */}
        <ReadOnlyModal
          showModal={showModalId === modalIds.createRichContentSelect}
          setShowModal={closeModal}
          modalHeader={
            <p className="rich-content-modal__header">
              {capitalizeFirstLetter(t('taskMaster.please_select_from_below'))}
            </p>
          }
          modalBody={
            <>
              <p className="rich-content-modal__instruction">
                {capitalizeFirstLetter(
                  t('taskMaster.rich_content_select_type'),
                )}
              </p>
              <div className="rich-content-modal__options">
                {RC_CATEGORIES.map((category) => (
                  <div
                    key={category.onClickShowModal}
                    className="rich-content-modal__icon"
                    onClick={() => {
                      if (category.type !== 'Heuristic') {
                        let initial = rcDataUrls[category.type];
                        if (category.type === 'URL') initial += 'https://';
                        else initial += '<div>Example</div>';
                        setModalText(
                          initial.substring(rcDataUrls[category.type].length),
                        );
                        onChange([...richContents, initial]);
                      }
                      openModal(category.onClickShowModal);
                    }}
                  >
                    {category.icon}
                    <p>{category.name}</p>
                  </div>
                ))}
              </div>
            </>
          }
        />

        {/** HTML RC Modal */}
        <RichContentSelectModal
          isOpen={showModalId === modalIds.heuristicRichContent}
          closeModal={closeModal}
          onSelectionEnd={(selected) => {
            const clone = [...richContents];
            if (selectedIndex !== clone.length)
              clone.splice(selectedIndex, 1, ...selected);
            else clone.push(...selected);
            onChange(clone);
          }}
          productId={
            base ? ('pageId' in base ? base.pageId : base.productId) : ''
          }
        />

        {/** HTML RC Modal */}
        <ConfirmationModal
          className="html-rich-content-editor-modal"
          modalIcon={<HTMLIcon />}
          showModal={modalIds.htmlRichContent === showModalId}
          setShowModal={closeModal}
          title={capitalizeFirstLetter(t('taskMaster.please_add_html_here'))}
          statement={capitalizeFirstLetter(
            t('taskMaster.added_html_instruction'),
          )}
          buttons={[
            {
              text: capitalizeFirstLetter(t('global.cancel')),
              color: 'gray',
              type: 'cancel',
            },
            {
              text: capitalizeFirstLetter(t('global.done')),
              color: 'green',
              type: 'submit',
            },
          ]}
          clickHandler={() => {
            const updated = [...richContents];
            updated[selectedIndex] = rcDataUrls.HTML + modalText;
            onChange(updated);
            closeModal();
          }}
          cancelHandler={closeModal}
          customComponent={
            <TextareaWithoutBorder onChange={setModalText} value={modalText} />
          }
        />

        {/** URL RC Modal */}
        <ConfirmationModal
          className="url-rich-content-editor-modal"
          modalIcon={<UrlIcon />}
          showModal={modalIds.urlRichContent === showModalId}
          setShowModal={closeModal}
          title={capitalizeFirstLetter(t('taskMaster.please_add_the_URL_here'))}
          statement={HTMLReactParser(
            `${capitalizeFirstLetter(
              t('taskMaster.incase_of_more_than_one_URL'),
            )}<p>(${capitalizeFirstLetter(
              t('taskMaster.added_url_instruction'),
            )})</p>`,
          )}
          buttons={[
            {
              text: capitalizeFirstLetter(t('global.cancel')),
              color: 'gray',
              type: 'cancel',
            },
            {
              text: capitalizeFirstLetter(t('global.done')),
              color: 'green',
              type: 'submit',
            },
          ]}
          clickHandler={() => {
            const updated = [...richContents];
            updated[selectedIndex] = rcDataUrls.URL + modalText;
            onChange(updated);
            closeModal();
          }}
          cancelHandler={closeModal}
          customComponent={
            <TextareaWithoutBorder onChange={setModalText} value={modalText} />
          }
        />

        {/** Delete RC Modal */}
        <ConfirmationModal
          showModal={modalIds.deleteRichContent === showModalId}
          setShowModal={closeModal}
          title={capitalizeEveryFirstLetter(
            `${t('global.remove')} ${t('rich_content.title')}`,
          )}
          statement={capitalizeFirstLetter(
            `${t('taskMaster.removeConfirmationSingleTwo')}`,
          )}
          buttons={[
            {
              text: capitalizeFirstLetter(t('global.no_cancel')),
              color: 'gray',
              type: 'cancel',
            },
            {
              text: capitalizeFirstLetter(t('global.yes_remove')),
              color: 'red',
              type: 'submit',
            },
          ]}
          clickHandler={() => {
            const clone = [...richContents];
            clone.splice(selectedIndex, 1);
            onChange(clone);
            closeModal();
          }}
          isDanger
        />

        {/** View Rich Content */}
        {previewRichContent && (
          <RichContentPreviewModal
            showModal={showModalId === modalIds.previewHeuristicRichContent}
            closeModal={closeModal}
            richContentData={previewRichContent}
          />
        )}
      </>
    );
  }) satisfies FieldCreator<string[]>,
  assets: ((assetIds = [], onChange) => {
    const { t } = useTranslation();
    const fullyEnabled = useSelector(
      (state) => state.tenant.galleryStatus !== 'disabled',
    );
    const { showModalId, openModal, closeModal } = useLegacyModal();
    const [currentIndex, setCurrentIndex] = useState<number>(-1);
    const base = useSelector(
      (state) => state.productVersion.baseProduct || state.pages.currentItem,
    );
    const { assetCache } = useAssets();

    const replaceCurrentAsset = (
      newAssets: DeepReadonly<DigitalAssetBrief[]>,
    ) => {
      if (!assetIds) return;
      const updated = [...assetIds];
      updated.splice(currentIndex, 1, ...newAssets.map((a) => a.id));
      onChange(updated);
      setCurrentIndex(-1);
    };

    // Delete modal layout
    const deleteModal = {
      modalId: modalIds.delete,
      title: capitalizeEveryFirstLetter(
        `${t('global.remove')} ${t('global.image')}`,
      ),
      confirmationStatement: capitalizeFirstLetter(
        `${t('taskMaster.removeConfirmationSingle')}`,
      ),
      clickHandler: () => {
        replaceCurrentAsset([]);
        closeModal();
      },
      actionButtons: [
        {
          text: capitalizeFirstLetter(`${t('global.no_cancel')}`),
          color: 'gray',
          type: 'cancel',
        },
        {
          text: capitalizeFirstLetter(`${t('global.yes_remove')}`),
          color: 'red',
          type: 'submit',
        },
      ] as ActionButtonProps[],
    };

    const maxAssets = useMemo(() => {
      // TODO this should be controlled via template
      if (fullyEnabled) return Number.MAX_SAFE_INTEGER;
      else if (base) return base.attributes.images.length;
      else return 0; // Don't know yet so disable adding
    }, [fullyEnabled, base]);

    const openModalOnAsset = (
      modalId: (typeof modalIds)[keyof typeof modalIds],
      index: number,
    ) => {
      setCurrentIndex(index);
      openModal(modalId);
    };

    return (
      <>
        <DragDropList
          listClassName="gallery"
          childClassName={(i) => 'asset-gallery-item' + (i ? '' : ' primary')}
          items={assetIds}
          mapItem={(assetId) => (
            <Asset assetId={assetId} assetCache={assetCache} />
          )}
          onReordered={onChange}
          openModalOnIndex={openModalOnAsset}
          canAdd={assetIds?.length < maxAssets}
          canDelete={fullyEnabled}
        />

        {modalIds.update === showModalId && currentIndex !== -1 && (
          <DigitalAssetsModal
            showModal={modalIds.update === showModalId}
            setShowModal={closeModal}
            onClose={closeModal}
            allowedFiles={
              fullyEnabled
                ? undefined // Allow any medium
                : !isAssetVideo(assetCache[currentIndex])
                ? ALL_IMAGES
                : ALL_VIDEOS
            }
            setFinalFiles={replaceCurrentAsset}
            maxFiles={maxAssets}
          />
        )}

        {modalIds.delete === showModalId && (
          <ConfirmationModal
            showModal={modalIds.delete === showModalId}
            setShowModal={closeModal}
            title={deleteModal?.title}
            statement={deleteModal?.confirmationStatement}
            buttons={deleteModal?.actionButtons}
            clickHandler={deleteModal.clickHandler}
            isDanger
          />
        )}

        {modalIds.fullscreen === showModalId && (
          <AssetPreviewModal
            showAssetIndex
            assets={[assetCache[assetIds[currentIndex]]!]}
            showModal={modalIds.fullscreen === showModalId}
            setShowModal={closeModal}
            onClose={closeModal}
            callToAction={{
              text: capitalizeEveryFirstLetter(t('taskMaster.change_asset')),
              callback: () => openModal(modalIds.update),
            }}
          />
        )}
      </>
    );
  }) satisfies FieldCreator<string[]>,
} as const;

/** An editable view for a version of a product attribute */
export const AttributeField: React.FC<{
  attribute: TemplateAttribute<AttributeTypes>;
  value: AnyAttributeValue;
  referenceContent?: AnyAttributeValue;
}> = ({ attribute, value, referenceContent }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [updating, setUpdating] = useState(false);

  if (value !== undefined && !isValidAttributeValue(attribute, value))
    throw new AttributeValueError(attribute, value);

  const length = value
    ? attribute.type !== 'html'
      ? value?.length
      : getHTMLLength(value)
    : 0;
  const valid =
    (!attribute.validator?.min || length >= attribute.validator.min) &&
    (!attribute.validator?.max || length <= attribute.validator.max);

  const updateAttribute = (name: string, value: AnyAttributeValue) =>
    dispatch(RVersion.updateContentAttribute({ name, value }));

  return (
    <div className="attribute-field">
      <div className="top-row">
        <h5>{capitalizeEveryFirstLetter(attribute.displayName)}</h5>
        <span className={'limits' + (valid ? '' : ' invalid')}>
          ({length || 0}/{attribute.validator?.max || '∞'})
        </span>
        <PrimaryButton
          className="content-reference-button"
          variant="green"
          size="small"
          disabled={!referenceContent}
          onClick={() => {
            if (!referenceContent) return;
            setUpdating(true);
            updateAttribute(attribute.name, referenceContent);
            setTimeout(() => setUpdating(false));
          }}
        >
          {capitalizeEveryFirstLetter(t('version.use_reference'))}
        </PrimaryButton>
      </div>
      {AttributeEditors[attribute.type](
        value,
        (value) => updateAttribute(attribute.name, value),
        updating,
        length === 0 || valid,
      )}
    </div>
  );
};
