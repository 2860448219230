import { colors } from '@constants/colors';

export const AlertIcon = ({
  fill = colors.emerald,
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 200 200"
    {...props}
  >
    <g id="Popup_AlertTriangle" transform="translate(1.031 1)">
      <path
        fill={fill}
        d="M179.7,185.8H18.3c-3,0-5.9-0.8-8.4-2.3c-8.1-4.7-10.8-15-6.2-23L84.4,20.7c0,0,0,0,0,0
		c1.5-2.6,3.6-4.7,6.2-6.2c3.9-2.3,8.4-2.9,12.8-1.7s8,4,10.2,7.9l80.7,139.8c1.5,2.6,2.3,5.5,2.3,8.4
		C196.5,178.2,189,185.8,179.7,185.8z M93.1,25.8L12.5,165.5c-1.9,3.2-0.7,7.3,2.5,9.2c1,0.6,2.2,0.9,3.4,0.9h161.4
		c3.7,0,6.7-3,6.7-6.7c0-1.2-0.3-2.3-0.9-3.4L104.8,25.8c-0.9-1.6-2.3-2.7-4.1-3.1c-1.7-0.5-3.5-0.2-5.1,0.7
		C94.6,23.9,93.7,24.7,93.1,25.8L93.1,25.8z"
      />
      <path
        fill={fill}
        d="M99,124.8c-2.8,0-5.1-2.3-5.1-5.1V64.7c0-2.8,2.3-5.1,5.1-5.1s5.1,2.3,5.1,5.1v55.1
		C104,122.6,101.8,124.8,99,124.8z"
      />
      <path
        fill={fill}
        d="M99,154.5c-2.8,0-5.1-2.3-5.1-5.1v-11.7c0-2.8,2.3-5.1,5.1-5.1s5.1,2.3,5.1,5.1v11.7
		C104,152.2,101.8,154.5,99,154.5z"
      />
    </g>
  </svg>
);
