import './WorkflowHeading.scss';

/** Contextual Version Page Heading Component used in editor in overview page */
export const WorkflowHeading: React.FC<{
  heading: string;
  subHeading: string;
  type?: 'type1' | 'type2';
  className?: string;
}> = ({ heading, subHeading, type = 'type1', className = '' }) => (
  <div className={`WorkflowHeading ${className}`}>
    <>
      {type === 'type1' && (
        <h3 className="WorkflowHeading__heading">{heading}</h3>
      )}
      <h5 className="WorkflowHeading__subHeading">{subHeading}</h5>
      {type === 'type2' && (
        <h3 className="WorkflowHeading__heading">{heading}</h3>
      )}
    </>
  </div>
);
