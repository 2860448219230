import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { graphClient } from '@apis/graphql';
import { ApolloProvider } from '@apollo/client';

import './i18n/config';
import reportWebVitals from './reportWebVitals';
import store from '@redux/store';

import App from './App';

Sentry.init({
  dsn: 'https://a4003ba5911c48bfa512d9fe3588ef0b@o1161933.ingest.sentry.io/6248579',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <HelmetProvider>
    <Provider store={store}>
      <ApolloProvider client={graphClient}>
        <App />
      </ApolloProvider>
    </Provider>
  </HelmetProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
