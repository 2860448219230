import { capitalizeEveryFirstLetter } from '@utils/textTransform';
import { RangeDatePickerWithoutBorder } from '@components/RangeDatePickerWithoutBorder/RangeDatePickerWithoutBorder';

import './PageHeading.scss';

export const PageHeading: React.FC<{
  title: string;
  className?: string;
  datePickerState?: boolean;
  togglePickerState?: () => void | null;
}> = ({
  title,
  children,
  className = '',
  datePickerState,
  togglePickerState,
}) => (
  <div className={`PageHeading ${className}`}>
    <span className="PageHeading-title">
      {capitalizeEveryFirstLetter(title)}
    </span>
    <div className="PageHeading-children">
      {children}
      {togglePickerState && (
        <RangeDatePickerWithoutBorder
          datePickerState={datePickerState}
          togglePickerState={togglePickerState}
          className="PageHeading-datepicker"
          module="Dashboard"
        />
      )}
    </div>
  </div>
);
