import { registerSideEffects } from '@redux/sideEffects';
import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '@redux/store';
import {
  IUpdateWorkflowSettingPayload,
  IWorkflow_Temp,
  WorkflowState,
} from '@models/workflow';
import { toast } from '@components/ToastNotification/ToastManager';
import i18next from 'i18next';
import { fetchWorkflowSettings, updateWorkflowSettings } from '@apis/workflow';

const initialState: WorkflowState = {
  loading: true,
  workflowEnabled: true,
  activeWorkflow: null,
  workflows: null,
  tenantId: null,
  error: false,
};

const { actions, reducer } = createSlice({
  name: 'workflow',
  initialState,
  reducers: {
    FETCH_WORKFLOW_SETTING_DONE: (
      state,
      {
        payload,
      }: {
        payload: {
          workflowEnabled: boolean;
          activeWorkflow: string;
          workflows: IWorkflow_Temp[];
        };
      },
    ) => {
      const { workflowEnabled, activeWorkflow, workflows } = payload;
      state.workflowEnabled = workflowEnabled;
      state.activeWorkflow = activeWorkflow;
      state.workflows = workflows;
      state.loading = false;
      state.error = false;
    },
    // TODO duplicate
    UPDATE_WORKFLOW_SETTING_DONE: (
      state,
      {
        payload,
      }: {
        payload: {
          workflowEnabled: boolean;
          activeWorkflow: string;
          workflows: IWorkflow_Temp[];
        };
      },
    ) => {
      const { workflowEnabled, activeWorkflow, workflows } = payload;
      state.workflowEnabled = workflowEnabled;
      state.activeWorkflow = activeWorkflow;
      state.workflows = workflows;
      state.loading = false;
      state.error = false;
    },

    UPDATE_WORKFLOW_SETTING_ERROR: (state) => {
      state.loading = false;
      state.error = true;
    },
  },
});

const thunks = {
  fetchWorkflowSettingsSaga: (): AppThunk => async (dispatch) => {
    try {
      const workflow = await fetchWorkflowSettings();
      dispatch(RWorkflow.FETCH_WORKFLOW_SETTING_DONE(workflow));
    } catch (errorObj: any) {
      const { errors } = errorObj;
      dispatch(RWorkflow.UPDATE_WORKFLOW_SETTING_ERROR());
      toast.show({
        message: errors?.[0]?.message,
        error: true,
      });
    }
  },
  updateWorkflowSettingsSaga:
    (payload: IUpdateWorkflowSettingPayload): AppThunk =>
    async (dispatch) => {
      try {
        const updated = await updateWorkflowSettings(payload);
        dispatch(RWorkflow.UPDATE_WORKFLOW_SETTING_DONE(updated));
        toast.show({
          message: i18next.t('global.changes_updated_successfully'),
        });
      } catch (errorObj: any) {
        const { errors } = errorObj;
        dispatch(RWorkflow.UPDATE_WORKFLOW_SETTING_ERROR());
        toast.show({
          message: errors?.[0]?.message,
          error: true,
        });
      }
    },
} satisfies { [key: string]: (...args: any[]) => AppThunk };

registerSideEffects();

export const RWorkflow = Object.assign(actions, thunks);

export default reducer;
