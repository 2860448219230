import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import { colors } from '@constants/colors';
import {
  capitalizeEveryFirstLetter,
  capitalizeFirstLetter,
} from '@utils/textTransform';
import {
  ISearchBarDropdown,
  ISearchBarDropdownItem,
  ISearchBarInput,
  IShowingResult,
} from '@models/searchBar';
import { handleUpDownEnterKeyEvent } from '@utils/searchBar';
import { Mixpanel } from '@utils/mixpanel';

import { ReactComponent as RecentIcon } from '@assets/icons/recent-search.svg';
import { ReactComponent as SearchIcon } from '@assets/icons/5_search.svg';
import { ReactComponent as CloseIcon } from '@assets/icons/27_close.svg';

import './SearchBar.scss';

/** To debounce events and track if there has been another change event since the last */
let lastChangeTimestamp = null;

const SearchInput: React.FC<ISearchBarInput> = ({
  id,
  value,
  placeholder,
  dropdownId,
  debounce,
  updateSearch,
  enterkeyHandler,
  onClearHandler,
  disabled,
}) => {
  const [searchValue, setSearchValue] = useState(value);
  const [activeItem, setActiveItem] = useState(0);
  const searchInput = useRef<HTMLInputElement | null>(null);
  const handleEvent = (event: React.KeyboardEvent<HTMLInputElement>) => {
    setActiveItem(
      handleUpDownEnterKeyEvent(
        event,
        enterkeyHandler,
        dropdownId,
        activeItem,
        value,
      ),
    );
  };

  useEffect(() => {
    setActiveItem(0);
    setSearchValue(value);
  }, [value]);

  const onChange: React.ChangeEventHandler<HTMLInputElement> = async (
    event,
  ) => {
    const newSearch = event.target.value;
    setSearchValue(newSearch);
    if (!debounce) updateSearch(newSearch);
    else {
      const ts = performance.now();
      lastChangeTimestamp = ts;
      await new Promise((r) => setTimeout(r, 400));
      // Only update if no new update since start
      if (lastChangeTimestamp === ts) updateSearch(newSearch);
    }
  };

  return (
    <div className="SearchBar-searchInput">
      <div>
        <SearchIcon />
      </div>
      <input
        id={id}
        disabled={disabled}
        ref={searchInput}
        value={searchValue}
        placeholder={placeholder}
        onChange={onChange}
        onKeyDown={handleEvent}
      />
      {value.length > 0 && (
        <ClearIconComponent
          onClearHandler={() => {
            searchInput.current?.focus();
            onClearHandler();
            Mixpanel.track('clear_search', {
              eventName: 'clear_search',
              kpi: 'search',
              propertyType: 'event_property',
            });
          }}
        />
      )}
    </div>
  );
};

const SearchBarDropdown: React.FC<
  ISearchBarDropdown & { searchQuery: string }
> = ({
  dropdownId,
  removeAllHandler,
  showRecentLabel,
  dropdownItems,
  onSelectResult,
  onSelectedReturn = 'searchTerm',
  onCreate,
  searchQuery,
}) => {
  const { t } = useTranslation();

  const handleSelectResult = (result: string) => {
    onSelectResult && onSelectResult(result);
    Mixpanel.track('search', {
      eventName: 'search_suggestion',
      kpi: 'search',
      propertyType: 'event_property',
      search: result,
    });
  };

  return (
    <div className="SearchBar-searchDropdown">
      {showRecentLabel && <RecentSearch removeAllHandler={removeAllHandler} />}
      <ul className="SearchBar-searchDropdown__list" id={dropdownId}>
        {onCreate && searchQuery.trim().length > 0 && (
          <SearchBarDropdownItem
            id={'listItem-create'}
            searchTerm={t('global.searchCreate', { name: searchQuery.trim() })}
            onSelect={() => void onCreate(searchQuery.trim())}
            style={{ color: colors.emerald }}
          />
        )}
        {dropdownItems?.map((item, index) => (
          <SearchBarDropdownItem
            id={item.id ? item.id : `listItem-${index}`}
            key={item.id ? item.id : `listItem-${index}`}
            searchTerm={item.searchTerm}
            onSelect={async (result) => {
              if (item.onSelect)
                result = (await item.onSelect(result)) ?? result;
              handleSelectResult(result);
              return result;
            }}
            returnEntity={item.returnEntity ?? onSelectedReturn}
          />
        ))}
      </ul>
    </div>
  );
};

const SearchBarDropdownItem: React.FC<
  ISearchBarDropdownItem & { style?: React.CSSProperties }
> = ({ id, searchTerm, onSelect, returnEntity, style }) => {
  return (
    <li
      id={id}
      className="SearchBar-searchDropdown__list--item"
      {...(onSelect && {
        onClick: () => onSelect(returnEntity === 'id' && id ? id : searchTerm),
      })}
      {...{ style }}
    >
      {searchTerm}
    </li>
  );
};

const RecentSearch = ({
  removeAllHandler,
}: {
  removeAllHandler: ISearchBarDropdown['removeAllHandler'];
}) => {
  const { t } = useTranslation();
  return (
    <div className="SearchBar-searchDropdown__recentSearch">
      <div>
        <RecentIcon />
      </div>
      <span className="SearchBar-searchDropdown__recentSearch--heading">
        {capitalizeEveryFirstLetter(t('products.recentSearches'))}
      </span>
      <span
        className="SearchBar-searchDropdown__recentSearch--clearHandler"
        onClick={removeAllHandler}
      >
        {capitalizeEveryFirstLetter(t('products.removeAll'))}
      </span>
    </div>
  );
};

const ClearIconComponent = ({
  onClearHandler,
}: {
  onClearHandler: ISearchBarInput['onClearHandler'];
}) => {
  return (
    <button
      className="SearchBar-searchInput__clearIcon"
      onClick={onClearHandler}
    >
      <CloseIcon />
    </button>
  );
};

const ShowingResult: React.FC<IShowingResult> = ({
  id,
  count,
  title,
  selectedCount,
}) => {
  const { t } = useTranslation();
  const text = selectedCount
    ? `${capitalizeFirstLetter(
        t('global.selected'),
      )} ${selectedCount} / ${count} ${title ? title : t('global.results')}`
    : `${capitalizeFirstLetter(t('global.showing'))} ${count} ${
        title ? title : t('global.results')
      }`;
  return (
    <div className="SearchBar-showingResult">
      <span id={id}>{text}</span>
    </div>
  );
};

export const SearchBar: React.FC<
  ISearchBarInput &
    ISearchBarDropdown &
    IShowingResult & { showShowingResult?: boolean; dropdownId?: string }
> = ({
  id,
  value,
  placeholder = '',
  dropdownItems = [],
  updateSearch,
  debounce,
  enterkeyHandler,
  onClearHandler,
  removeAllHandler,
  onSelectResult = () => null,
  showRecentLabel = false,
  showShowingResult = true,
  count = 0,
  selectedCount,
  title = '',
  dropdownId = '',
  onSelectedReturn = 'searchTerm',
  disabled = false,
  onCreate,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const handleOutSideClick = () => setShowDropdown(false);

  useEffect(() => {
    setSearchQuery(value);
  }, [value]);

  return (
    <OutsideClickHandler onOutsideClick={handleOutSideClick}>
      <div className="SearchBar">
        <div
          className="SearchBar-withInput"
          onClick={() => setShowDropdown(true)}
        >
          <SearchInput
            id={id}
            value={searchQuery}
            placeholder={placeholder}
            updateSearch={updateSearch}
            enterkeyHandler={enterkeyHandler}
            onClearHandler={onClearHandler}
            dropdownId={dropdownId}
            debounce={debounce}
            disabled={disabled}
          />
          {showDropdown &&
            (dropdownItems.length > 0 ||
              (onCreate && searchQuery.trim().length > 0)) && (
              <SearchBarDropdown
                dropdownId={dropdownId}
                searchQuery={searchQuery}
                showRecentLabel={showRecentLabel}
                removeAllHandler={removeAllHandler}
                dropdownItems={dropdownItems}
                onSelectResult={onSelectResult}
                onSelectedReturn={onSelectedReturn}
                onCreate={onCreate}
              />
            )}
        </div>
        {showShowingResult && (
          <ShowingResult
            id={`${id}-count`}
            count={count}
            selectedCount={selectedCount}
            title={title}
          />
        )}
      </div>
    </OutsideClickHandler>
  );
};
