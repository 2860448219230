import { registerSideEffects } from '@redux/sideEffects';
import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '@redux/store';

const initialState = {
  collapsed: JSON.parse(localStorage.getItem('sidebar-toggled') || 'true'),
  secondarySidebars: {} as { [key: string]: boolean },
};

const { actions, reducer } = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setCollapsed: (state, { payload }: { payload: boolean }) => {
      localStorage.setItem('sidebar-toggled', payload + '');
      state.collapsed = payload;
    },
    setSecondarySidebar: (
      state,
      { payload }: { payload: { id: string; collapsed: boolean | null } },
    ) => {
      const secondarySidebars = { ...state.secondarySidebars };
      const { id, collapsed } = payload;

      if (collapsed !== null) secondarySidebars[id] = collapsed;
      else if (secondarySidebars[id] !== undefined)
        secondarySidebars[id] = !secondarySidebars[id];
      else secondarySidebars[id] = false;

      return { ...state, secondarySidebars };
    },
  },
});

const thunks = {} satisfies { [key: string]: (...args: any[]) => AppThunk };

registerSideEffects();

export const RSidebar = Object.assign(actions, thunks);

export default reducer;
