import { colors } from '@constants/colors';

export const AssetPlaceholder = ({
  height = '60px',
  width = '60px',
  fill = colors.oxfordBlue1,
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 60 60"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.40395 45.296L24.476 20.648C24.6367 20.4346 24.8449 20.2613 25.084 20.1421C25.3231 20.0228 25.5867 19.9607 25.854 19.9607C26.1212 19.9607 26.3848 20.0228 26.6239 20.1421C26.863 20.2613 27.0712 20.4346 27.232 20.648L46.304 45.296C46.5175 45.5645 46.6525 45.8869 46.6941 46.2274C46.7357 46.568 46.6822 46.9134 46.5395 47.2254C46.3968 47.5373 46.1705 47.8037 45.8857 47.995C45.6009 48.1863 45.2687 48.295 44.926 48.309H6.77895C6.43643 48.2944 6.10463 48.1854 5.82025 47.9939C5.53588 47.8024 5.31003 47.536 5.16769 47.2241C5.02536 46.9122 4.9721 46.567 5.0138 46.2267C5.05549 45.8864 5.19053 45.5643 5.40395 45.296Z"
      fill={colors.emerald2}
    />
    <path
      d="M20.8749 45.15L36.2529 29.092C36.4137 28.9208 36.6078 28.7844 36.8233 28.6912C37.0388 28.5979 37.2711 28.5498 37.5059 28.5498C37.7407 28.5498 37.973 28.5979 38.1885 28.6912C38.404 28.7844 38.5982 28.9208 38.7589 29.092L54.1369 45.15C54.3875 45.4074 54.5582 45.7318 54.6286 46.084C54.699 46.4363 54.6659 46.8013 54.5335 47.1353C54.401 47.4692 54.1748 47.7576 53.8822 47.9659C53.5895 48.1741 53.2428 48.2933 52.8839 48.309H22.1279C21.769 48.2933 21.4224 48.1741 21.1297 47.9659C20.837 47.7576 20.6108 47.4692 20.4783 47.1353C20.3459 46.8013 20.3129 46.4363 20.3832 46.084C20.4536 45.7318 20.6244 45.4074 20.8749 45.15Z"
      fill={colors.emerald5}
    />
    <path
      d="M54.5841 18.707C54.6208 20.4479 53.9649 22.1322 52.7606 23.3898C51.5562 24.6474 49.9019 25.3754 48.1611 25.414C46.4201 25.3757 44.7656 24.6477 43.5612 23.39C42.3568 22.1324 41.7011 20.448 41.7381 18.707C41.7011 16.966 42.3568 15.2816 43.5612 14.024C44.7656 12.7663 46.4201 12.0383 48.1611 12C49.9019 12.0386 51.5562 12.7666 52.7606 14.0242C53.9649 15.2818 54.6208 16.9661 54.5841 18.707Z"
      fill={colors.white}
    />
  </svg>
);
