// Typography.tsx
// It is used to add static values of Text
export const typography = {
  fontWeight: {
    thin: 100,
    extra_light: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semi_bold: 600,
    bold: 700,
    extra_bold: 800,
    black: 900,
  },
  fontSize: { xs: 10, sm: 12, md: 16, lg: 24, xl: 32 },
} as const;
