import { ActionOverlay } from '@components/ActionOverlay/ActionOverlay';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { capitalizeFirstLetter } from '@utils/textTransform';
import { useTranslation } from 'react-i18next';

import { ReactComponent as DragIcon } from '@assets/icons/DragIcon.svg';
import { ReactComponent as EditIcon } from '@assets/icons/EditIcon.svg';
import { ReactComponent as FullScreenIcon } from '@assets/icons/FullScreenIcon.svg';
import { ReactComponent as AssetGalleryAddIcon } from '@assets/icons/Group 7619.svg';
import { ReactComponent as DeleteIcon } from '@assets/icons/TrashIcon.svg';

export const modalIds = {
  update: 'update-modal',
  delete: 'delete-modal',
  fullscreen: 'fullscreenModalId',
} as const;

type ModalId = (typeof modalIds)[keyof typeof modalIds];

const DragAndDropContainer = SortableContainer(
  ({ children }: { children: React.ReactElement }) => children,
);

const DragAndDropElement = SortableElement(
  ({ children }: { children: React.ReactElement }) => children,
);

export const DragDropList = <T extends Array<any>>({
  listClassName,
  childClassName,
  items,
  mapItem,
  onReordered,
  openModalOnIndex,
  canAdd,
  canDelete,
}: {
  listClassName: string;
  childClassName?: (index: number) => string;
  items: T;
  mapItem: (item: T[number], index: number, items: T) => JSX.Element;
  onReordered: (newOrder: T[number][]) => any;
  openModalOnIndex: (modalId: ModalId, index: number) => any;
  canAdd: boolean;
  canDelete: boolean;
}) => {
  const { t } = useTranslation();

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    if (!items) return;
    const newOrder = [...items];
    const [removed] = newOrder.splice(oldIndex, 1);
    newOrder.splice(newIndex, 0, removed);
    onReordered(newOrder);
  };

  return (
    <DragAndDropContainer
      axis="xy"
      onSortEnd={onSortEnd}
      disableAutoscroll={true}
      distance={5}
    >
      <div className={listClassName}>
        {items.map((item, i) => {
          return (
            <DragAndDropElement key={i} index={i} disabled={false}>
              <ActionOverlay
                className={childClassName && childClassName(i)}
                actions={[
                  ...(canDelete
                    ? ([
                        {
                          icon: <DeleteIcon />,
                          tipText: capitalizeFirstLetter(t('global.remove')),
                          position: 'top',
                          handler: () => openModalOnIndex(modalIds.delete, i),
                        },
                      ] as const)
                    : []),
                  {
                    icon: <FullScreenIcon />,
                    tipText: capitalizeFirstLetter(t('taskMaster.preview')),
                    position: 'bottom',
                    handler: () => openModalOnIndex(modalIds.fullscreen, i),
                  },
                  {
                    icon: <EditIcon />,
                    tipText: capitalizeFirstLetter(t('global.update')),
                    position: 'top',
                    handler: () => openModalOnIndex(modalIds.update, i),
                  },
                  {
                    icon: <DragIcon />,
                    tipText: capitalizeFirstLetter(t('global.drag')),
                    position: 'center',
                    handler: () => null,
                  },
                ]}
              >
                {mapItem(item, i, items)}
              </ActionOverlay>
            </DragAndDropElement>
          );
        })}
        {canAdd && (
          <div className="add-icon">
            <AssetGalleryAddIcon
              role="button"
              onClick={() => openModalOnIndex(modalIds.update, items.length)}
            />
          </div>
        )}
      </div>
    </DragAndDropContainer>
  );
};
