import './style.scss';

const ExperienceCardLoading = () => {
  return (
    <div className="experience-card">
      <div className="experience-card-loading"></div>
    </div>
  );
};

export default ExperienceCardLoading;
